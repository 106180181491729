import { token } from '../modules/token'
import { client } from '../modules/client'
import { getRights } from '../modules/auth'

export const auth = {
  setState: (state) => () => (state),
  authorize: (auth) => () => {
    const { user, error } = (auth || {});
    return {
      user,
      error,
      authed: !error && token.exists(),
      rights: null,
      isAdmin: user && ((user.roles || []).indexOf('admin') > -1)
    }
  },
  login: ({ email, password }) => async (state, actions) => {
    const loginResp = await client.login({ email, password })
    if (loginResp.error) {
      return actions.authorize({ error: loginResp.error })
    }
    token.save(loginResp.token)
    return actions.authorize({ user: loginResp.user })
  },
  logout: () => (state, actions) => {
    token.remove()
    return actions.authorize()
  },
  me: () => async (state, actions) => {
    var user;
    if (token.exists()) {
      const res = await client.me()
      if (!res.error && res.item) {
        user = res.item
      } else {
        return actions.logout();
      }
    }
    return actions.authorize({ user })
  },
  priviliges: () => async (state, actions) => {
    if (!state.user) return actions.setState({});
    const res = await client.priviliges({ email: state.user.email })
    if (res.error) return actions.setState({});
    const rights = getRights(res.items)
    return actions.setState({ rights })
  },
  save: () => async (state, actions) => {
    const res = await client.saveProfile(state.user);
    if (res.error) {
      return actions.setState({ error: res.error });
    }
    return actions.setState({ editing: false });
  },
  changePassword: () => async (state, actions) => {
    const res = await client.saveProfile(state.user);
    if (res.error) {
      return actions.setState({ error: res.error });
    }
    delete state.user.oldPassword
    delete state.user.password
    delete state.user.confirmPassword
    return actions.setState({ changePass: false, user: state.user });
  }
}
