import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'

export const languages = {
  setState: (state) => (state),
  getLanguages: (params) => async (state, actions) => {
    var { filter, order, skip, take, enabled, defaultOrder } = state;
    const req = {};

    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.enabled) !== 'undefined') {
      if (params.enabled)
        req.enabled = true;
    } else if (enabled) {
      req.enabled = true;
    }

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      for (var k in filter) {
        if (filter[k] !== "" && filter[k] !== undefined) {
          req[k] = filter[k];
        }
      }
    }

    const res = await client.getLanguages(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.loaded = true;
    res.enabled = req.enabled;
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    return actions.setState(res);
  },
  deleteLanguage: (id) => async (state, actions) => {
    const res = await client.deleteLanguage(id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const items = state.items.filter(i => id === i.id)
    return actions.setState({ items });
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getLanguages({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getLanguages({ order });
  },
  setFilter: (filter) => async (state, actions) => {
    return actions.getLanguages({ filter });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  exportLanguages: (r) => async (state, actions) => {
    const languages = await client.exportLanguages(r && r.id);
    return languages.items;
  },
  importLanguages: (data) => async (state, actions) => {
    const res = await client.importLanguages(data);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getLanguages();
  },
  save: (l) => async (state, actions) => {
    let items = state.items.map(i => i.id === l.id ? Object.assign({}, i, { loading: true }) : i);
    actions.setState({ items });
    const res = await client.saveLanguage(c);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    items = state.items.map(i => i.id === l.id ? Object.assign({}, i, l) : i);
    return actions.setState({ items });
  }
}
