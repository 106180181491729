import { h } from 'hyperapp';

import { validate, propTypes } from '../../modules/capsule';
import { untagify, validateEditor } from '../../modules/utils';

import { Loading } from './loading';
import { Buttons, CheckBox, Divider, Dropdown, RequiredField, TagsInput, CreateUpdateControl, Fields } from './common';
import { FieldValidators } from './input-fields'
import { EditorButtons } from './editor-buttons';

import '../../css/rule-editor.css'

export const CapsuleEditor = ({ state, actions, id }) => {

  const err = state.enabled ? validate(state, state) : (!state.component && 'Please add capsule component title');

  const getData = () => {
    actions.getCapsule(id);
  }

  const setValue = (prop, value, keep) => {
    const newState = { [prop]: value }
    if (!keep) {
      newState.modified = true;
    }
    actions.setState(newState);
  }

  const save = (e, exit, empty) => {
    if (state.enabled) validateEditor(e.target.closest('.rule-editor'));
    if (err) {
      actions.setState({ error: err });
    } else {
      actions.saveCapsule({ exit, empty });
    }
  }

  const saveAndExit = e => {
    save(e, true)
  }

  const saveAndNew = e => {
    save(e, false, true)
  }

  return (
    <div class="rule-editor" oncreate={getData} ondestroy={() => actions.getCapsule()} >
      {state.loaded ? (<div>
        <div class={'box' + (state.showDetails ? ' show-details' : '')}>
          <CapsuleTitle component={state.component} enabled={state.enabled} setValue={setValue} showDetails={state.showDetails} />
          <CapsuleDetails {...state} setValue={setValue} />
          <Divider />
          <CapsuleDefaultProps defaultProps={state.defaultProps} setDefaultProp={actions.setDefaultProp} addDefaultProp={actions.addDefaultProp} removeDefaultProp={actions.removeDefaultProp} />
        </div>
        {!state.archive ?
          (<EditorButtons clear={() => actions.getCapsule()} cancelTo="capsules" save={save} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!err} />) :
          (<EditorButtons save={() => actions.restoreCapsule()} saveText="Restore" saveIcon="fas fa-trash-restore" />)}
        {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
      </div>
      ) : (<Loading />)}
    </div>
  )
}

const CapsuleTitle = ({ component, enabled, setValue, showDetails }) => {
  return (<div class="rule-heading">
    <div class="columns">
      <div class="column rule-title">
        <RequiredField placeholder="Component" value={component} onchange={({ target }) => setValue('component', target.value)} className="is-expanded" />
        <p class="title is-4">{component}</p>
      </div>
      <div class="column is-one-fifth">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_enabled">Enabled</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_enabled" active={enabled} toggle={value => setValue('enabled', !!value)} />
          </div>
        </div>
      </div>
    </div>
    {showDetails && (<Divider />)}
    <button class="button is-rounded is-white rule-toggle" title={(showDetails ? 'Hide' : 'Show') + ' details'} onclick={() => setValue('showDetails', !showDetails, true)}>
      <span class="icon is-small">
        <i class={`fas fa-${showDetails ? 'minus-square' : 'plus-square'}`}></i>
      </span>
    </button>
  </div>)
}

const CapsuleDetails = ({ desc, tags, setValue, created, createdBy, updated, updatedBy, showDetails, showCreateUpdate }) => {
  return (<div class="rule-details">
    <div class="rule-details-main">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea class="textarea" rows="3" placeholder="Description" value={desc} onchange={({ target: { value } }) => setValue('desc', value)}></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Tags</label>
            <div class="control">
              <TagsInput placeholder="Tags" value={untagify(tags)} onchange={tags => setValue('tags', tags)} className="is-expanded" />
            </div>
          </div>
        </div>
      </div>
      {showDetails && (<button class="button is-rounded is-white rule-toggle" title={'Show ' + (showCreateUpdate ? 'less' : 'more')} onclick={() => setValue('showCreateUpdate', !showCreateUpdate, true)}>
        <span class="icon is-small">
          <i class={`fas fa-${showCreateUpdate ? 'minus-square' : 'plus-square'}`}></i>
        </span>
      </button>)}
    </div>
    {showCreateUpdate && (<Divider />)}
    {showCreateUpdate && (<CreateUpdateControl created={created} createdBy={createdBy} updated={updated} updatedBy={updatedBy} />)}
  </div>)
}

const CapsuleDefaultProps = ({ defaultProps, setDefaultProp, addDefaultProp, removeDefaultProp }) => {
  const showButton = !defaultProps.length || !defaultProps[defaultProps.length - 1].error && !!defaultProps[defaultProps.length - 1].property && !!defaultProps[defaultProps.length - 1].value;
  return (<div class="field">
    <label class="label">Default Properties</label>
    <div class="control">
      <ul class="action_list">
        {defaultProps.map(defaultProp => (<CapsuleDefaultProp defaultProp={defaultProp} onchange={setDefaultProp} onremove={removeDefaultProp} restProps={defaultProps.filter(p => (p.id != defaultProp.id))} />))}
      </ul>
    </div>
    {showButton && (<Buttons addTitle="Add default property" onAddClick={addDefaultProp} addIcon="fa-plus" />)}
  </div>)
}

const CapsuleDefaultProp = ({ defaultProp, onchange, onremove, restProps }) => {

  const setProperty = (value) => {
    if (value) {
      const exists = (restProps || []).find(p => p.property == value);
      if (!exists) {
        defaultProp.property = value;
        onchange(defaultProp);
      }
    }
  }

  const setType = (t) => {
    defaultProp.type = t;
    onchange(defaultProp);
  }

  const setValue = (value) => {
    defaultProp.value = value;
    onchange(defaultProp);
  }

  const restPropValidator = (val) => {
    if (val) {
      const exists = (restProps || []).find(p => p.property == val);
      if (exists) {
        return `Property ${val} already has a default value`
      }
    }
  }

  const validators = defaultProp.type && FieldValidators[defaultProp.type] ? [FieldValidators[defaultProp.type]] : [];

  const TypeField = defaultProp.type ? Fields[defaultProp.type]({ placeholder: "Value", onchange: ({ target: { value } }) => setValue(value), value: defaultProp.value, validators }) : null;

  return (
    <li class="field is-grouped is-grouped-multiline" key={defaultProp.property} >
      <div class="control">
        <RequiredField placeholder="Property" onchange={({ target: { value } }) => setProperty(value)} value={defaultProp.property} validators={[restPropValidator]} />
      </div>
      <div class="control">
        <Dropdown text=" --- select an type --- " items={propTypes} onchange={setType} value={defaultProp.type} />
      </div>
      <div class="control">
        {TypeField}
      </div>
      <div class="control">
        <button class="rm_btn delete" title="Remove default property" onclick={() => onremove(defaultProp)}></button>
      </div>
    </li>
  )
}
