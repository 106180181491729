import { h } from 'hyperapp'
import { tryParse, getFilterPath, capitalize } from '../modules/utils'
import { Tr, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Countries = ({ state, actions }) => {
  const { filter, order } = state.countries;

  const toggleFilter = (prop) => (filters) => {
    actions.location.go(getFilterPath(prop, filters))
    actions.countries.filter({ [prop]: filters })
  }

  const deleteClick = ({ id, path }) => {
    const modal = {
      data: id,
      label: path,
      onConfirm: (id) => actions.countries.deleteCountry(id),
      open: true,
      toggle: actions.countries.toggleModal
    }
    actions.countries.toggleModal(modal);
  }

  const toggleIsLive = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.isLive = !c.isLive
    actions.countries.save(c)
    return false
  }

  const toggleExcluded = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.isExcluded = !c.isExcluded
    actions.countries.save(c)
    return false
  }

  const toggleCryptoAllowed = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.cryptoAllowed = !c.cryptoAllowed
    actions.countries.save(c)
    return false
  }

  const importCountries = () => {
    const modal = {
      title: `Import countries`,
      confirmText: "Import",
      content: (<textarea id="import_countries" rows="25" class="textarea" placeholder="Import countries" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_countries').value;
        const res = tryParse(text);
        if (res)
          actions.countries.importCountries(res);
      },
      open: true,
      toggle: actions.countries.toggleModal
    }
    actions.countries.toggleModal(modal);
  }

  const exportCountries = (c) => {
    actions.countries.exportCountries(c).then(countries => {
      let exportLiveOnly, exportExcluded, exportCryptoAllowedOnly;
      function countriesToExport() {
        return countries.filter(r => (r.isLive === true || !exportLiveOnly) && (!r.isExcluded === true || exportExcluded) && (r.cryptoAllowed === true || !exportCryptoAllowedOnly))
      }
      const modal = {
        title: `Export countries`,
        content: (
          <div>
            <div style={{'padding-bottom':'5px'}}>
              <label class="checkbox">
                <input type="checkbox" id="cbExportLiveOnly" onclick={(e) => { exportLiveOnly = e.target.checked }} />
                <span>&nbsp;Export live only countries</span>
              </label>
              <label class="checkbox">
                <input type="checkbox" id="cbExcludedInactive" onclick={(e) => { exportExcluded = e.target.checked }} />
                <span>&nbsp;Export excluded countries</span>
              </label>
              <label class="checkbox">
                <input type="checkbox" id="cbExportCryptoAllowedOnly" onclick={(e) => { exportCryptoAllowedOnly = e.target.checked }} />
                <span>&nbsp;Export only crypto allowed countries</span>
              </label>
            </div>
            <textarea id="export_country" rows="25" class="textarea" value={JSON.stringify(countriesToExport(), null, 2)} readonly autofocus></textarea>
          </div>
        ),
        open: true,
        toggle: actions.countries.toggleModal,
        buttons: [{
          text: "Copy to clipboard",
          class: "is-primary",
          onclick: () => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(JSON.stringify(countriesToExport()))
            }
          }
        }, {
          text: "Save to file",
          class: "is-info",
          onclick: () => {
            const json = JSON.stringify(countriesToExport());
            const blob = new Blob([json], { type: 'application/json' });
            const link = document.createElement('a');
            link.setAttribute('download', 'export.json');
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);

            // wait for the link to be added to the document
            window.requestAnimationFrame(function () {
              var event = new MouseEvent('click');
              link.dispatchEvent(event, true, true);
              document.body.removeChild(link);
              URL.revokeObjectURL(blob);
            });
          }
        }]
      }
      actions.countries.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_country').focus();
      })
    });
    return false
  }

  const exportCountriesClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    return exportCountries()
  }

  return (<div>
    <ListView name="country" columns="7" getItems={actions.countries.getCountries} onDelete={deleteClick} goToPage={actions.countries.goToPage} {...state.countries}
      renderHead={() => (<Tr>
        <Th field="name" filter={filter} order={order} filterAction={actions.countries.setFilter} setOrderAction={actions.countries.setOrder} route={actions.location} />
        <Th field="code"  filter={filter} order={order} filterAction={actions.countries.setFilter} setOrderAction={actions.countries.setOrder} route={actions.location}  />
        <Th field="alphaCode3"  filter={filter} order={order} filterAction={actions.countries.setFilter} setOrderAction={actions.countries.setOrder} route={actions.location}  />
        <Th field="phonePrefix" order={order} setOrderAction={actions.countries.setOrder} route={actions.location} />
        <Th field="legalAge" order={order} setOrderAction={actions.countries.setOrder} route={actions.location} />
        <Th field="isLive" items={['true', 'false']} filter={filter} filterAction={actions.countries.setFilter} setOrderAction={actions.countries.setOrder} route={actions.location} format={capitalize} />
        <Th field="isExcluded" items={['true', 'false']} filter={filter} filterAction={actions.countries.setFilter} setOrderAction={actions.countries.setOrder} route={actions.location} format={capitalize} />
        <Th field="cryptoAllowed" items={['true', 'false']} filter={filter} filterAction={actions.countries.setFilter} setOrderAction={actions.countries.setOrder} route={actions.location} format={capitalize} />
      </Tr>)}
      renderBody={item => (<Tr>
        <td>{item.name}</td>
        <td>{item.code}</td>
        <td>{item.alphaCode3}</td>
        <td>{item.phonePrefix}</td>
        <td>{item.legalAge}</td>
        <td>
          <span class="icon is-small" onclick={toggleIsLive(item)}>
            {item.isLive ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
        <td>
          <span class="icon is-small" onclick={toggleExcluded(item)}>
            {item.isExcluded ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
        <td>
          <span class="icon is-small" onclick={toggleCryptoAllowed(item)}>
            {item.cryptoAllowed ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
      </Tr>)
      }
      renderButtons={() => (<Tr>
        <button class="button is-primary" onclick={importCountries}>Import</button>
        {!!state.countries.items.length && (<button class="button is-info" onclick={exportCountriesClick}>Export</button>)}
      </Tr>)} />
    <Modal {...state.countries.modal} />
  </div>
  )
}
