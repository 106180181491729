import { h } from 'hyperapp'

import '../../css/emulate.css'

const exampleDelete = `
Slug
10001-nights
`

export const GameDelete = ({ state, actions }) => {
  const selectDelete = (e) => {
    actions.setDeleteFile(e.target.files[0])
  }
  const infoDelete = () => {
    const modal = document.querySelector('[id="games_delete_view"]').querySelector('.modal')
    modal.classList.add('is-active')
  }
  const close = () => {
    document.querySelector('[id="games_delete_view"]').querySelector('.modal').classList.remove('is-active')
  }
  return (
    <section class="section" key="games_delete_view" id="games_delete_view">
      {/* Delete games */}
      <h1 class="title">
        Upload deleted games
      </h1>
      <hr />
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="deleteFile" onchange={selectDelete} />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file
                </span>
              </span>
              <span class="file-name">
                {state.deleteFile ? state.deleteFile.name : '...'}
              </span>
            </label>
            <a onclick={infoDelete}>
              <span class="icon has-text-info">
                <i class="fas fa-info-circle"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          {(!state.uploadDeleteFailed && state.deleteCount == 0 && typeof state.deleteProgress != 'undefined') &&
            <div>
              <h2>
                <i class="fas fa-spinner progress-spinner"></i>&nbsp;
                {state.deleteProgress < 1 ?
                  (<span>{Math.round(state.deleteProgress * 100)}%</span>) :
                  (<span>Upload complete. Processing...</span>)}
              </h2>
              <progress class="progress is-info" value={state.deleteProgress} />
            </div>
          }
          {state.uploadDeleteFailed &&
            <h2>Upload failed: {state.uploadDeleteFailed}</h2>
          }
          {!state.uploadDeleteFailed && (
            (state.deleteCount > 0 && <h2>{state.deleteCount} games deleted in {state.deleteElapsed}s</h2>) ||
            (state.deleteElapsed > 0 && <h2>No games to delete in {state.deleteElapsed}s</h2>))
          }
        </div>
        <div class="column">
          <a class="button" onclick={actions.uploadDelete} disabled={!state.deleteFile || state.deleteLoading}>
            <span class="icon">
              <i class="fas fa-cloud-upload-alt"></i>
            </span>
            <span>Upload</span>
          </a>
        </div>
      </div>
      <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">CSV help</p>
            <button class="delete" onclick={close}></button>
          </header>
          <section class="modal-card-body">
            <h2>Example</h2>
            <pre class="textlog example">{exampleDelete}</pre>
          </section>
          <footer class="modal-card-foot">
            <button class="button" onclick={close}>OK</button>
          </footer>
        </div>
      </div>
    </section >
  )
}
