import { client } from '../modules/client'
import { Language, validate } from "../modules/language";

export const language = {
  setState: (state) => (state),
  getLanguage: (id) => async (state, actions) => {
    if (id) {
      var res = await client.getLanguage(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.item) {
        const err = validate(res.item);
        return actions.setState(Object.assign({ error: err, loaded: true }, res.item));
      }
      return actions.setState({
        error: "Language not found",
        loaded: true
      })
    }
    return actions.setState(Object.assign(new Language(), { loaded: true }))
  },
  saveLanguage: (onDone) => async (state, actions) => {
    const err = validate(state);
    if (!!err) {
      return actions.setState({
        error: err
      })
    }
    const res = await client.saveLanguage(state);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      })
    }
    if (onDone) {
      if (onDone.exit) {
        history.pushState(location.pathname, '', '/languages')
      } else if (onDone.empty) {
        history.pushState(location.pathname, '', '/languages/new')
        return actions.setState(new Capsule());
      } else {
        if (location.pathname == '/languages/new' && res.items.length) {
          actions.setState({ id: res.items[0].id });
          history.pushState(location.pathname, '', '/languages/' + res.items[0].id)
        }
        return actions.setState({ modified: false });
      }
    }
  }
}
