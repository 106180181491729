import { h } from 'hyperapp'

import '../css/emulate.css'

const example = `
{
  "journey": [
    {
      "event": "SIGNUP_COMPLETED",
      "payload": {
        "userId": 1234
      }
    },
    {
      "event": "DEPOSIT",
      "payload": {
        "userId": 1234,
        "bonusCode": "welcome-bonus-row"
      }
    }
  ]
}`

export const Emulate = ({ state, actions }) => {
  const select = (e) => {
    actions.emulate.setFile(e.target.files[0])
  }
  const toggle = () => {
    actions.emulate.setDryRun(!state.emulate.dryRun)
  }
  const upload = (e) => {
    if (state.emulate.file) {
      const fr = new FileReader()
      fr.onload = () => {
        const parsed = JSON.parse(fr.result)
        actions.emulate.upload({ data: parsed, dryRun: state.emulate.dryRun })
      }
      fr.readAsText(state.emulate.file)
    }
  }
  const info = () => {
    document.querySelector('[id="emulate_view"]').querySelector('.modal').classList.toggle('is-active')
  }
  return (
    <section class="section" key="emulate_view" id="emulate_view">
      <h1 class="title">
        Emulate events
        </h1>
      <hr />
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="resume" onchange={select} />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file
                  </span>
              </span>
              <span class="file-name">
                {state.emulate.file ? state.emulate.file.name : '...'}
              </span>
            </label>
            <a onclick={info}>
              <span class="icon has-text-info">
                <i class="fas fa-info-circle"></i>
              </span>
            </a>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <p class="control checkbox">
              {state.emulate.dryRun ? (<input type="checkbox" id="ch_dryRun" class="mw_checkbox" onchange={toggle} value="dryRun" checked="checked" />) :
                (<input type="checkbox" id="ch_dryRun" class="mw_checkbox" onchange={toggle} value="dryRun" />)}
              <label for="ch_dryRun">
                <span class="icon is-small">
                  {state.emulate.dryRun ? <i class="far fa-check-square"></i> : <i class="far fa-square"></i>}
                </span>
                &nbsp;DryRun
              </label>
            </p>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          {(state.emulate.count > 0 && state.emulate.progress > 0) &&
            <div>
              <h2>Upload: {state.emulate.progress} / {state.emulate.count}</h2>
              <progress class="progress is-info" value={state.emulate.progress} max={state.emulate.count} />
            </div>
          }
          {state.emulate.uploadFailed &&
            <h2>Upload failed: {state.emulate.uploadFailed}</h2>
          }
        </div>
        <div class="column">
          <a class="button" onclick={upload} disabled={!state.emulate.file}>
            <span class="icon">
              <i class="fas fa-cloud-upload-alt"></i>
            </span>
            <span>Upload</span>
          </a>
        </div>
      </div>
      <h2 class="subtitle">
        Log
      </h2>
      <span class="has-text-grey-light">(recent 15 entries)</span>
      <div class="columns">
        <div class="column">
          <div class="control">
            <textarea class="textarea textlog" rows="15" readonly>
              {state.debug.log}
            </textarea>
          </div>
        </div>
      </div>
      <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Payload example</p>
            <button class="delete" onclick={info}></button>
          </header>
          <section class="modal-card-body">
            <pre class="textlog">{example}</pre>
          </section>
          <footer class="modal-card-foot">
            <button class="button" onclick={info}>OK</button>
          </footer>
        </div>
      </div>
    </section >
  )
}
