import { client } from '../modules/client'

export const emulate = {
  setFile: file => () => ({ file }),
  setCount: value => state => ({ count: state.count + value }),
  incProgress: value => state => ({ progress: state.progress + value }),
  uploadSuccess: () => () => ({ uploadSuccess: true }),
  uploadFailed: (e) => () => ({ uploadFailed: e }),
  upload: ({ data, dryRun }) => async (state, actions) => {
    actions.setCount(1)
    data.dryRun = dryRun
    await client.emulateEvent(data)
    actions.incProgress(1)
  },
  setDryRun: dryRun => ({ dryRun })
}
