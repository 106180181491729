import { h } from 'hyperapp'
import { FormulaEditor } from './components/formula-editor'

export const Formula = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  const { isAdmin } = state.auth;
  return (
    <section class="section" key="formula_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} Formula
      </h1>
      <FormulaEditor state={state.formula} actions={actions.formula} id={id} isAdmin={isAdmin} />
    </section>
  )
}
