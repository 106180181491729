import { h } from 'hyperapp'

import '../../css/footer.css'

export const Footer = ({ state }) => (
  <footer class="footer" key="footer_view">
    <div class="container">
      <div class="content has-text-centered">
        <p>
          &copy; <a href={state.orgUrl}>{state.org}</a>
        </p>
      </div>
    </div>
  </footer>
)
