import { h } from 'hyperapp'
import { tryParse, getFilterPath, splitUnderscore, propertyToString, capitalize } from '../modules/utils'
import { Tr, TrTagsCell, TrButton, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'
import { RulesStatus } from './components/rules-status'

export const Rules = ({ state, actions }) => {
  const { filter, order, methods, events } = state.rules;
  const { isAdmin } = state.auth

  const getData = () => {
    actions.rules.getEvents();
    actions.rules.getRules();
  }

  const duplicateRule = ({ id, active, ...rest }) => {
    actions.rule.setState({ clone: rest });
    actions.location.go('/rules/new');
  }

  const deleteClick = ({ id, title }) => {
    const { error } = actions.rules.deleteRule(id);
    if (!error) {
      actions.snackbar.open(<span>{`${title} deleted successfully.`}&nbsp;<button class="button is-text is-small is-light" onclick={() => actions.rules.restoreRule({ id })}>Undo.</button></span>)
    }
  }

  const importRules = () => {
    const modal = {
      title: `Import rules`,
      confirmText: "Import",
      content: (<textarea id="import_rule" rows="25" class="textarea" placeholder="Import rules" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_rule').value;
        const res = tryParse(text);
        if (res)
          actions.rules.importRules(res);
      },
      open: true,
      toggle: actions.rules.toggleModal
    }
    actions.rules.toggleModal(modal);
  }

  const exportRules = (r) => {
    actions.rules.exportRules(r).then(rules => {
      let exportInactive
      function rulesToExport() {
        return rules.filter(r => r.active === true || exportInactive === true)
      }
      const modal = {
        title: `Export rules`,
        content: (
          <div>
            <div style={{ 'padding-bottom': '5px' }}>
              <label class="checkbox">
                <input type="checkbox" id="cbExportInactive" onclick={() => { exportInactive = event.target.checked }} />
                <span>&nbsp;Export inactive rules</span>
              </label>
            </div>
            <textarea id="export_rule" rows="25" class="textarea" value={JSON.stringify(rulesToExport(), null, 2)} readonly autofocus></textarea>
          </div>
        ),
        open: true,
        toggle: actions.rules.toggleModal,
        buttons: [{
          text: "Copy to clipboard",
          class: "is-primary",
          onclick: () => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(JSON.stringify(rulesToExport()))
            }
          }
        }, {
          text: "Save to file",
          class: "is-info",
          onclick: () => {
            const json = JSON.stringify(rulesToExport());
            const blob = new Blob([json], { type: 'application/json' });
            const link = document.createElement('a');
            link.setAttribute('download', 'export.json');
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);

            // wait for the link to be added to the document
            window.requestAnimationFrame(function () {
              var event = new MouseEvent('click');
              link.dispatchEvent(event, true, true);
              document.body.removeChild(link);
              URL.revokeObjectURL(blob);
            });
          }
        }]
      }
      actions.rules.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_rule').focus();
      })
    });
  }

  const toggleStatus = () => {
    const status = tryParse(sessionStorage.getItem('rules.status'))
    const modal = {
      title: `Rules status`,
      content: (<pre><code class="language-javascript" >{JSON.stringify(status, null, 2)}</code></pre>),
      open: true,
      toggle: actions.rules.toggleModal
    }
    actions.rules.toggleModal(modal);
  }

  const exportRulesClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    exportRules()
    return false
  }

  const toggleTag = (tags) => {
    actions.location.go(getFilterPath('tags', tags))
    actions.rules.filterRules({ tags })
  }

  const toggleRule = (r) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    actions.rules.toggleRule(r)
    return false
  }

  const toggleIncludeArchived = () => {
    actions.rules.toggleIncludeArchived();
  }

  return (<div>
    <ListView name="rule" columns={isAdmin && state.rules.includeArchived ? "7" : "6"} getItems={getData} goToPage={actions.rules.goToPage} onDelete={deleteClick} {...state.rules}
      renderTitle={() => (<RulesStatus onclick={toggleStatus} />)}
      renderHead={() => (<Tr>
        <Th field="title" filter={filter} order={order} filterAction={actions.rules.filterRules} setOrderAction={actions.rules.setOrder} route={actions.location} />
        <Th field="tags" filter={filter} filterAction={actions.rules.filterRules} setOrderAction={actions.rules.setOrder} route={actions.location} />
        <Th field="event" order={order} filter={filter} items={(events || []).map(e => (e.id))} filterAction={actions.rules.filterRules} setOrderAction={actions.rules.setOrder} route={actions.location} format={splitUnderscore} />
        <Th field="onmet" name="On met" filter={filter} items={methods} filterAction={actions.rules.filterRules} setOrderAction={actions.rules.setOrder} route={actions.location} format={propertyToString} />
        <Th field="onunmet" name="On unmet" filter={filter} items={methods} filterAction={actions.rules.filterRules} setOrderAction={actions.rules.setOrder} route={actions.location} format={propertyToString} />
        <Th field="active" items={['true', 'false']} filter={filter} filterAction={actions.rules.filterRules} setOrderAction={actions.rules.setOrder} route={actions.location} format={capitalize} />
        <th class="th-btn"></th>
        <th class="th-btn"></th>
        {isAdmin && state.rules.includeArchived && (<th class="th-btn"></th>)}
      </Tr>)}
      renderBody={item => (<Tr>
        <td class="title-cell">
          <span>{item.title}</span>
          {item.desc ? (<span>
            <span class="icon is-small"><i class="fas fa-info-circle"></i></span>
            <div class="desc">
              <article class="message">
                <div class="message-body">
                  {item.desc.split('\n').map(l => {
                    return (<span>{l}<br /></span>)
                  })}
                </div>
              </article>
            </div></span>) : null}
        </td>
        <td>
          <TrTagsCell tags={item.tags && item.tags.split(',') || []} filter={filter && filter.tags} onchange={toggleTag} />
        </td>
        <td>{item.event}</td>
        <td>
          <span class={item.actions?.length ? "has-tooltip-bottom" : ""} data-tooltip={item.actions?.map(a => a.method).join('\n')}>{item.actions?.length}</span>
        </td>
        <td>
          <span class={item.unmetActions?.length ? "has-tooltip-bottom" : ""} data-tooltip={item.unmetActions?.map(a => a.method).join('\n')}>{item.unmetActions?.length}</span>
        </td>
        <td>
          {item.error ? (<span class="icon has-text" style={{ cursor: 'help' }} title={item.error}><i class="fas fa-exclamation-triangle"></i></span>) : (
            <span class="icon is-small" onclick={toggleRule(item)}>
              {item.active ?
                (item.isScheduled ?
                  (item.isInPeriod ? <i class="far fa-clock"></i> : <i class="far fa-times-circle"></i>) :
                  <i class="far fa-check-square"></i>) :
                <i class="far fa-square"></i>}
            </span>
          )}
        </td>
        <td>
          <TrButton name="rule" item={item} title="Export" onclick={exportRules} icon="fas fa-file-export" />
        </td>
        <td>
          <TrButton name="rule" item={item} title="Duplicate" onclick={duplicateRule} icon="fas fa-copy" />
        </td>
        {isAdmin && state.rules.includeArchived && (<td>
          {item.archive && (<TrButton name="rule" item={item} title="Restore" onclick={actions.rules.restoreRule} icon="fas fa-trash-restore" />)}
        </td>)}
      </Tr>)
      }
      renderButtons={() => (<Tr>
        {isAdmin && (<label class={'button ' + (state.rules.includeArchived ? ' is-dark' : '')}>
          {state.rules.includeArchived ? (<input type="checkbox" onchange={toggleIncludeArchived} checked="checked" style={{ display: 'none' }} />) :
            (<input type="checkbox" onchange={toggleIncludeArchived} style={{ display: 'none' }} />)}
          {(state.rules.includeArchived ? 'Hide' : 'Show') + ' archived'}
        </label>)}
        <button class="button is-primary" onclick={importRules}>Import</button>
        {!!state.rules.items.length && (<button class="button is-info" onclick={exportRulesClick}>Export</button>)}
      </Tr>)}>
      <div>
        <p class="help">Repository: {state.rules.repository}; Count: {state.rules.count} (active: {state.rules.activeCount}, inactive: {state.rules.inactiveCount})</p>
      </div>
    </ListView>
    <Modal {...state.rules.modal} />

  </div>
  )
}
