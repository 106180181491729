import { client } from '../modules/client'
import { generateID } from '../modules/utils'

export const config = {
    setState: (state) => (state),
    getConfigs: () => async (state, actions) => {
        const res = await client.getConfigs();
        if (res.error) {
            return {
                error: res.error
            }
        }
        const configs = Object.entries(res.items || {}).map(([k, v]) => ({
            key: k,
            value: v,
            id: generateID(),
            saved: true
        }))
        if (!configs.length) {
            configs.push({ id: generateID() });
        }
        return actions.setState({ configs, loaded: true });
    },
    addConfig: () => async (state, actions) => {
        state.configs.push({ id: generateID() });
        return actions.setState({ configs: state.configs });
    },
    editConfig: (cfg) => (state, actions) => {
        cfg.modified = true;
        const configs = state.configs.map(i => i.id === cfg.id ? Object.assign({}, i, cfg) : i)
        return actions.setState({ configs });
    },
    saveConfig: (cfg) => async (state, actions) => {
        const res = await client.saveConfig(cfg);
        if (res.error) {
            return actions.setState({
                error: res.error
            })
        }
        cfg.modified = false;
        cfg.saved = true;
        const configs = state.configs.map(i => i.id === cfg.id ? Object.assign({}, i, cfg) : i)
        return actions.setState({ configs });
    },
    deleteConfig: (cfg) => async (state, actions) => {
        const res = await client.deleteConfig(cfg);
        if (res.error) {
            return actions.setState({
                error: res.error
            })
        }
        const configs = state.configs.filter(c => (c.id !== cfg.id));
        if (!configs.length) {
            configs.push({ id: generateID() });
        }
        return actions.setState({ configs });
    },
}
