import { client } from '../modules/client'
import { Event, EventField, InternalEvent, toBaseEvent } from '../modules/events'

export const events = {
  setState: (state) => () => {
    return state;
  },
  addNew: (id) => (state) => {
    state.events.push(new Event({ id: id.toUpperCase(), changed: true }));
    state.events = state.events.sort((a, b) => (a.id < b.id ? -1 : 1));
    history.pushState(location.pathname, '', '/events/' + id);
    return state;
  },
  getEvents: () => async (state, actions) => {
    const internal = await client.getInternalEvents()
    if (internal.error) {
      return actions.setState({
        error: internal.error,
        loaded: true
      })
    }
    var internalEvents = [];
    if (internal.events) {
      internalEvents = internal.events.map(e => new InternalEvent(e)).sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1));
    }
    const res = await client.getEvents();
    if (res.error) {
      return actions.setState({
        error: res.error,
        loaded: true
      })
    }
    var events = [];
    if (res.events) {
      events = res.events.map(e => new Event(e)).sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1));
    }

    return actions.setState({ loaded: true, active: "events", events, internalEvents });
  },
  saveEvent: (event) => async (state, actions) => {
    const res = await client.saveEvent(toBaseEvent(event));
    event.changed = false;
    if (!res.error) {
      history.pushState(location.pathname, '', '/events')
      return actions.setState({ events: state.events.map(i => event.id === i.id ? Object.assign({}, i, event) : i) });
    }
    return {
      error: `Something went wrong. ${res.error}`
    }
  },
  removeEvent: (id) => async (state, actions) => {
    const res = await client.deleteEvent(id);
    if (!res.error) {
      const events = state.events.filter(r => r.id !== id);
      return actions.setState({ events, count: res.count });
    }
    return {
      error: `Something went wrong. ${res.error}`
    }
  },
  setEvent: (event) => (state) => {
    event.changed = true;
    return {
      events: state.events.map(i => event.id === i.id ? Object.assign({}, i, event) : i).sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1))
    }
  },
  addField: (event) => (state, actions) => {
    event.fields.push(new EventField());
    return event;
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
}
