import { h } from 'hyperapp';

import { validate, logMetOptions, onFailOptions } from '../../modules/rule';
import { untagify, validateEditor, validateButton, toKeyValue, dateToString } from '../../modules/utils';

import { Loading } from './loading';
import { CheckBox, Dropdown, Divider, Modal, RequiredField, DateTimeField, TagsInput, CreateUpdateControl } from './common';
import { EventSelector } from './event-selector';
import { RuleDelay } from './rule-delay';
import { Criterias } from './criteria-editor';
import { Actions } from './action-editor';
import { RuleButtons, RestoreButton } from './rule-buttons';
import { RulePreview } from './rule-preview';
import { ActionEventFieldsEditor } from './action-event-fields';

import '../../css/rule-editor.css'

export const FormulaEditor = ({ state, actions, id, isAdmin }) => {

  const err = state.active ? validate(state, state) : (!state.title && 'Please add rule title');

  const getData = () => {
    actions.getFormula(id);
  }

  const setValue = (prop, value, keep) => {
    const newState = { [prop]: value }
    if (!keep) {
      newState.modified = true;
    }
    actions.setState(newState);
  }

  const save = (e, exit, empty) => {
    if (state.active) validateEditor(e.target.closest('.rule-editor'));
    if (err) {
      actions.setState({ error: err });
    } else {
      actions.saveFormula({ exit, empty });
    }
  }

  const saveAndExit = e => {
    save(e, true)
  }

  const saveAndNew = e => {
    save(e, false, true)
  }

  const closeEventFieldsModal = () => {
    actions.setState({ eventFields: null })
  }

  return (
    <div class="rule-editor" oncreate={getData} ondestroy={() => actions.getFormula()} >
      {state.loaded ? (<div>
        <div class={'box' + (state.showDetails ? ' show-details' : '')}>
          <FormulaTitle title={state.title} active={state.active} setValue={setValue} showDetails={state.showDetails} />
          <FormulaDetails {...state} setValue={setValue} />
          <Divider />
          <div class="field is-grouped datasource">
            <div class="control">
              <EventSelector value={state.event} events={state.events} onSelect={actions.selectEvent} />
            </div>
            {/* <div class="control">
              <RuleDelay state={state} actions={actions} />
            </div> */}
          </div>
          {state.event && (<div>
            <Criterias state={state} actions={actions} />
            <Divider text="then" />
            <Actions state={state} actions={actions} />
          </div>)}
        </div>
        {!state.archive ? (<RuleButtons clear={() => actions.getFormula()} cancelTo="formulas" save={save} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!err} preview={state.preview} togglePreview={actions.togglePreview} />) :
          (<RestoreButton restore={() => actions.restoreFormula()} preview={state.preview} togglePreview={actions.togglePreview} />)}
        {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
        {isAdmin && (<div>
          <div class="is-small is-boxed">
            <RulePreview state={state} actions={actions} />
          </div>
        </div>)}
        {state.eventFields && (<Modal open={state.eventFields.open} title={`${state.eventFields.event.id} fields`} cancelText="OK" onCancel={closeEventFieldsModal} >
          <ActionEventFieldsEditor {...state.eventFields} criterias={state.criterias} setEventFields={actions.setEventFields} />
        </Modal>)}
      </div>
      ) : (<Loading />)}
    </div>
  )
}

const FormulaTitle = ({ title, active, setValue, showDetails }) => {
  return (<div class="rule-heading">
    <div class="columns">
      <div class="column rule-title">
        <RequiredField placeholder="Title" value={title} onchange={({ target }) => setValue('title', target.value)} className="is-expanded" />
        <p class="title is-4">{title}</p>
      </div>
      <div class="column is-one-fifth">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_active">Active</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_active" active={active} toggle={value => setValue('active', !!value)} />
          </div>
        </div>
      </div>
    </div>
    {showDetails && (<Divider />)}
    <button class="button is-rounded is-white rule-toggle" title={(showDetails ? 'Hide' : 'Show') + ' details'} onclick={() => setValue('showDetails', !showDetails, true)}>
      <span class="icon is-small">
        <i class={`fas fa-${showDetails ? 'minus-square' : 'plus-square'}`}></i>
      </span>
    </button>
  </div>)
}

const FormulaDetails = ({ desc, tags, onUnmet, onMet, /*onFail, group, validFrom, validTo,*/ setValue, created, createdBy, updated, updatedBy, showDetails, showCreateUpdate/*, noCriterias */ }) => {
  return (<div class="rule-details">
    <div class="rule-details-main">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea class="textarea" rows="3" placeholder="Description" value={desc} onchange={({ target: { value } }) => setValue('desc', value)}></textarea>
            </div>
          </div>
        </div>
        {/* <div class="column is-two-fifths">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Validity</label>
            </div>
            <div class="field-body">
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">From</label>
            </div>
            <div class="field-body">
              <DateTimeField placeholder="Valid From" value={dateToString(validFrom)} onchange={({ target }) => setValue('validFrom', target.value)} />
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">To</label>
            </div>
            <div class="field-body">
              <DateTimeField placeholder="Valid To" value={dateToString(validTo)} onchange={({ target }) => setValue('validTo', target.value)} />
            </div>
          </div>
        </div> */}
      </div>
      <div class="columns">
        {/* <div class="column is-two-fifths">
          <label class="label">Group</label>
          <div class="control">
            <Dropdown value={group} onchange={value => setValue('group', value)} items={[]} valueField="key" displayField="value" orderField="index" className="is-expanded" disabled={true} />
          </div>
        </div> */}
        <div class="column">
          <div class="field">
            <label class="label">Tags</label>
            <div class="control">
              <TagsInput placeholder="Tags" value={untagify(tags)} onchange={tags => setValue('tags', tags)} className="is-expanded" />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-fifths">
          <label class="label">Log Unmet</label>
          <div class="control">
            <Dropdown value={onUnmet} onchange={value => setValue('onUnmet', parseInt(value))} items={toKeyValue(logMetOptions)} valueField="key" displayField="value" orderField="index" className="is-expanded" />
          </div>
        </div>
        <div class="column is-two-fifths">
          <label class="label">Log Met</label>
          <div class="control">
            <Dropdown value={onMet} onchange={value => setValue('onMet', parseInt(value))} items={toKeyValue(logMetOptions)} valueField="key" displayField="value" orderField="index" className="is-expanded" />
          </div>
        </div>
        {/* <div class="column is-two-fifths">
          <label class="label">On Failure</label>
          <div class="control">
            <Dropdown value={onFail} onchange={value => setValue('onFail', parseInt(value))} items={toKeyValue(onFailOptions)} valueField="key" displayField="value" orderField="index" className="is-expanded" />
          </div>
        </div>
        <div class="column is-one-fifth">
          <label class="label" for="is_active">No Criterias</label>
          <div class="control">
            <CheckBox active={noCriterias} toggle={value => setValue('noCriterias', !!value)} />
          </div>
        </div>*/}
      </div>
      {showDetails && (<button class="button is-rounded is-white rule-toggle" title={'Show ' + (showCreateUpdate ? 'less' : 'more')} onclick={() => setValue('showCreateUpdate', !showCreateUpdate, true)}>
        <span class="icon is-small">
          <i class={`fas fa-${showCreateUpdate ? 'minus-square' : 'plus-square'}`}></i>
        </span>
      </button>)}
    </div>
    {showCreateUpdate && (<Divider />)}
    {showCreateUpdate && (<CreateUpdateControl created={created} createdBy={createdBy} updated={updated} updatedBy={updatedBy} />)}
  </div>)
}
