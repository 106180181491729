import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'

export const countries = {
  setState: (state) => (state),
  getCountries: (params) => async (state, actions) => {
    var { filter, order, skip, take, isExcluded, isLive, cryptoAllowed, defaultOrder } = state;
    const req = {};

    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.isExcluded) !== 'undefined') {
      if (params.isExcluded)
        req.isExcluded = true;
    } else if (isExcluded) {
      req.isExcluded = true;
    }
    if (params && typeof (params.isLive) !== 'undefined') {
      req.isLive = params.isLive;
    } else if (typeof (isLive) !== 'undefined') {
      req.isLive = isLive;
    }
    if (params && typeof (params.cryptoAllowed) !== 'undefined') {
      req.cryptoAllowed = params.cryptoAllowed;
    } else if (typeof (cryptoAllowed) !== 'undefined') {
      req.cryptoAllowed = cryptoAllowed;
    }

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      for (var k in filter) {
        if (filter[k] !== "" && filter[k] !== undefined) {
          req[k] = filter[k];
        }
      }
    }

    const res = await client.getCountries(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.loaded = true;
    res.isExcluded = req.isExcluded;
    res.isLive = req.isLive;
    res.cryptoAllowed = req.cryptoAllowed
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    return actions.setState(res);
  },
  deleteCountry: (id) => async (state, actions) => {
    const res = await client.deleteCountry(id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const items = state.items.filter(i => id === i.id)
    return actions.setState({ items });
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getCountries({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getCountries({ order });
  },
  setFilter: (filter) => async (state, actions) => {
    return actions.getCountries({ filter });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  exportCountries: (r) => async (state, actions) => {
    const countries = await client.exportCountries(r && r.id);
    return countries.items;
  },
  importCountries: (data) => async (state, actions) => {
    const res = await client.importCountries(data);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getCountries();
  },
  save: (c) => async (state, actions) => {
    let items = state.items.map(i => i.id === c.id ? Object.assign({}, i, { loading: true }) : i);
    actions.setState({ items });
    const res = await client.saveCountry(c);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    items = state.items.map(i => i.id === c.id ? Object.assign({}, i, c) : i);
    return actions.setState({ items });
  }
}
