import { h } from 'hyperapp'
import { SiteconfigEditor } from './components/siteconfig-editor'

export const Siteconfig = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  return (
    <section class="section" key="siteconfig_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} Siteconfig
      </h1>
      <SiteconfigEditor state={state.siteconfig} actions={actions.siteconfig} id={id} />
    </section>
  )
}
