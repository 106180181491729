import { h } from 'hyperapp'
import { tryParse, getFilterPath, capitalize } from '../modules/utils'
import { Tr, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Languages = ({ state, actions }) => {
  const { filter, order } = state.languages;

  const toggleFilter = (prop) => (filters) => {
    actions.location.go(getFilterPath(prop, filters))
    actions.languages.filter({ [prop]: filters })
  }

  const deleteClick = ({ id, path }) => {
    const modal = {
      data: id,
      label: path,
      onConfirm: (id) => actions.languages.deleteLanguage(id),
      open: true,
      toggle: actions.languages.toggleModal
    }
    actions.languages.toggleModal(modal);
  }

  const toggleEnabled = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.enabled = !c.enabled
    actions.languages.save(c)
    return false
  }

  const toggleDefault = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.isDefault = !c.isDefault
    actions.languages.save(c)
    return false
  }

  const toggleExcluded = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.excludeFromSitemap = !c.excludeFromSitemap
    actions.languages.save(c)
    return false
  }

  const importLanguages = () => {
    const modal = {
      title: `Import languages`,
      confirmText: "Import",
      content: (<textarea id="import_languages" rows="25" class="textarea" placeholder="Import languages" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_languages').value;
        const res = tryParse(text);
        if (res)
          actions.languages.importLanguages(res);
      },
      open: true,
      toggle: actions.languages.toggleModal
    }
    actions.languages.toggleModal(modal);
  }

  const exportLanguages = (c) => {
    actions.languages.exportLanguages(c).then(languages => {
      let exportDisabled;
      function languagesToExport() {
        return languages.filter(r => r.enabled === true || exportDisabled)
      }
      const modal = {
        title: `Export languages`,
        content: (
          <div>
            <div style={{'padding-bottom':'5px'}}>
              <label class="checkbox">
                <input type="checkbox" id="cbExportDisabled" onclick={(e) => { exportDisabled = e.target.checked }} />
                <span>&nbsp;Export disabled languages</span>
              </label>
            </div>
            <textarea id="export_language" rows="25" class="textarea" value={JSON.stringify(languagesToExport(), null, 2)} readonly autofocus></textarea>
          </div>
        ),
        open: true,
        toggle: actions.languages.toggleModal,
        buttons: [{
          text: "Copy to clipboard",
          class: "is-primary",
          onclick: () => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(JSON.stringify(languagesToExport()))
            }
          }
        }, {
          text: "Save to file",
          class: "is-info",
          onclick: () => {
            const json = JSON.stringify(languagesToExport());
            const blob = new Blob([json], { type: 'application/json' });
            const link = document.createElement('a');
            link.setAttribute('download', 'export.json');
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);

            // wait for the link to be added to the document
            window.requestAnimationFrame(function () {
              var event = new MouseEvent('click');
              link.dispatchEvent(event, true, true);
              document.body.removeChild(link);
              URL.revokeObjectURL(blob);
            });
          }
        }]
      }
      actions.languages.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_language').focus();
      })
    });
    return false
  }

  return (<div>
    <ListView name="language" columns="7" getItems={actions.languages.getLanguages} onDelete={deleteClick} goToPage={actions.languages.goToPage} {...state.languages}
      renderHead={() => (<Tr>
        <Th field="name" filter={filter} order={order} filterAction={actions.languages.setFilter} setOrderAction={actions.languages.setOrder} route={actions.location} />
        <Th field="code" filter={filter} order={order} filterAction={actions.languages.setFilter} setOrderAction={actions.languages.setOrder} route={actions.location} />
        <Th field="canonicalLocale" order={order} setOrderAction={actions.languages.setOrder} route={actions.location} />
        <Th field="fallbackCode" order={order} setOrderAction={actions.languages.setOrder} route={actions.location} />
        {/* <Th field="months" /> */}
        <Th field="isDefault" items={['true', 'false']} order={order} setOrderAction={actions.languages.setOrder} route={actions.location} format={capitalize} />
        <Th field="enabled" items={['true', 'false']} filter={filter} filterAction={actions.languages.setFilter} setOrderAction={actions.languages.setOrder} route={actions.location} format={capitalize} />
        <Th field="excludeFromSitemap" items={['true', 'false']} filter={filter} order={order} filterAction={actions.languages.setFilter} setOrderAction={actions.languages.setOrder} route={actions.location} format={capitalize} />
      </Tr>)}
      renderBody={item => (<Tr>
        <td>{item.name}</td>
        <td>{item.code}</td>
        <td>{item.canonicalLocale}</td>
        <td>{item.fallbackCode}</td>
        {/* <td>
          <span class="icon is-small">
            {item.months && item.months.length == 12 ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-times-circle"></i>}
          </span>
        </td> */}
        <td>
          <span class="icon is-small" onclick={toggleDefault(item)}>
            {item.isDefault ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
        <td>
          <span class="icon is-small" onclick={toggleEnabled(item)}>
            {item.enabled ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
        <td>
          <span class="icon is-small" onclick={toggleExcluded(item)}>
            {item.excludeFromSitemap ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
      </Tr>)
      }
      renderButtons={() => (<Tr>
        <button class="button is-primary" onclick={importLanguages}>Import</button>
        {!!state.languages.items.length && (<button class="button is-info" onclick={exportLanguages}>Export</button>)}
      </Tr>)} />
    <Modal {...state.languages.modal} />
  </div>
  )
}
