import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'

export const capsules = {
  setState: (state) => (state),
  getCapsules: (params) => async (state, actions) => {
    var { filter, order, skip, take, includeArchived, defaultOrder } = state;
    const req = {};

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      filter = Object.keys(filter).filter(f => (filter[f] !== "" && filter[f] !== undefined)).sort().map(f => `${f}:${filter[f]}`).join(';');
      if (Object.keys(filter)) {
        req.filter = filter;
      }
    }
    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.includeArchived) !== 'undefined') {
      if (params.includeArchived)
        req.includeArchived = true;
    } else if (includeArchived) {
      req.includeArchived = true;
    }
    const res = await client.getCapsules(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.loaded = true;
    res.includeArchived = req.includeArchived;
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    return actions.setState(res);
  },
  toggleCapsule: (r) => async (state, actions) => {
    r.enabled = !r.enabled;
    const res = await client.saveCapsule(r);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const items = state.items.map(i => r.id === i.id ? Object.assign({}, i, r) : i)
    return actions.setState({ items });
  },
  deleteCapsule: (id) => async (state, actions) => {
    const res = await client.deleteCapsule(id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getCapsules();
  },
  restoreCapsule: ({ id }) => async (state, actions) => {
    const res = await client.restoreCapsule(id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getCapsules();
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getCapsules({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getCapsules({ order });
  },
  filterCapsules: (filter) => async (state, actions) => {
    return actions.getCapsules({ filter, skip: 0 });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  exportCapsules: (r) => async (state, actions) => {
    const capsules = await client.exportCapsules(r && r.id);
    return capsules.items;
  },
  importCapsules: (data) => async (state, actions) => {
    const res = await client.importCapsules(data);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getCapsules();
  },
  toggleIncludeArchived: () => async (state, actions) => {
    return actions.getCapsules({ includeArchived: !state.includeArchived });
  }
}
