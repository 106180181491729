export const debug = {
  state: {
    log: []
  },
  actions: {
    onmessage: msg => state => {
      const payload = jsonParse(msg.data)

      const ts = new Date().toLocaleTimeString()
      let entry = `[${ts}] ${payload}\n`

      if (payload.event) {
        entry = `[${ts}] rule executed: "${payload.rule.title}" (id: ${payload.rule.id}), triggered by: ${payload.event.topic}, error: ${payload.error}\n`
      }

      state.log.unshift(entry)
      if (state.log.length > 14) {
        state.log.pop()
      }

      return {
        log: state.log
      }
    }
  }
}

function jsonParse(s) {
  try {
    return JSON.parse(s)
  } catch (e) {
    return s
  }
}
