import { client } from '../modules/client'
import { CrmEvent, CrmEventField } from '../modules/crm-events'

export const crmEvents = {
  setState: (state) => () => {
    return state;
  },
  addNew: (id) => (state) => {
    state.crmEvents.push(new CrmEvent({ id: id.toUpperCase(), changed: true }));
    state.crmEvents = state.crmEvents.sort((a, b) => (a.id < b.id ? -1 : 1));
    history.pushState(location.pathname, '', '/crmevents/' + id);
    return state;
  },
  getEvents: () => async (state, actions) => {
    const res = await client.getCrmEvents();
    if (res.error) {
      return actions.setState({
        error: res.error,
        loaded: true
      })
    }
    var crmEvents = [];
    if (res.events) {
      crmEvents = res.events.map(e => new CrmEvent(e)).sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1));
    }

    return actions.setState({ loaded: true, crmEvents });
  },
  saveEvent: (event) => async (state, actions) => {
    const res = await client.saveCrmEvent(event);
    event.changed = false;
    if (!res.error) {
      history.pushState(location.pathname, '', '/crmevents')
      return actions.setState({ crmEvents: state.crmEvents.map(i => event.id === i.id ? Object.assign({}, i, event) : i) });
    }
    return {
      error: `Something went wrong. ${res.error}`
    }
  },
  removeEvent: (id) => async (state, actions) => {
    const res = await client.deleteCrmEvent(id);
    if (!res.error) {
      const crmEvents = state.crmEvents.filter(r => r.id !== id);
      return actions.setState({ crmEvents, count: res.count });
    }
    return {
      error: `Something went wrong. ${res.error}`
    }
  },
  setEvent: (event) => (state) => {
    event.changed = true;
    return {
      crmEvents: state.crmEvents.map(i => event.id === i.id ? Object.assign({}, i, event) : i).sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1))
    }
  },
  addField: (event) => (state, actions) => {
    event.fields.push(new CrmEventField());
    return event;
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  exportEvents: (r) => async (state, actions) => {
    const res = await client.exportCrmEvents(r && r.id);
    return res.events;
  },
  importEvents: (data) => async (state, actions) => {
    const res = await client.importCrmEvents(data);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getEvents();
  }
}
