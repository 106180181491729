import { h } from 'hyperapp'
import { Loading } from './components/loading'
import { untagify, validateEditor } from '../modules/utils'
import { RequiredField, StringField, MatchField } from './components/input-fields';
import TagsInput from './components/tags-input'

import '../css/profile.css'


export const Profile = ({ state, actions }) => {
  const { user, editing, changePass } = state.auth;
  if (!user) return (<Loading />);

  const hasPass = user.providers && (user.providers.indexOf("password") != -1);

  const setProfile = (prop) => ({ target: { value } }) => {
    const { user } = state.auth;
    user[prop] = value;
    actions.auth.setState({ user });
  }

  const toggleEdit = () => {
    actions.auth.setState({ editing: !editing });
  }

  const toggleChangePass = () => {
    actions.auth.setState({ changePass: !changePass });
  }

  const validateProfileEditor = (e) => {
    const editor = e.target.closest('.profile_box').querySelector('.profile-editor');
    validateEditor(editor);
  }

  const save = (e) => {
    if (!user.protected) {
      validateProfileEditor(e);
      if (user.displayName && user.email)
        actions.auth.save();
    }
  }
  const changePassword = (e) => {
    validateProfileEditor(e);
    if (user.oldPassword && user.password && user.confirmPassword == user.password)
      actions.auth.changePassword();
  }

  return (
    <section class="section" key="profile_view">
      <h1 class="title">
        Profile
      </h1>
      <hr />
      <div class="box is-clearfix profile_box">
        {user.avatarUrl && (
          <div class="profile-image">
            <img src={user.avatarUrl} alt={user.displayName} />
            <div class="slant"></div>
          </div>)}
        <div class="profile-fields">
          {editing && !user.protected && (<ProfileEdit profile={user} onchange={setProfile} />)}
          {!editing && !changePass && (<ProfileDisplay profile={user} />)}
          {changePass && (<ProfileChangePassword profile={user} onchange={setProfile} />)}
        </div>
        <div class="profile-buttons">
          {editing && (
            <div>
              <a class="button" href="javascript:void(0)" onclick={toggleEdit}>
                <span class="icon" title="Cancel">
                  <i class="fas fa-user-times"></i>
                </span>
                <span class="info">Cancel</span>
              </a>
              <a class="button" href="javascript:void(0)" onclick={save}>
                <span class="icon" title="Save Profile">
                  <i class="fas fa-user-check"></i>
                </span>
                <span class="info">Save Profile</span>
              </a>
            </div>
          )}
          {changePass && (
            <div>
              <a class="button" href="javascript:void(0)" onclick={toggleChangePass}>
                <span class="icon" title="Cancel">
                  <i class="fas fa-user-times"></i>
                </span>
                <span class="info">Cancel</span>
              </a>
              <a class="button" href="javascript:void(0)" onclick={changePassword}>
                <span class="icon" title="Set Password">
                  <i class="fas fa-user-check"></i>
                </span>
                <span class="info">Set Password</span>
              </a>
            </div>
          )}
          {!user.protected && !editing && !changePass && (
            <div>
              <a class="button" href="javascript:void(0)" onclick={toggleEdit}>
                <span class="icon">
                  <i class="fas fa-user-edit"></i>
                </span>
                <span class="info">Edit Profile</span>
              </a>
              {hasPass && (<a class="button" href="javascript:void(0)" onclick={toggleChangePass}>
                <span class="icon" title="Change Password">
                  <i class="fas fa-user-shield"></i>
                </span>
                <span class="info">Change Password</span>
              </a>)}
            </div>
          )}
        </div>
      </div>
      {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
    </section >
  )
}

const ProfileEdit = ({ profile, onchange }) => (state, actions) => {
  return (<div class="profile-editor">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <RequiredField class="input is-expanded" value={profile.displayName} placeholder="Name" onchange={onchange('displayName')} disabled={profile.protected} />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Email</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <RequiredField class="input is-expanded" value={profile.email} placeholder="Email" onchange={onchange('email')} disabled={profile.protected} />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Avatar URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <StringField placeholder="Avatar URL" value={profile.avatarUrl} onchange={onchange('avatarUrl')} className="is-expanded" disabled={profile.protected} />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Slack Name</label>
      </div>
      <div class="field-body">
        <StringField placeholder="Slack Name" value={profile.slackName} onchange={onchange('slackName')} className="is-expanded" disabled={profile.protected} />
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Role(s)</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <TagsInput placeholder="Role(s)" value={untagify((profile.roles || []).join(','))} className="is-expanded" disabled={true} />
          </p>
        </div>
      </div>
    </div>
  </div>)
}

const ProfileDisplay = ({ profile }) => (state, actions) => {
  return (<div class="profile-display">
    <h3 class="title">{profile.displayName}</h3>
    <div class="profile-info">
      <span class="icon" title="Email">
        <i class="fas fa-envelope"></i>
      </span>
      <span class="field-value">{profile.email}</span>
    </div>
    <div class="profile-info">
      <span class="icon" title="Slack Name">
        <i class="fab fa-slack"></i>
      </span>
      <span class="field-value">{profile.slackName}</span>
    </div>
    <div class="profile-info">
      <span class="icon" title="Roles">
        <i class="fas fa-user-cog"></i>
      </span>
      <span class="field-value">{profile.roles}</span>
    </div>
  </div>
  )
}

const ProfileChangePassword = ({ profile, onchange }) => (state, actions) => {
  return (<div class="profile-editor">
    <div class="field">
      <label class="label">Current Password</label>
      <p class="control">
        <RequiredField class="input is-expanded" value={profile.oldPassword} placeholder="Current Password" onchange={onchange('oldPassword')} />
      </p>
    </div>
    <div class="field">
      <label class="label">New Password</label>
      <p class="control">
        <RequiredField class="input is-expanded" value={profile.password} placeholder="New Password" onchange={onchange('password')} />
      </p>
    </div>
    <div class="field">
      <label class="label">Confirm Password</label>
      <p class="control">
        <MatchField class="input is-expanded" value={profile.confirmPassword} field={profile.password} fieldName="New Password" placeholder="Confirm Password" onchange={onchange('confirmPassword')} />
      </p>
    </div>
  </div>)
}
