import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

import { untagify, validateButton, validateEditor } from '../../modules/utils';

import { validate } from '../../modules/language';
import { Loading } from './loading';
import { CheckBox, RequiredField, TagsInput, StringField, MetaData } from './common';
import { EditorButtons } from './editor-buttons';

export const LanguageEditor = ({ state, actions, id }) => {

  const getData = () => {
    actions.getLanguage(id);
  }

  const setValue = (prop, value) => {
    actions.setState({
      [prop]: value,
      modified: true
    });
  }

  const validateSaveButton = (el) => {
    validateButton(el, validate(state))
  }

  const save = (e, exit, empty) => {
    if (state.modified) {
      const err = validate(state);
      validateEditor(e.target.closest('.language-editor'));
      if (err) {
        actions.setState({ error: err });
      } else {
        actions.saveLanguage({ exit, empty });
      }
    }
  }

  const saveAndExit = (e) => {
    save(e, true);
  }

  const saveAndNew = (e) => {
    save(e, false, true);
  }

  const onChange = (prop) => ({ target }) => {
    setValue(prop, target.value);
  }

  const setDefault = (value) => {
    if (value && state.fallbackCode) {
      setValue('fallbackCode', '')
    }
    setValue('isDefault', !!value)
  }

  const onMonthsChange = (months) => {
    setValue('months', months.split(/\s*,\s*/));
  }

  return (
    <div class="language-editor" oncreate={getData} >
      {state.loaded ? (<div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Name</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Name" value={state.name} onchange={onChange('name')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Code</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Code" value={state.code} onchange={onChange('code')} className="is-expanded" nowhitespace={true} toLowerCase={true} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Canonical Locale</label>
          </div>
          <div class="field-body">
            <StringField placeholder="Canonical Locale" value={state.canonicalLocale} onchange={onChange('canonicalLocale')} className="is-expanded" nowhitespace={true} toLowerCase={true} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Fallback Code</label>
          </div>
          <div class="field-body">
            <StringField placeholder="Fallback Code" value={state.fallbackCode} onchange={onChange('fallbackCode')} className="is-expanded" nowhitespace={true} toLowerCase={true} disabled={state.isDefault} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Months Names</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <TagsInput placeholder="Months Names" value={untagify((state.months || []).join(','))} onchange={onMonthsChange} className="is-expanded" />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_enabled">Enabled</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_enabled" active={state.enabled} toggle={value => setValue('enabled', !!value)} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_default">Is Default</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_default" active={state.isDefault} toggle={setDefault} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_excludeFromSitemap">Exclude From Sitemap</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_excludeFromSitemap" active={state.excludeFromSitemap} toggle={value => setValue('excludeFromSitemap', !!value)} />
          </div>
        </div>
        <MetaData {...state} />
        <EditorButtons clear={actions.getLanguage} cancelTo="languages" save={save} validateSave={validateSaveButton} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!state.error} />
      </div>) : (<Loading />)}
      {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
    </div>)
}
