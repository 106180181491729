import { h } from 'hyperapp';
import { dateToLocaleString } from '../../modules/utils'
import { Modal, Pagination, Loading, Dropdown, Tr } from './common'
import Th from './table-header'

import '../../css/task-list.css'

export const TaskList = ({ state, actions, queue, route }) => {

  if (!queue && state.queues && state.queues.length) {
    history.pushState(location.pathname, '', '/tasks/' + state.queues[0].id);
    return null
  }

  const getData = () => {
    actions.getQueues();
    actions.getActions();
    if (queue) {
      actions.getTasks({ queue });
    }
  }
  const selectQueue = (queue) => {
    // const key = currentTarget.dataset['key'];
    // if (state.queue == key) return;
    // document.querySelectorAll('.tabs li').forEach(t => {
    //   t.classList.remove('is-active');
    // })
    // currentTarget.parentElement.classList.add("is-active");
    actions.getTasks({ queue });
    history.pushState(location.pathname, '', '/tasks/' + queue);
  }

  const expandBatch = (batch) => {
    actions.toggleBatch(batch);
  }

  const cancelTask = (task, method) => {
    const modal = {
      data: task.index,
      title: `Are you sure you want to delete ${method}?`,
      confirmText: "Delete",
      onConfirm: (id) => actions.cancelTask(id),
      open: true
    }
    actions.toggleModal(modal);
  }

  const cancelBatch = (batch) => {
    var count = batch.tasks.length;
    if (!batch.batchId && count) {
      return cancelTask(batch.tasks[0], batch.method)
    }
    const modal = {
      data: batch.batchId,
      title: `Are you sure you want to delete ${count} task${(count > 1 ? 's' : '')}?`,
      confirmText: "Delete",
      onConfirm: (id) => actions.cancelBatch(id),
      open: true
    }
    actions.toggleModal(modal);
  }
  const methodFormat = (m) => {
    const l = (m + "").length;
    return (m + "").replace(/(^.|[A-Z])/g, (s, p, i, t) => {
      return (i == 0 ? s.toUpperCase() : (t[i - 1] == t[i - 1].toLowerCase() || (i < l - 1 && t[i + 1] == t[i + 1].toLowerCase()) ? ` ${s}` : s))
    });
  }

  return (
    <div class="schedule-list item-list" oncreate={getData}>
      {state.loaded ? (<div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Task Queue</label>
          </div>
          <div class="field-body">
            <div class="control">
              <Dropdown text="--- select a queue ---" items={state.queues} valueField="id" displayField="label" onchange={val => selectQueue(val)} value={queue} />
            </div>
          </div>
        </div>
        <hr />
        <div>
          {!state.loading && state.items ? (<div>
            <table class="table is-narrow is-fullwidth">
              <thead>
                <tr>
                  {/* <Th field="action" filter={state.filter} order={state.order} items={state.methods} actions={actions} route={route} format={methodFormat} /> */}
                  <th>Action</th>
                  <Th field="created" order={state.order} actions={actions} route={route} />
                  <th>Tasks</th>
                  {/* <th>Provider User ID</th>
                  <th>User ID</th>
                  <th>Parameters</th> */}
                  <th colspan="1"></th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th colspan="5" class="has-text-right">
                    <Pagination count={state.count} skip={state.skip} take={state.take} actions={actions} route={route} />
                  </th>
                </tr>
              </tfoot>
              <tbody>
                {(state.items || []).map((b) => {
                  var methods = [];
                  if (b.tasks && b.tasks.length && b.tasks[0].args && b.tasks[0].args.rule && b.tasks[0].args.rule.actions && b.tasks[0].args.rule.actions.length) {
                    b.tasks[0].args.rule.actions.forEach(a => {
                      var res = a.method;
                      if (a.parameters && a.parameters.length) {
                        res += (" " + a.parameters.map(p => p.value).join(","))
                      }
                      methods.push(res)
                    })
                  } else if (b.method) {
                    methods = [b.method];
                  }
                  // const actions = methods.map(m => {
                  //   var action = state.actionMethods.find(a => (a.name == method));
                  //   return action && action.label || m;
                  // });
                  const count = b.tasks.length;
                  const scheduled = count && b.tasks[0].scheduled;
                  return (<Tr>
                    <tr onclick={() => expandBatch(b)}>
                      <td>{methods.map(a => (<span>{a}<br /></span>))}</td>
                      <td>{dateToLocaleString(b.created)}</td>
                      <td>{count + ' task' + (count > 1 ? 's' : '')}</td>
                      <td>
                        {scheduled && (<button class="button is-white is-rounded" title={'Cancel ' + (count > 1 ? 'batch' : 'task')} onclick={() => cancelBatch(b)}>
                          <span class="icon is-small"><i class="fas fa-trash"></i></span>
                        </button>)}
                      </td>
                    </tr>
                    {b.isExpanded && <tr>
                      <td colspan="5">
                        <BatchTable batch={b} action={action} cancelTask={cancelTask} />
                      </td>
                    </tr>}
                  </Tr>)
                })}
              </tbody>
            </table>
          </div>) : (<Loading />)}
        </div>
      </div>) : (<Loading />)
      }
      {state.error ? (<div class="notification">{state.error}</div>) : null}
      {state.modal && (<Modal toggle={actions.toggleModal} {...state.modal} />)}
    </div>
  )
}

const BatchTable = ({ batch, action, cancelTask }) => {
  let nameColumn = 'User ID'
  let timeColumn = "Processed"
  let hasError = false;
  if (batch.tasks && batch.tasks.length) {
    if (batch.tasks[0].args.event) {
      if (batch.tasks[0].args.event.providerUserId) {
        nameColumn = "Provider User ID"
      } else if (batch.tasks[0].args.event.segment != -1) {
        nameColumn = "Segment"
      }
      if (batch.tasks[0].scheduled) {
        timeColumn = "Scheduled"
      }
    }
    if (batch.tasks[0].error) {
      hasError = true;
    }
  }

  return (<table class="table is-narrow is-fullwidth">
    <thead>
      <tr>
        <th>{nameColumn}</th>
        <th>{timeColumn}</th>
        <th>Parameters</th>
        {hasError ? (<th>Error</th>) : null}
        <th colspan="2"></th>
      </tr>
    </thead>
    <tbody>
      {(batch.tasks || []).map((t) => {
        const args = t.args.trigger && t.args.trigger.event || t.args.arg && JSON.parse(t.args.arg) || {};
        const userId = t.args.trigger && t.args.trigger.event.userId || t.args.ctx && JSON.parse(t.args.ctx).event.userId;
        const params = t.args.trigger && t.args.trigger.action && t.args.trigger.action.parameters.map(p => p.value) || t.params || [];
        var triggeredOn = args.providerUserId;
        if (!triggeredOn && args.segment != -1) triggeredOn = args.segment;
        return (<tr>
          <td>{triggeredOn || userId}</td>
          <td>{t.processed || t.scheduled ? dateToLocaleString(t.processed || t.scheduled) : ''}</td>
          <td>{params.map((p, pi) => {
            const label = action && action.params && action.params[pi].label;
            return (<span style={{display:'block'}}>
              {label} : {p}
            </span>)
          })}</td>
          {hasError ? (<th>t.error</th>) : null}
          <td><span>&nbsp;</span></td>
          <td>
            {!batch.tasks.length > 1 && !!t.scheduled ? (<button class="button is-white is-rounded" title="Cancel task" onclick={() => cancelTask(t, action && action.label || batch.method)}>
              <span class="icon is-small"><i class="fas fa-trash"></i></span>
            </button>) : null}
          </td>
        </tr>)
      })}
    </tbody>
  </table>)
}
