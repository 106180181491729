import { client } from '../modules/client'
import { validate, User } from '../modules/user'

export const user = {
  setState: (state) => (state),
  getUser: (id) => async (state, actions) => {
    await actions.getRoles();
    if (id) {
      const res = await client.getUser(id);
      if (res.error) {
        return actions.setState({
          error: `Something went wrong. ${res.error}`,
          loaded: true
        });
      }
      if (res.item) {
        return actions.setState(Object.assign(new User(res.item), {
          error: state.error,
          loaded: true
        }));
      }
      else {
        return actions.setState({
          error: "User not found",
          loaded: true
        });
      }
    }
    return actions.setState(Object.assign(new User(), { loaded: true }));
  },
  saveUser: () => async (state, actions) => {
    const err = validate(state);
    if (!!err) {
      return actions.setState({
        error: err
      })
    }
    const res = await client.saveUser(state);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      });
    }
    history.pushState(location.pathname, '', '/users')
    return actions.setState({})
  },
  getRoles: () => async (state, actions) => {
    if (!state.rolesAvailable) {
      const rolesResp = await client.getRoles();
      return actions.setState({ error: rolesResp.error, rolesAvailable: (rolesResp.items || []).map(r => r.role) });
    }
  }
}
