import { h } from 'hyperapp'
import { getFilterPath } from '../modules/utils'
import { Tr, TrTagsCell } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Policies = ({ state, actions }) => {
  const { filter, order } = state.policies;

  const toggleFilter = (prop) => (filters) => {
    actions.location.go(getFilterPath(prop, filters))
    actions.policies.filterPolicies({ [prop]: filters })
  }

  const deleteClick = ({ id, path }) => {
    const modal = {
      data: id,
      label: path,
      onConfirm: (id) => actions.policies.deletePolicy(id),
      open: true,
      toggle: actions.policies.toggleModal
    }
    actions.policies.toggleModal(modal);
  }

  return (
    <ListView name="policy" columns="3" protectedField="protected" getItems={actions.policies.getPolicies} goToPage={actions.policies.goToPage} onDelete={deleteClick} {...state.policies}
      renderHead={() => (<Tr>
        <Th field="path" order={order} actions={actions.policies} route={actions.location} />
        <th>Method(s)</th>
        <th>Role(s)</th>
      </Tr>)}
      renderBody={item => (<Tr>
        <td>{item.path}</td>
        <td>
          <TrTagsCell tags={item.methods} filter={filter && filter.methods} onchange={toggleFilter('methods')} />
        </td>
        <td>
          <TrTagsCell tags={item.roles} filter={filter && filter.roles} onchange={toggleFilter('roles')} />
        </td>
      </Tr>)
      } />
  )
}
