import { Items } from './classes'

export class Tasks extends Items {
  constructor() {
    super('created');
    this.queue = '';
    this.queues = [];
    this.modal = null;
  }
}
