import { h } from 'hyperapp'
import { Dropdown, Modal, RequiredField } from './common'
import { tryParse } from '../../modules/utils'
import { Loading } from './loading';

import '../../css/events.css'

const validate = (e) => {
  if (!e.changed || !e.id) return false;
  for (var i = 0, j = e.fields.length; i < j; i++) {
    const f = e.fields[i];
    if (!f.id || !f.label || !f.type) return false;
    if (f.values) {
      for (var k = 0, l = f.values.length; k < l; k++) {
        const v = f.values[k];
        if (!v.key || !v.value) return false;
      }
    }
  }
  return true;
}

export const CrmEvents = ({ state, actions, id }) => {
  const importEvents = () => {
    const modal = {
      title: `Import events`,
      confirmText: "Import",
      content: (<textarea id="import_event" rows="25" class="textarea" placeholder="Import events" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_event').value;
        const res = tryParse(text);
        if (res)
          actions.importEvents(res);
      },
      open: true,
      toggle: actions.toggleModal
    }
    actions.toggleModal(modal);
  }

  const exportEvents = (r) => {
    actions.exportEvents(r).then(events => {
      const modal = {
        title: `Export events`,
        content: (<textarea id="export_events" rows="25" class="textarea" value={JSON.stringify(events, null, 2)} readonly autofocus></textarea>),
        open: true,
        toggle: actions.toggleModal,
        buttons: [{
          text: "Copy to clipboard",
          class: "is-primary",
          onclick: () => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(JSON.stringify(events))
            }
          }
        }, {
          text: "Save to file",
          class: "is-info",
          onclick: () => {
            const json = JSON.stringify(events);
            const blob = new Blob([json], { type: 'application/json' });
            const link = document.createElement('a');
            link.setAttribute('download', 'export.json');
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);

            // wait for the link to be added to the document
            window.requestAnimationFrame(function () {
              var event = new MouseEvent('click');
              link.dispatchEvent(event, true, true);
              document.body.removeChild(link);
              URL.revokeObjectURL(blob);
            });
          }
        }]
      }
      actions.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_events').focus();
      })
    });
  }

  const exportEventsClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    exportEvents()
    return false
  }

  const addNew = (e) => {
    var id = "";
    const modal = {
      title: `New CRM event`,
      confirmText: "Save",
      content: (<RequiredField placeholder="Topic" value={id} onchange={({ target: { value } }) => { id = value }} className="toUpperCase" />),
      onConfirm: () => {
        actions.addNew(id);
      },
      open: true
    }
    actions.toggleModal(modal);
  }
  const fieldChange = (e, i, f) => {
    e.fields[i] = f;
    if (f.id && f.name && f.label && f.type) {
      e.fields = e.fields.sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1));
    }
    actions.setEvent(e);
  }
  const removeField = (e, idx) => {
    e.fields.splice(idx, 1);
    actions.setEvent(e);
  }
  const checkedChange = ({ target }) => {
    if (target.checked) {
      document.querySelectorAll('input[name="tabs"]').forEach(c => {
        if (c.id != target.id) {
          c.checked = false;
        }
      })
      history.pushState(location.pathname, '', '/crmevents/' + target.id)
    }
    else {
      history.pushState(location.pathname, '', '/crmevents/')
    }
  }
  const deleteClick = ({ id }) => {
    const modal = {
      data: id,
      title: `Are you sure you want to delete ${id}?`,
      confirmText: "Delete",
      onConfirm: (id) => actions.removeEvent(id),
      open: true
    }
    actions.toggleModal(modal);
  }
  const editTitle = (e) => {
    var id = e.id;
    const modal = {
      title: `Edit event name`,
      confirmText: "Save",
      content: (<RequiredField placeholder="Event name" value={id} onchange={({ target: { value } }) => { id = value }} />),
      onConfirm: () => {
        e.id = id;
        actions.setEvent(e);
      },
      open: true
    }
    actions.toggleModal(modal);
  }
  const validateSave = (target, event) => {
    if (validate(event)) {
      target.removeAttribute('disabled');
    } else {
      target.setAttribute('disabled', 'disabled');
    }
  }
  const { crmEvents, loaded } = state;

  return (<div class="events" oncreate={actions.getEvents} style={{ "min-width": "35em" }}>
    {loaded ? (<div>
      <div class="add-new buttons">
        <button class="button is-primary" onclick={importEvents}>Import</button>
        {!!crmEvents.length && (<button class="button is-info" onclick={exportEventsClick}>Export</button>)}
        <button class="button is-primary" onclick={addNew}>New CRM Event</button>
      </div>
      <div>
        {crmEvents.map(e => {
          const isChecked = id && id.toLowerCase() == e.id.toLowerCase();
          return (<div class="event">
            {isChecked ? (<input id={e.id} type="checkbox" name="tabs" checked="checked" onchange={checkedChange} />) : (<input id={e.id} type="checkbox" name="tabs" onchange={checkedChange} />)}
            <label class="event-header" for={e.id}>{e.id}</label>
            <div class="event-container">
              <div class="event-content">
                <div class="columns">
                  <div class="column is-one-fifth">
                    <label class="label">External</label>
                  </div>
                  <div class="column is-one-fifth">
                    <label class="label">Internal</label>
                  </div>
                  <div class="column is-one-fifth">
                    <label class="label">Label</label>
                  </div>
                  <div class="column is-one-fifth">
                    <label class="label">Type</label>
                  </div>
                  <div class="column is-one-fifth"></div>
                </div>
                {e.fields.map((f, i) => (<CrmEventField field={f} onchange={(f) => fieldChange(e, i, f)} onremove={() => removeField(e, i)} />))}
                <div class="is-clearfix">
                  <hr />
                  <div class="is-pulled-left">
                    <div class="buttons">
                      <span class="button is-info" onclick={() => editTitle(e)}>Edit event name</span>
                      <span class="button is-info" onclick={() => actions.addField(e)}>Add field</span>
                      <button class="button is-info" onclick={() => exportEvents(e)}>Export</button>
                    </div>
                  </div>
                  <div class="is-pulled-right">
                    <div class="buttons">
                      <span class="button is-success" onclick={() => actions.saveEvent(e)} oncreate={t => validateSave(t, e)} onupdate={t => validateSave(t, e)} disabled>Save changes</span>
                      <span class="button is-danger" onclick={() => deleteClick(e)}>Delete</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)
        })}
      </div>
    </div>) : (<Loading />)}
    {state.modal && (<Modal toggle={actions.toggleModal} {...state.modal} />)}
  </div >)
}


const CrmEventField = ({ field, onchange, onremove }) => {
  const { externalId, id, label, type } = field;
  const setField = (key, value) => {
    field[key] = value;
    onchange(field);
  }
  return (
    <div class="columns">
      <div class="column is-one-fifth">
        <div class="field">
          <div class="control">
            <RequiredField placeholder="External" value={externalId} onchange={({ target: { value } }) => setField('externalId', value)} />
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
        <div class="field">
          <div class="control">
            <RequiredField placeholder="Internal" value={id} onchange={({ target: { value } }) => setField('id', value)} />
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
        <div class="field">
          <div class="control">
            <RequiredField placeholder="Label" value={label} onchange={({ target: { value } }) => setField('label', value)} />
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
        <div class="field">
          <div class="control">
            <Dropdown text=" --- Select a type --- " items={['bool', 'date', 'datetime', 'eventType', 'fraction', 'float', 'number', 'string']} onchange={value => setField('type', value)} value={type} />
          </div>
        </div>
      </div>
      <div class="column is-one-fifth">
        <div class="control">
          <button class="button is-white is-rounded" title="Remove field" onclick={onremove}>
            <span class="icon is-small"><i class="far fa-times-circle"></i></span>
          </button>
        </div>
      </div>
    </div>)
}
