import { client } from '../modules/client'
import { client } from '../modules/client'
import { generateID } from '../modules/utils'

export const freespins = {
    setState: (state) => (state),
    getFreespins: () => async (state, actions) => {
        const res = await client.getFreespins();
        if (res.error) {
            return {
                error: res.error
            }
        }
        return actions.setState({
            freespins: res.items
                .sort((a, b) => (a.minValue - b.minValue))
                .map(f => Object.assign({}, f, { minValue: f.minValue || '', maxValue: f.maxValue || '' })),
            loaded: true
        });
    },
    addFreespins: () => async (state, actions) => {
        state.freespins.push({ id: generateID(), name: '' });
        return actions.setState({ freespins: state.freespins, modified: true });
    },
    editFreespins: (fs) => (state, actions) => {
        const freespins = state.freespins.map(i => i.id === fs.id ? Object.assign({}, i, fs) : i);
        return actions.setState({ freespins, modified: true });
    },
    saveFreespins: () => async (state, actions) => {
        const res = await client.saveFreespins(state.freespins
            .filter(fs => (fs.name != "" || fs.minValue != "" || fs.maxValue != ""))
            .map(f => Object.assign({}, f, { minValue: Number(f.minValue), maxValue: Number(f.maxValue) }))
            .sort((a, b) => (a.minValue - b.minValue)))
        if (res.error) {
            return actions.setState({
                error: res.error
            })
        }
        return actions.setState({
            freespins: res.items
                .sort((a, b) => (a.minValue - b.minValue))
                .map(f => Object.assign({}, f, { minValue: f.minValue || '', maxValue: f.maxValue || '' })),
            modified: false
        });
    },
    deleteFreespins: (fs) => async (state, actions) => {
        const freespins = state.freespins.filter(c => (c.id !== fs.id));
        return actions.setState({ freespins, modified: true });
    }
}
