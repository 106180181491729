import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'
import { RulesStatus } from '../components/rules-status'

export const SidePanel = ({ rights, sidepanel, version, setstate, logout }) => {
  const isActive = (path) =>
    ((new RegExp(`^/${path}(/.*)?$`)).test(location.pathname) ? 'is-active navbar-item' : 'navbar-item')

  const toggleMenu = (e) => {
    setstate({ sidepanel: !sidepanel });
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  // const gitRepoUrl = 'https://github.com/moonwalker/backend'
  // const commitLink = (state.version && state.version != 'dev') ? `${gitRepoUrl}/commit/${state.version}` : gitRepoUrl

  let hasRules = false;
  let hasFormulas = false;
  let hasCapsules = false;
  let hasBatch = false;
  let hasEvents = false;
  let hasConfig = false;
  let hasAdmin = false;

  if (!!rights) {
    hasRules = rights.rules.list;
    hasFormulas = rights.formulas.list;
    hasCapsules = rights.capsules.list;
    hasBatch = rights.trigger.trigger || rights.tasks.list;
    hasEvents = rights.events.list || rights.crmEvents.list || rights.query.execute || rights.emulate.emulate;
    hasConfig = rights.config.list || rights.countries.list || rights.currencies.list || rights.languages.list || rights.freespins.list || rights.games.upload || rights.siteconfigs.list;
    hasAdmin = rights.users.list || rights.roles.list || rights.policies.list;
  }

  return (<div class="side-menu">
    <a class={"navbar-item sidemenu-icon" + (sidepanel ? ' is-active' : '')} onclick={toggleMenu}>
      <span class="icon">
        <i class="fas fa-bars"></i>
      </span>
    </a>
    <div class={'navbar is-dark' + (sidepanel ? ' is-open' : '')}>
      <aside class="menu">
        {(hasRules || hasFormulas || hasCapsules) && (
          <p class="menu-label">
            Rules<RulesStatus compact={true} />
          </p>)}
        {(hasRules || hasFormulas) && (<ul class="menu-list">
          {hasRules && <li><Link class={isActive('rules')} to="/rules?filter=active%3Atrue" title="Back End Automation Rules"><span class="icon"><i class="fas fa-atom"></i></span><span>B.E.A.R.</span></Link></li>}
          {hasFormulas && <li><Link class={isActive('formulas')} to="/formulas?filter=active%3Atrue" title="Front End Automation Rule engine"><span class="icon"><i class="fas fa-cloud"></i></span><span>F.E.A.R.</span></Link></li>}
          {hasCapsules && <li><Link class={isActive('capsules')} to="/capsules" title="Capsules"><span class="icon"><i class="fas fa-capsules"></i></span><span>Capsules</span></Link></li>}
        </ul>)}
        {hasBatch && (<p class="menu-label">
          Batch
        </p>)}
        {hasBatch && (<ul class="menu-list">
          {rights.trigger && (<li><Link class={isActive('trigger')} to="/trigger"><span class="icon"><i class="fas fa-file-csv"></i></span><span>Trigger</span></Link></li>)}
          {rights.tasks && rights.tasks.list && (<li><Link class={isActive('tasks')} to="/tasks"><span class="icon"><i class="fas fa-tasks"></i></span><span>Tasks</span></Link></li>)}
        </ul>)}
        {hasEvents && (<p class="menu-label">
          Events
        </p>)}
        {hasEvents && (<ul class="menu-list">
          {rights.events && rights.events.list && (<li><Link class={isActive('events')} to="/events"><span class="icon"><i class="fas fa-book"></i></span><span>Events</span></Link></li>)}
          {rights.crmEvents && rights.crmEvents.list && (<li><Link class={isActive('crmEvents')} to="/crmevents"><span class="icon"><i class="fas fa-address-book"></i></span><span>CRM Events</span></Link></li>)}
          {rights.query && (<li><Link class={isActive('query')} to="/query"><span class="icon"><i class="fas fa-database"></i></span><span>Query</span></Link></li>)}
          {rights.emulate && (<li><Link class={isActive('emulate')} to="/emulate"><span class="icon"><i class="fas fa-play-circle"></i></span><span>Emulate</span></Link></li>)}
        </ul>)}
        {hasConfig && (<p class="menu-label">
          Config
        </p>)}
        {hasConfig && (<ul class="menu-list">
          {rights.config && rights.config.list && (<li><Link class={isActive('config')} to="/config"><span class="icon"><i class="fas fa-cog"></i></span><span>Config</span></Link></li>)}
          {rights.countries && rights.countries.list && (<li><Link class={isActive('countries')} to="/countries"><span class="icon"><i class="fas fa-globe"></i></span><span>Countries</span></Link></li>)}
          {/* {rights.currencies && rights.currencies.list && (<li><Link class={isActive('currencies')} to="/currencies"><span class="icon"><i class="fas fa-money-bill-wave"></i></span><span>Currencies</span></Link></li>)}
          {rights.languages && rights.languages.list && (<li><Link class={isActive('languages')} to="/languages"><span class="icon"><i class="fas fa-flag"></i></span><span>Languages</span></Link></li>)} }
          {rights.siteconfigs && rights.siteconfigs.list && (<li><Link class={isActive('siteconfigs')} to="/siteconfigs"><span class="icon"><i class="fas fa-cogs"></i></span><span>Siteconfigs</span></Link></li>)*/}
          {rights.freespins && rights.freespins.list && (<li><Link class={isActive('freespins')} to="/freespins"><span class="icon"><i class="fas fa-trophy"></i></span><span>Freespins</span></Link></li>)}
          {rights.games && rights.games.upload && (<li><Link class={isActive('games')} to="/games/upload"><span class="icon"><i class="fas fa-dice"></i></span><span>Games</span></Link></li>)}
        </ul>)}
        {hasAdmin && (<p class="menu-label">
          Administration
        </p>)}
        {hasAdmin && (<ul class="menu-list">
          {rights.users && rights.users.list && (<li><Link class={isActive('users')} to="/users"><span class="icon"><i class="fas fa-users"></i></span><span>Users</span></Link></li>)}
          {rights.roles && rights.roles.list && (<li><Link class={isActive('roles')} to="/roles"><span class="icon"><i class="fas fa-scroll"></i></span><span>Roles</span></Link></li>)}
          {rights.policies && rights.policies.list && (<li><Link class={isActive('policies')} to="/policies"><span class="icon"><i class="fas fa-traffic-light"></i></span><span>Policies</span></Link></li>)}
        </ul>)}
        <p class="menu-label">
          Profile
        </p>
        <ul class="menu-list">
          <li><Link class={isActive('profile')} to="/profile"><span class="icon"><i class="fas fa-user"></i></span><span>Profile</span></Link></li>
          <li><a class="navbar-item" onclick={logout}><span class="icon"><i class="fas fa-sign-out-alt"></i></span><span>Logout</span></a></li>
        </ul>
        <div class="version">
          {/* <a href={commitLink} target="_blank"><i class="fas fa-code-branch"></i>&nbsp;{state.version}</a> */}
          <i class="fas fa-code-branch"></i>&nbsp;{version}
        </div>
      </aside>
    </div>
  </div>)
}
