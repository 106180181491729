import { h } from 'hyperapp'
import { Dropdown, Modal, RequiredField } from './common'
import { Loading } from './loading';

import '../../css/events.css'

const validate = (e) => {
  if (!e.changed || !e.id) return false;
  for (var i = 0, j = e.fields.length; i < j; i++) {
    const f = e.fields[i];
    if (!f.id || !f.label || !f.type) return false;
    if (f.values) {
      for (var k = 0, l = f.values.length; k < l; k++) {
        const v = f.values[k];
        if (!v.key || !v.value) return false;
      }
    }
  }
  return true;
}

export const Events = ({ state, actions, id }) => {
  const addNew = (e) => {
    var id = "";
    const modal = {
      title: `New event topic`,
      confirmText: "Save",
      content: (<RequiredField placeholder="name" value={id} onchange={({ target: { value } }) => { id = value }} className="toUpperCase" />),
      onConfirm: () => {
        actions.addNew(id);
      },
      open: true
    }
    actions.toggleModal(modal);
  }
  const fieldChange = (e, i, f) => {
    e.fields[i] = f;
    if (f.id && f.label && f.type) {
      e.fields = e.fields.sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1));
    }
    actions.setEvent(e);
  }
  const removeField = (e, idx) => {
    e.fields.splice(idx, 1);
    actions.setEvent(e);
  }
  const checkedChange = ({ target }) => {
    if (target.checked) {
      document.querySelectorAll('input[name="tabs"]').forEach(c => {
        if (c.id != target.id) {
          c.checked = false;
        }
      })
      history.pushState(location.pathname, '', '/events/' + target.id)
    }
    else {
      history.pushState(location.pathname, '', '/events/')
    }
  }
  const deleteClick = ({ id }) => {
    const modal = {
      data: id,
      title: `Are you sure you want to delete ${id}?`,
      confirmText: "Delete",
      onConfirm: (id) => actions.removeEvent(id),
      open: true
    }
    actions.toggleModal(modal);
  }
  const editTitle = (e) => {
    var id = e.id;
    const modal = {
      title: `Edit event topic`,
      confirmText: "Save",
      content: (<RequiredField placeholder="name" value={id} onchange={({ target: { value } }) => { id = value }} />),
      onConfirm: () => {
        e.id = id;
        actions.setEvent(e);
      },
      open: true
    }
    actions.toggleModal(modal);
  }
  const validateSave = (target, event) => {
    if (validate(event)) {
      target.removeAttribute('disabled');
    } else {
      target.setAttribute('disabled', 'disabled');
    }
  }
  const toggle = (target) => (e) => {
    actions.setState({ active: target });
  }
  const { events, internalEvents, loaded, active } = state;
  const activeEvents = (active == "events" ? events : internalEvents) || [];

  return (<div class="events" oncreate={actions.getEvents}>
    {loaded ? (<div>
      <div class="add-new buttons">
        <button class="button is-primary" onclick={addNew}>New Event</button>
      </div>
      <div class="tabs">
        <ul>
          <li class={active == "events" ? "is-active" : ""}><a onclick={toggle("events")}>Custom events</a></li>
          <li class={active == "internal" ? "is-active" : ""}><a onclick={toggle("internal")}>Internal events</a></li>
        </ul>
      </div>
      <div>
        {activeEvents.map(e => {
          const isChecked = id && id.toLowerCase() == e.id.toLowerCase();
          return (<div class="event">
            {isChecked ? (<input id={e.id} type="checkbox" name="tabs" checked="checked" onchange={checkedChange} />) : (<input id={e.id} type="checkbox" name="tabs" onchange={checkedChange} />)}
            <label class="event-header" for={e.id}>{e.id}</label>
            <div class="event-container">
              <div class="event-content">
                {e.fields.map((f, i) => (<EventField field={f} onchange={(f) => fieldChange(e, i, f)} onremove={() => removeField(e, i)} editable={!e.internal} />))}
                {!e.internal && (<div class="is-clearfix">
                  <hr />
                  <div class="is-pulled-left">
                    <div class="buttons">
                      <span class="button is-info" onclick={() => editTitle(e)}>Edit event topic</span>
                      <span class="button is-info" onclick={() => actions.addField(e)}>Add field</span>
                    </div>
                  </div>
                  <div class="is-pulled-right">
                    <div class="buttons">
                      <span class="button is-success" onclick={() => actions.saveEvent(e)} oncreate={t => validateSave(t, e)} onupdate={t => validateSave(t, e)} disabled>Save changes</span>
                      <span class="button is-danger" onclick={() => deleteClick(e)}>Delete</span>
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
          </div>)
        })}
      </div>
    </div>) : (<Loading />)}
    {state.modal && (<Modal toggle={actions.toggleModal} {...state.modal} />)}
  </div >)
}


const EventField = ({ field, onchange, onremove, editable }) => {
  const { id, label, type, values, readonly } = field;
  //  {id: "activityType", label: "Activity Type", type: "number", values: {1: "Bet", 2: "Winning", 3: "Tournament Register", 4: "Game Refund", 5: "Winning Rollback", 6: "Bet Rollback", 7: "EndGame" } },
  const setField = (key, value) => {
    field[key] = value;
    onchange(field);
  }
  const setValue = (idx, key, value) => {
    field.values[idx] = { key, value }
    if (!!key && !!value) {
      field.values = field.values.sort((a, b) => (a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1))
    }
    onchange(field)
  }
  const removeValue = (idx) => {
    field.values.splice(idx, 1);
    onchange(field)
  }
  const addValue = () => {
    field.values.push({ key: '', value: '' });
    onchange(field)
  }
  return (
    <div class="">
      <div class="field is-grouped is-horizontal">
        <div class="control">
          <div class="field-label">
            <label class="label">Name</label>
          </div>
        </div>
        <div class="control">
          <RequiredField placeholder="name" value={id} onchange={({ target: { value } }) => setField('id', value)} disabled={!editable || readonly} />
        </div>
        <div class="control">
          <div class="field-label">
            <label class="label">Label</label>
          </div>
        </div>
        <div class="control">
          <RequiredField placeholder="Label" value={label} onchange={({ target: { value } }) => setField('label', value)} disabled={!editable || readonly} />
        </div>
        <div class="control">
          <div class="field-label">
            <label class="label">Type</label>
          </div>
        </div>
        <div class="control">
          <Dropdown text=" --- select a type --- " items={['bool', 'date', 'datetime', 'eventType', 'fraction', 'float', 'number', 'string']} onchange={value => setField('type', value)} value={type} disabled={!editable || readonly} />
        </div>
        {editable && !readonly && (<div class="control">
          <button class="button group-button is-small is-rounded" title="Add value" onclick={addValue}>
            <span class="icon is-small">
              <i class="fas fa-plus"></i>
            </span>
          </button>
        </div>)}
        {editable && !readonly && (<div class="control">
          <button class="rm_btn delete" title="Remove condition" onclick={onremove}></button>
        </div>)}
      </div>
      {values && values.length ? (<div class="field"><div class="panel" style={{'margin-bottom':'1em'}}><div class="panel-block"><FieldValues values={values} onremove={removeValue} setValue={setValue} editable={editable && !readonly} /></div></div></div>) : null}
    </div>)
}


const FieldValues = ({ values, onremove, setValue, editable }) => {
  return (<div class="field is-horizontal">
    <div class="control">
      <div class="field-label">
        <label class="label">Values</label>
      </div>
    </div>
    <div class="control"><div style={{width:'5em'}} /></div>
    <div class="control">
      {values.map((v, i) => (<div class="field is-grouped is-horizontal">
        <div class="control">
          <div class="field-label">
            <label class="label">Key</label>
          </div>
        </div>
        <div class="control">
          <RequiredField placeholder="Key" value={v.key} onchange={({ target: { value } }) => setValue(i, value, v.value)} disabled={!editable} />
        </div>
        <div class="control">
          <div class="field-label">
            <label class="label">Value</label>
          </div>
        </div>
        <div class="control">
          <RequiredField placeholder="Value" value={v.value} onchange={({ target: { value } }) => setValue(i, v.key, value)} disabled={!editable} />
        </div>
        {editable && (<div class="control">
          <button class="rm_btn delete" title="Remove value" onclick={() => onremove(i)}></button>
        </div>)}
      </div>))}
    </div>
  </div>)
}
