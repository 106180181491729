import { h } from 'hyperapp'
import { PolicyEditor } from './components/policy-editor'

export const Policy = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  return (
    <section class="section" key="policy_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} {id && state.policy ? state.policy.path : 'Policy'}
      </h1>
      <PolicyEditor state={state.policy} actions={actions.policy} id={id} />
    </section>
  )
}
