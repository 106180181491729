import { token } from './token'

const moduleRights = {
  rules: {
    list: ['rules', 'events/internal', 'events', 'actions', 'queries'],
    delete: 'rules|delete',
    import: 'rules/_import|post',
    export: 'rules/_export',
    get: ['rules/:id', 'events/internal', 'events', 'actions', 'queries'],
    play: 'rules/play',
    preview: 'rules/preview',
    save: 'rules|post'
  },
  formulas: {
    list: ['formulas', 'events/formulas', 'actions/reactions', 'queries/datasources'],
    delete: 'formulas|delete',
    import: 'formulas/_import|post',
    export: 'formulas/_export',
    get: ['formulas/:id', 'events/formulas', 'actions/reactions', 'queries/datasources'],
    save: 'formulas|post'
  },
  capsules: {
    list: ['capsules'],
    delete: 'capsules|delete',
    import: 'capsules/_import|post',
    export: 'capsules/_export',
    get: ['capsules/:id'],
    save: 'capsules|post'
  },
  trigger: {
    trigger: 'trigger|post'
  },
  tasks: {
    list: ['tasks', 'actions'],
    cancel: 'tasks|delete'
  },
  events: {
    list: ['events', 'events/internal'],
    delete: 'events|delete',
    save: 'events|post'
  },
  crmEvents: {
    list: ['crmevents'],
    delete: 'crmevents|delete',
    save: 'crmevents|post'
  },
  query: {
    execute: ['query/:id|post', 'events/internal', 'events', 'queries']
  },
  emulate: {
    emulate: 'emulate|post'
  },
  users: {
    list: 'users',
    delete: 'users|delete',
    get: ['users/:id', 'roles'],
    save: 'users|post'
  },
  roles: {
    list: 'roles',
    delete: 'roles|delete',
    save: 'roles|post'
  },
  policies: {
    list: 'policies',
    delete: 'policies|delete',
    get: ['policies/:id', 'roles'],
    save: 'policies|post'
  },
  config: {
    list: 'config'
  },
  countries: {
    list: 'countries',
    delete: 'countries|delete',
    import: 'countries/_import|post',
    export: 'countries/_export',
    get: ['countries/:id'],
    save: 'countries|post'
  },
  currencies: {
    list: 'currencies',
    delete: 'currencies|delete',
    import: 'currencies/_import|post',
    export: 'currencies/_export',
    get: ['currencies/:id'],
    save: 'currencies|post'
  },
  languages: {
    list: 'languages',
    delete: 'languages|delete',
    import: 'languages/_import|post',
    export: 'languages/_export',
    get: ['languages/:id'],
    save: 'languages|post'
  },
  siteconfigs: {
    list: 'siteconfigs',
    delete: 'siteconfigs|delete',
    import: 'siteconfigs/_import|post',
    export: 'siteconfigs/_export',
    get: ['siteconfigs/:id'],
    save: 'siteconfigs|post'
  },
  games: {
    upload: 'games/_upload|post',
    export: 'games/_export|post'
  },
  freespins: {
    list: 'freespins'
  },
}
const formatPath = (p) => {
  return (p[0] != "/" ? "/" : '') + p.toLowerCase().replace(/\/$/, '');
}
const hasRight = (route, priviliges) => {
  var [path, method] = route.split('|');
  method = method || 'get';
  path = formatPath('/api/' + path);
  if (priviliges[path] && (priviliges[path][method] || priviliges[path]['*'])) {
    return true;
  }
  path = path + '/*';
  if (priviliges[path] && (priviliges[path][method] || priviliges[path]['*'])) {
    return true;
  }
  path = path.replace(/\/\*$/, '');
  path = path.substr(0, path.lastIndexOf("/"))
  while (path.length) {
    path = path + '/*';
    if (priviliges[path] && (priviliges[path][method] || priviliges[path]['*'])) {
      return true
    }
    path = path.replace(/\/\*$/, '');
    path = path.substr(0, path.lastIndexOf("/"))
  }
  return !!(priviliges['*'] && (priviliges['*'][method] || priviliges['*']['*']))
}
export const getRights = (policies) => {
  const priviliges = policies.reduce((p, i) => {
    p[formatPath(i.path)] = i.methods.reduce((pa, m) => {
      if (m) {
        pa[m.toLowerCase()] = true;
      }
      return pa
    }, {})
    return p
  }, {});
  const rights = {}
  for (var m in moduleRights) {
    if (!rights[m]) rights[m] = {};
    const module = moduleRights[m];
    for (var p in module) {
      const method = module[p];
      let available = true;
      if (Array.isArray(method)) {
        for (var i = 0, j = method.length; i < j; i++) {
          if (!hasRight(method[i], priviliges)) {
            available = false;
            break;
          }
        }

      } else {
        available = hasRight(method, priviliges)
      }
      rights[m][p] = available;
    }
  }
  return rights;
}

export class Auth {
  constructor() {
    this.authed = token.exists();
    this.user = null;
    this.rights = null;
    this.editing = false;
    this.isAdmin = false;
  }
}
