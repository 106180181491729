import { client } from '../modules/client'
import { validate, Policy } from '../modules/policy'


export const policy = {
  setState: (state) => (state),
  getPolicy: (id) => async (state, actions) => {
    await actions.getRoles();
    if (id) {
      var res = await client.getPolicy(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.item) {
        const err = validate(res.item);
        return actions.setState(Object.assign({ error: err, loaded: true }, res.item));
      }
      return actions.setState({
        error: "Policy not found",
        loaded: true
      })
    }
    return actions.setState(Object.assign(new Policy(), { loaded: true }))
  },
  savePolicy: () => async (state, actions) => {
    const err = validate(state);
    if (!!err) {
      return actions.setState({
        error: err
      })
    }
    const { id, path, methods, roles } = state;
    const res = await client.savePolicy({ id, path, methods, roles });
    if (!res.error) {
      history.pushState(location.pathname, '', '/policies')
    }
    return actions.setState({
      error: `Something went wrong. ${res.error}`
    });
  },
  getRoles: () => async (state, actions) => {
    if (!state.rolesAvailable) {
      const rolesResp = await client.getRoles();
      return actions.setState({ error: rolesResp.error, rolesAvailable: (rolesResp.items || []).map(r => r.role) });
    }
  }
}
