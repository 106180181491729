import { h } from 'hyperapp'
import { Dropdown } from './common'
import { NumericField } from './input-fields'
import { durations } from '../../modules/rule'
import { toKeyValue } from '../../modules/utils'

export const RuleDelay = ({ state, actions }) => {
  const setValue = (value) => {
    actions.setState({ delay: value });
  }
  const setDelay = () => {
    actions.setState({ scheduled: true });
  }
  const removeDelay = () => {
    actions.setState({ scheduled: false, delay: '', repeat: '' });
  }
  if (!state.event) return null;
  if (!state.scheduled) return (<div class="field is-grouped"><div class="control">
    <button class="button is-rounded is-light" onclick={setDelay}>Delay</button>
  </div></div>)
  return (<div class="field is-grouped">
    <div class="control">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">after</label>
        </div>
        <div class="field-body">
          <DelayEditor delay={state.delay} values={toKeyValue(durations)} setValue={setValue} onremove={removeDelay} />
        </div>
      </div>
    </div>
  </div>)
}


const DelayEditor = ({ delay, values, setValue, onremove }) => {
  const matches = (delay || '').match(/(\d+)?([smhDWMY])?/);
  var value, timespan;
  if (matches) {
    value = matches[1];
    timespan = matches[2];
  }
  var items = values;
  if (value && Number(value) > 1) {
    items = items.map(v => {
      return Object.assign({}, v, { value: v.value + 's' });
    })
  }
  return (<div class="field is-grouped">
    <div class="control">
      <NumericField value={value} onchange={({ target: { value } }) => setValue(value + (timespan || ''))} placeholder="Enter duration" />
    </div>
    <div class="control">
      <Dropdown text=" --- select a timespan --- " items={items} valueField="key" displayField="value" orderField="index" onchange={(t) => setValue((value || '') + t)} value={timespan} />
    </div>
    <div class="control">
      <button class="rm_btn delete" title="Remove delay" onclick={onremove}></button>
    </div>
  </div >)
}
