import { h } from 'hyperapp';

import { Dropdown } from './common';

export const ActionEventFieldsEditor = ({ eventFields, values, fields, action, index, setEventFields }) => {

  if (!eventFields) return null;
  if (!values) values = {}

  const setField = (prop, value) => {
    const data = Object.assign({}, values, { [prop]: value });
    setEventFields({ action, index, data });
  }
  return (<div>
    {(eventFields || []).map(field => {
      return (<div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{field.label}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <Dropdown text={`--- select an ${field.type} ---`} items={fields[field.type]} valueField="id" displayField="label" onchange={val => setField(field.id, val)} value={values[field.id]} />
            </p>
          </div>
        </div>
      </div>)
    })}
  </div>)
}
