import { h } from 'hyperapp'
import { validateButton } from '../../modules/utils';
import { validateCriteria } from '../../modules/rule';
import { Divider } from './common';
import { NumericField } from './input-fields';
import { CriteriaItem } from './criteria-editor';
import { EditorButtons } from './editor-buttons';

import '../../css/query-events.css'

const getPreview = (state) => {
  var previewData = null;
  if (state.preview == 'query') {
    const { criteria } = state;
    const { datasource, parameters, filters, aggregations, returns } = criteria || {};
    previewData = { datasource, parameters, filters, aggregations, returns };
  } else if (state.result) {
    const { query, count, error, results, events } = state.result;
    if (state.preview == 'result') {
      previewData = error ? { query, count, error } : { query, count, results };
    } else {
      previewData = events;
    }
  }
  if (previewData) {
    previewData = JSON.stringify(previewData, null, 2);
    previewData = previewData.substring(2, previewData.length - 2);
  }
  return previewData;
}

export const QueryEvents = ({ state, actions }) => {
  const onchange = (c) => {
    actions.setState(Object.assign({ error: '' }, c));
  }

  const newQuery = () => {
    actions.newQuery();
  }

  const runQuery = () => {
    if (!state.userId) {
      return actions.setState({ error: 'UserId is mandatory' });
    }
    const err = validateCriteria(state.criteria, state.datasources);
    if (err) {
      actions.setState({ error: err });
    } else {
      actions.runQuery();
    }
  }

  const validateRun = (el) => {
    const valid = !!state.userId && !validateCriteria(state.criteria, state.datasources);
    validateButton(el, !valid)
  }

  const setUserId = (userId) => {
    actions.setState({ userId });
  }

  const togglePreview = ({ target }) => {
    const key = target.dataset['key'];
    if (state.preview == key) return;
    target.closest('ul').querySelector(`.is-active`).classList.remove('is-active');
    target.closest('li').classList.add('is-active');
    actions.setState({ preview: key });
  }

  const event = {};

  const ds = (state.datasources || []).filter(d => (d.id != 'event' && d.id != 'getUser'));

  return (<div class="query_events">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">UserId</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <NumericField value={state.userId} onchange={({ target: { value } }) => setUserId(value)} placeholder="Enter userId" />
          </p>
        </div>
      </div>
    </div>
    <ul class="criteria_list" oncreate={newQuery}>
      {state.criteria && (<CriteriaItem event={event} criteria={state.criteria} events={state.events} transactions={state.transactions} onchange={onchange} onremove={newQuery} datasources={ds} />)}
    </ul>
    <EditorButtons clear={newQuery} save={runQuery} saveText="Execute" saveIcon="fas fa-play-circle" validateSave={validateRun} />
    <Divider />
    {state.error && (<div class="notification is-danger">{state.error}</div>)}
    <br />
    <div class="tabs is-small is-boxed">
      <ul>
        <li class="is-active" key="query"><a data-key="query" onclick={togglePreview}>Query</a></li>
        {state.result && (<li key="result"><a data-key="result" onclick={togglePreview}>Result</a></li>)}
        {state.result && (<li key="entries"><a data-key="entries" onclick={togglePreview}>Entries</a></li>)}
      </ul>
    </div>
    <div><pre><code>{getPreview(state)}</code></pre></div>
  </div>)
}


