import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

import { validateEditor, validateButton, untagify } from '../../modules/utils';
import { validate } from '../../modules/policy';
import { Loading } from './loading';
import { RequiredField, TagsInput } from './common';
import { EditorButtons } from './editor-buttons';

export const PolicyEditor = ({ state, actions, id }) => {

  const getData = () => {
    actions.getPolicy(id);
  }

  const setValue = (prop, value) => {
    actions.setState({
      [prop]: value,
      modified: true
    });
  }

  const validateSaveButton = (el) => {
    validateButton(el, validate(state))
  }

  const save = (e) => {
    if (state.modified) {
      const err = validate(state);
      validateEditor(e.target.closest('.policy-editor'));
      if (err) {
        actions.setState({ error: err });
      } else {
        actions.savePolicy();
      }
    }
  }

  const onChange = (prop) => ({ target }) => {
    setValue(prop, target.value);
  }

  const onArrayChange = (prop) => (tags) => {
    setValue(prop, tags.split(','));
  }

  return (<div class="policy-editor" oncreate={getData} >
    {state.loaded ? (<div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Path</label>
        </div>
        <div class="field-body">
          <RequiredField placeholder="Path" value={state.path} onchange={onChange('path')} className="is-expanded" />
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Method(s)</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <TagsInput placeholder="Method(s)" value={untagify((state.methods || []).join(','))} onchange={onArrayChange('methods')} className="is-expanded" isUpperCase={true} required={true} datasource={['*', 'GET', 'POST', 'DELETE', 'PUT', 'PATCH', 'OPTIONS', 'HEAD']} />
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Role(s)</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <TagsInput placeholder="Role(s)" value={untagify((state.roles || []).join(','))} onchange={onArrayChange('roles')} className="is-expanded" datasource={state.rolesAvailable} />
            </p>
          </div>
        </div>
      </div>
      <EditorButtons clear={!state.protected && actions.getPolicy} cancelTo="policies" save={!state.protected && save} validateSave={validateSaveButton} />
    </div>) : (<Loading />)}
    {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
  </div>)
}
