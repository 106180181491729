import { client } from '../modules/client'
import { Country, validate } from "../modules/country";

export const country = {
  setState: (state) => (state),
  getCountry: (id) => async (state, actions) => {
    if (id) {
      await actions.getCurrencies();
      await actions.getLanguages();
      var res = await client.getCountry(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.item) {
        const err = validate(res.item);
        return actions.setState(Object.assign({ error: err, loaded: true }, res.item));
      }
      return actions.setState({
        error: "Country not found",
        loaded: true
      })
    }
    return actions.setState(Object.assign(new Country(), { loaded: true }))
  },
  saveCountry: (onDone) => async (state, actions) => {
    const err = validate(state);
    if (!!err) {
      return actions.setState({
        error: err
      })
    }
    const res = await client.saveCountry(state);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      })
    }
    if (onDone) {
      if (onDone.exit) {
        history.pushState(location.pathname, '', '/countries')
      } else if (onDone.empty) {
        history.pushState(location.pathname, '', '/countries/new')
        return actions.setState(new Country());
      } else {
        if (location.pathname == '/countries/new' && res.items.length) {
          actions.setState({ id: res.items[0].id });
          history.pushState(location.pathname, '', '/countries/' + res.items[0].id)
        }
        return actions.setState({ modified: false });
      }
    }
  },
  getCurrencies: () => async (state, actions) => {
    if (!state.availableCurrencies) {
      const resp = await client.getCurrencies();
      return actions.setState({ error: resp.error, availableCurrencies: (resp.items.map(c => c.code) || []) });
    }
  },
  getLanguages: () => async (state, actions) => {
    if (!state.availableLanguages) {
      const resp = await client.getLanguages();
      return actions.setState({ error: resp.error, availableLanguages: (resp.items.map(c => c.code) || []) });
    }
  }
}
