import { h } from 'hyperapp'
import logo from '../img/rocket.png'

import '../css/login.css'

export const Login = ({ state, actions }) => {
  function submitLogin(e) {
    e.preventDefault()
    const email = e.target['email'].value
    const password = e.target['password'].value
    actions.auth.login({ email, password })
    return false
  }

  const spin = (e) => {
    e.preventDefault()
    e.stopPropagation()
    actions.auth.authorize()
    actions.login.flip()
  }

  return (
    <section class="login-section hero is-fullheight" key="login_view">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title login-title">
            {state.title}
          </h1>
          <div class="column is-4 is-offset-4">
            <div class={"login-box" + (state.login.flipped ? ' flipped' : '')}>
              <div class="login-providers login-container">
                <div class="login-content">
                  <figure class="login-logo">
                    <img src={logo} />
                  </figure>
                  <div class="buttons">
                    <a class="button is-medium is-block is-fullwidth is-dark" href="/api/auth/github">
                      <span class="icon">
                        <i class="fab fa-github"></i>
                      </span>
                      <span>GitHub</span>
                    </a>
                    <a class="button is-medium is-block is-fullwidth is-danger" href="/api/auth/google">
                      <span class="icon">
                        <i class="fab fa-google"></i>
                      </span>
                      <span>Google</span>
                    </a>
                    <button class="button is-medium is-block is-fullwidth is-info" onclick={spin}>
                      <span class="icon">
                        <i class="far fa-envelope"></i>
                      </span>
                      <span>Email</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="login-password login-container">
                <div class="login-content">
                  <figure class="login-logo">
                    <img src={logo} />
                  </figure>
                  {state.auth.error && (<div class="notification is-danger"><i class="fas fa-ban"></i>&nbsp;{state.auth.error}</div>)}
                  <form name="loginForm" onsubmit={submitLogin}>
                    <div class="field">
                      <div class="control has-icons-left">
                        <input class="input is-medium is-rounded" name="email" placeholder="Email address" autofocus="true" />
                        <span class="icon is-small is-left">
                          <i class="fas fa-envelope"></i>
                        </span>
                      </div>
                    </div>
                    <div class="field">
                      <div class="control has-icons-left">
                        <input class="input is-medium is-rounded" name="password" type="password" placeholder="Password" />
                        <span class="icon is-small is-left">
                          <i class="fas fa-lock"></i>
                        </span>
                      </div>
                    </div>
                    <div class="buttons">
                      <button type="submit" class="button is-block is-info is-medium is-fullwidth">Login</button>
                      <button class="button is-block is-light is-medium is-fullwidth" onclick={spin}>Back</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <br />
            <p class="has-text-grey">
              &copy; <a href={state.orgUrl}>{state.org}</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
