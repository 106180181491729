import { h } from 'hyperapp'
import { Dropdown } from './common'

export const EventSelector = ({ value, events, onSelect, valueField = "id", displayField }) => {
  if (!(events && events.length)) return null;
  return (<div class="field">
    <div class="field-body">
      <div class="field is-expanded">
        <div class="field has-addons">
          <p class="control">
            <a class="button is-static event_on">
              ON
            </a>
          </p>
          <p class="control is-expanded">
            <Dropdown text="--- select an event ---" items={events} valueField={valueField} displayField={displayField} onchange={val => onSelect(val)} value={value} />
          </p>
        </div>
      </div>
    </div>
  </div>)
}

export const EventRuleIdSelector = ({ value, rules, onSelect }) => {
  return (<div class="field">
    <div class="field-body">
      <div class="field is-expanded">
        <p class="control is-expanded">
          <Dropdown text="--- select a rule ---" items={rules} valueField="id" displayField="title" orderField="title" onchange={val => onSelect(val)} value={value} />
        </p>
      </div>
    </div>
  </div>)
}
