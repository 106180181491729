import { cookies } from './cookies'

const TOKEN_NAME = 'mwa_token'

const get = () => {
  return cookies.read(TOKEN_NAME)
}

const save = (token) => {
  if (token) {
    cookies.write(TOKEN_NAME, token)
  }
}

const remove = () => {
  cookies.erase(TOKEN_NAME)
}

const exists = () => {
  return !!get()
}

export const token = {
  get,
  save,
  remove,
  exists
}
