import { h } from 'hyperapp';
import { comparers, Condition, connectors } from '../../modules/rule';
import { getLastCondition, slugify } from '../../modules/utils'
import { Connector, Dropdown, Buttons, Fields, TagsInput, RequiredField, NumericField } from './common';
import { ParamSelector } from './param-selector';

const comparerToString = (comparer, type) => {
  return comparers[type].find(c => c.value == comparer).label.replace(/\s+/g, '_');
}

const generateAlias = (condition) => {
  return slugify(condition.method + '_' + condition.field + '_' + comparerToString(condition.comparer, condition.type) + '_' + condition.value);
}

export const ConditionItem = ({ condition, isFirst, items, methods, type, datasourceValues, datasourceKey, onchange, onremove, className, events, paramValues, paramsData, fetchData }) => {

  const setConnector = (value) => {
    condition.connector = value;
    onchange(condition);
  }

  if (condition.items && condition.items.length) {
    return (<ConditionGroup condition={condition} isFirst={isFirst} items={items} methods={methods} type={type} onchange={onchange} onremove={onremove} className={className} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} setConnector={setConnector} datasourceValues={datasourceValues} datasourceKey={datasourceKey} />)
  }

  const setField = (value) => {
    condition.field = value;
    const f = getField(items);
    condition.type = f && (f.itemType || f.type) || type;
    onchange(condition);
  }

  const setMethod = (value) => {
    condition.method = value;
    onchange(condition);
  }

  const setComparer = (value) => {
    condition.comparer = value;
    const values = (condition.value || '').split('<>');
    condition.value = (values.length ? values[0] : '');
    onchange(condition);
  }

  const setValue = (value, isDynamic) => {
    const values = (condition.value || '').split('<>');
    condition.value = value + (values.length > 1 ? ('<>' + values[1]) : '');
    condition.isDynamic = !!isDynamic;
    if (condition.method && !condition.alias) {
      condition.alias = generateAlias(condition);
    }
    onchange(condition);
  }
  const setBetweenValue = (value) => {
    const values = (condition.value || '').split('<>');
    condition.value = (values.length ? values[0] : '') + '<>' + value;
    onchange(condition);
  }

  const getField = (items) => {
    if (!items) return null;
    return items.find(d => d.id == condition.field);
  }

  const setAlias = (alias) => {
    condition.alias = alias;
    onchange(condition);
  }

  const field = condition.field && getField(items);
  const paramType = field && (field.itemType || field.type) || type;
  const isValue = !items && !!type;
  const typeComparers = (isValue || condition.field) && comparers[(field && field.type || type)] || [];
  var conditionValue, conditionBetweenValue;
  if (condition.value != undefined) {
    const values = (condition.value || '').split('<>');
    conditionValue = (values.length ? values[0] : '');
    conditionBetweenValue = (values.length > 1 ? values[1] : '');
  }

  const novalue = condition.comparer && (condition.comparer == '!!' || condition.comparer == 'null');

  return (
    <li key={condition.id} class={`field condition_item ${className || ''}`} >
      {!isFirst && <Connector item={condition} setConnector={setConnector} />}
      <div class="field is-grouped is-grouped-multiline">
        {methods && (
          <div class="control">
            <Dropdown text=" --- select a method --- " items={methods} onchange={setMethod} value={condition.method} />
          </div>
        )}
        {!isValue && (
          <div class="control">
            <Dropdown text=" --- select a field --- " items={items} onchange={setField} value={condition.field} />
          </div>
        )}
        {(isValue || condition.field) && (
          <div class="control">
            <Dropdown text=" --- select a comparer --- " items={typeComparers} valueField="value" onchange={setComparer} value={condition.comparer} />
          </div>
        )}
        {(isValue || condition.field) && condition.comparer && !novalue && (
          <div class="control param_field">
            <ConditionParam conditionValue={conditionValue} field={field} type={paramType} isDynamic={condition.isDynamic} setValue={setValue} events={events} validators={field ? field.validators : null} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} datasourceValues={datasourceValues} datasourceKey={datasourceKey} />
          </div>
        )}
        {(isValue || condition.field) && condition.comparer == '<>' && (
          <div class="control param_field">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">AND</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <ConditionParam conditionValue={conditionBetweenValue} field={field} type={paramType} isDynamic={condition.isDynamic} setValue={setBetweenValue} events={events} validators={field ? field.validators : null} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} datasourceValues={datasourceValues} datasourceKey={datasourceKey} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {methods && condition.value && (
          <div class="control condition_alias param_field">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">as</label>
              </div>
              <div class="field-body">
                <RequiredField placeholder="Condition Alias" onchange={({ target: { value } }) => setAlias(value)} value={condition.alias} />
              </div>
            </div>
          </div>
        )}
        <div class="control">
          <button class="rm_btn delete" title="Remove condition" onclick={onremove}></button>
        </div>
      </div>
    </li>)
}

const ConditionParam = ({ conditionValue, field, type, isDynamic, setValue, events, validators, paramValues, paramsData, fetchData, datasourceValues, datasourceKey }) => {
  if (type == 'eventType') {
    return (<Dropdown text=" --- select an event --- " items={events} onchange={setValue} value={conditionValue} />)
  } else if (type == 'duration') {
    const matches = (conditionValue || '').match(/(\d+)?([mhDWMY])?/);
    var value, timespan;
    if (matches) {
      value = matches[1];
      timespan = matches[2];
    }
    var itemValues = field.values;
    if (value && Number(value) > 1) {
      itemValues = itemValues.map(v => {
        return Object.assign({}, v, { value: v.value + 's' });
      })
    }
    return (<div class="field is-grouped">
      <div class="control">
        <NumericField value={value} isDynamic={isDynamic} paramValues={paramValues} onchange={({ target: { value } }) => setValue(value + (timespan || ''))} placeholder="Enter duration" validators={validators} />
      </div>
      <div class="control">
        <div class="field has-addons">
          <div class="control">
            <Dropdown text=" --- select a timespan --- " items={itemValues} valueField="key" displayField="value" orderField="index" onchange={(t) => setValue((value || '') + t)} value={timespan} />
          </div>
          <div class="control">
            <a class="button is-static">ago</a>
          </div>
        </div>
      </div>
    </div>)
  } else if (field && field.type == 'array') {
    return (<TagsInput type={field.itemType} value={conditionValue} onchange={setValue} placeholder={`Enter ${field.itemType}`} validators={validators} />)
  }
  return ParamValues({ value: conditionValue, onchange: setValue, type, isDynamic, paramValues, validators, field, paramsData, fetchData, datasourceValues, datasourceKey })
}

const ConditionGroup = ({ condition, isFirst, items, type, onchange, onremove, className, paramValues, paramsData, fetchData, setConnector, datasourceValues, datasourceKey }) => {
  const onItemAdd = () => {
    const connector = condition.items.length ? connectors.AND : connectors.OR;
    condition.items = condition.items.concat(new Condition({ connector }))
    onchange(condition);
  }

  const onItemGroupAdd = () => {
    const connector = condition.items.length ? connectors.AND : connectors.OR;
    condition.items = condition.items.concat(new Condition({ connector, items: [new Condition({ connector })] }))
    onchange(condition);
  }

  const onItemChange = (c) => {
    condition.items = condition.items.map(i => c.id === i.id ? Object.assign({}, i, c) : i);
    onchange(condition);
  }

  const onItemRemove = (c) => {
    condition.items = condition.items.filter(i => c.id !== i.id);
    if (!condition.items.length)
      onremove();
    else {
      condition.items[0].connector == '||'
      onchange(condition);
    }
  }

  const lastItem = getLastCondition(condition.items);
  const canAddMore = (!lastItem || lastItem.value);

  return (<div>
    {condition.items && (<div class="field is-grouped">
      {!isFirst && <Connector item={condition} setConnector={setConnector} />}
      <div class="control">
        <ul class={`condition_item_list${isFirst ? ' first' : ''}`} >
          {condition.items.map((c, i) => (<ConditionItem condition={c} isFirst={i == 0} items={items} type={type} datasourceValues={datasourceValues} datasourceKey={datasourceKey} onchange={onItemChange} onremove={() => onItemRemove(c)} className={`item ${className || ''}`} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} />))}
        </ul>
      </div>
    </div>)}
    {canAddMore && (<Buttons addTitle="Add condition" onAddClick={onItemAdd} addIcon="fa-plus" groupTitle="Add condition group" onGroupClick={onItemGroupAdd} groupIcon="fa-layer-group" hasGroup={true} />)}
  </div>)
}

const ParamValues = ({ type, isDynamic, paramValues, paramsData, fetchData, value, onchange, field, datasourceValues, datasourceKey, placeholder, ...rest }) => {
  var valueField = "id", displayField = "label", orderField;
  let loading = false;
  let paramLabel = type;
  let typeValues = !!paramValues && Object.assign({}, paramValues[type]) || {};
  if (datasourceKey && paramValues[type] && paramValues[type][datasourceKey]) {
    if (field)
      typeValues[datasourceKey] = paramValues[type][datasourceKey].filter(v => (v.id != (datasourceKey + '.' + field.id)));
    else
      delete typeValues[datasourceKey];
  }
  if (field && field.data) {
    let { source, kind, key, query, display, order } = field.data;
    key = key || 'code';
    display = display || 'name';
    const path = `${source}/${kind}` + (!!query ? `/${query}` : '');
    const data = paramsData && paramsData[path];
    Object.entries(typeValues).forEach(([k, val]) => {
      typeValues[k] = val.map((v, i) => ({ [key]: v.id, [display]: v.label, [order || 'index']: i, isDynamic: v.isDynamic }));
    })
    if (!data) {
      fetchData(field.data);
    } else if (data.loading) {
      loading = true
    } else {
      typeValues[kind] = data.data;
    }
    paramLabel = field.data.kind
    valueField = key
    displayField = display
    orderField = order
  } else if (field && field.values && field.values.length) {
    Object.entries(typeValues).forEach(([k, val]) => {
      typeValues[k] = val.map((v, i) => ({ key: v.id, value: v.label, index: i, isDynamic: v.isDynamic }));
    })
    paramLabel = field.label.toLowerCase();
    typeValues[paramLabel] = field.values.map((v, i) => ({ index: i, ...v }));
    valueField = "key"
    displayField = "value"
    orderField = "index"
  } else if (datasourceValues && datasourceValues.length) {
    Object.entries(typeValues).forEach(([k, val]) => {
      typeValues[k] = val.map((v, i) => ({ key: v.id, value: v.label, index: i, isDynamic: v.isDynamic }));
    })
    paramLabel = datasourceKey;
    typeValues[datasourceKey] = datasourceValues.map((v, i) => ({ index: i, ...v }));
    valueField = "key"
    displayField = "value"
    orderField = "index"
  }
  if (['a', 'e', 'i', 'o', 'u'].indexOf(paramLabel.charAt(0)) > -1) {
    paramLabel = `an ${paramLabel}`
  } else {
    paramLabel = `a ${paramLabel}`
  }
  const TypeField = (type != "bool") && Fields[type];
  if (!placeholder) {
    placeholder = 'Enter a'
    if (['a', 'e', 'i', 'o', 'u'].indexOf(type.charAt(0)) > -1) {
      placeholder += `n ${type}`
    } else {
      placeholder = ` ${type}`
    }
  }
  return ParamSelector({ TypeField, typeValues, isDynamic, value, onchange, paramLabel, valueField, displayField, orderField, loading, placeholder, ...rest })
}
