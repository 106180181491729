import { client } from '../modules/client'
import { token } from '../modules/token'

function download(filename, text) {
  var pom = document.createElement('a');
  pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  pom.setAttribute('download', filename);

  if (document.createEvent) {
    var event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  }
  else {
    pom.click();
  }
}
function getErrorDesc(msg) {
  const idx = msg.indexOf('desc =');
  if (idx != -1) {
    return msg.substring(idx + 7, msg.length - 1);
  }
  return msg;
}

async function downloadFile(url, data, filename, onProgress, onDone, onError) {
  const start = new Date().getTime();
  return new Promise(resolve => {
    var blob;
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('Content-Type', 'application/json');
    if (token.exists()) {
      xhr.setRequestHeader('Authorization', `Bearer ${token.get()}`);
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        blob = new Blob([this.response]);
        var pom = document.createElement('a');
        var href = window.URL.createObjectURL(blob);
        pom.setAttribute('href', href);
        pom.setAttribute('download', filename);
        pom.click();
        window.URL.revokeObjectURL(href);
        onDone({ elapsed: Math.round(((new Date().getTime()) - start) / 100) / 10 });
        resolve();
      }
    }
    xhr.upload.addEventListener("progress", function (e) {
      if (e.lengthComputable) {
        onProgress((e.loaded / e.total).toFixed(2));
      }
    }, false);
    xhr.onerror = (e) => {
      onError(e.message);
      resolve();
    }
    xhr.send(JSON.stringify(data));
  });
}

// async function uploadFile(url, file, onProgress, onSuccess, onError) {
//   if (!!file) {
//     const chunk_size = 1024 * 1000;
//     const chunks = Math.ceil(file.size / chunk_size);
//     const reader = new FileReader()
//     let done = 0;
//     const start = new Date().getTime();

//     const onChunkProgress = (chp) => {
//       const progress = (done / file.size) + (chp / chunks);
//       onProgress(Math.min(progress, 1).toFixed(2));
//     }
//     const onChunkError = (e) => {
//       onError(e);
//     }
//     const onChunkSuccess = (res) => {
//       console.log("chunk done in", (new Date().getTime()) - start, "ms");
//       done += (chunk_size + 1);
//       if (done < file.size) {
//         reader.readAsDataURL(file.slice(done, done + chunk_size + 1));
//       } else {
//         console.log("upload complete in", (new Date().getTime()) - start, "ms");
//         onSuccess({ count: res.count });
//       }
//     }

//     reader.onload = () => {
//       uploadChunk(url, reader.result, onChunkProgress, onChunkSuccess, onChunkError);
//     }

//     reader.readAsDataURL(file.slice(done, done + chunk_size + 1));
//   }
// }

async function uploadFile(url, file, onProgress, onSuccess, onError) {
  if (!!file) {
    const start = new Date().getTime();
    var data = new FormData();
    data.append("file", file);

    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      // xhr.setRequestHeader('Content-Length', file.size);
      if (token.exists()) {
        xhr.setRequestHeader('Authorization', `Bearer ${token.get()}`);
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          try {
            const resp = JSON.parse(xhr.response);
            const err = resp && (resp.error || (xhr.status == 500 && getErrorDesc(resp.message)));
            if (err) {
              onError(err);
            } else {
              onSuccess({ count: resp.count, elapsed: Math.round(((new Date().getTime()) - start) / 100) / 10 });
            }
          } catch (e) {
            onError(e.message);
          }
          resolve();
        }
      }
      xhr.upload.addEventListener("progress", function (e) {
        if (e.lengthComputable) {
          onProgress((e.loaded / e.total).toFixed(2));
        }
      }, false);
      xhr.onerror = (e) => {
        onError(e.message);
        resolve();
      }
      xhr.send(data);
    })
  }
}

export const gamesUpload = {
  loading: () => () => ({ loading: true }),
  reset: () => () => ({ file: undefined, progress: undefined, slugs: '', loading: false }),
  setFile: file => () => ({ file, count: 0, elapsed: 0, uploadFailed: false }),
  setSlugs: slugs => () => ({ slugs }),
  incProgress: value => () => ({ loading: true, progress: value }),
  uploadSuccess: res => () => ({ count: res.count, elapsed: res.elapsed }),
  uploadFailed: e => () => ({ uploadFailed: e }),
  upload: () => async (state, actions) => {
    actions.incProgress(0);
    await uploadFile('/api/games/_upload', state.file, actions.incProgress, actions.uploadSuccess, actions.uploadFailed);
    actions.reset();
  },
  downloadSuccess: res => () => ({ elapsed: res.elapsed, done: true }),
  downloadFailed: e => () => ({ downloadFailed: e }),
  export: data => async (state, actions) => {
    actions.incProgress(0);
    await downloadFile('/api/games/_export', data, 'games.csv', actions.incProgress, actions.downloadSuccess, actions.downloadFailed);
    actions.reset();
  },
  brandLoading: () => () => ({ brandLoading: true }),
  resetBrand: () => () => ({ brandFile: undefined, brandProgress: undefined, brandSlugs: '', brandLoading: false }),
  setBrandFile: brandFile => () => ({ brandFile, brandCount: 0, brandElapsed: 0, uploadBrandFailed: false }),
  setBrandSlugs: brandSlugs => () => ({ brandSlugs }),
  incBrandProgress: value => () => ({ brandLoading: true, brandProgress: value }),
  uploadBrandSuccess: res => () => ({ brandCount: res.count, brandElapsed: res.elapsed }),
  uploadBrandFailed: e => () => ({ uploadBrandFailed: e }),
  uploadBrand: () => async (state, actions) => {
    actions.incBrandProgress(0);
    await uploadFile('/api/games/_uploadbrand', state.brandFile, actions.incBrandProgress, actions.uploadBrandSuccess, actions.uploadBrandFailed);
    actions.resetBrand();
  },
  downloadBrandSuccess: res => () => ({ brandElapsed: res.elapsed, brandDone: true }),
  downloadBrandFailed: e => () => ({ downloadBrandFailed: e }),
  exportBrand: data => async (state, actions) => {
    actions.incBrandProgress(0);
    await downloadFile('/api/games/_exportbrand', data, 'games-brand.csv', actions.incBrandProgress, actions.downloadBrandSuccess, actions.downloadBrandFailed)
    actions.resetBrand();
  },
  resetDelete: () => () => ({ deleteFile: undefined, deleteProgress: undefined, deleteLoading: false }),
  setDeleteFile: deleteFile => () => ({ deleteFile, deleteCount: 0, deleteElapsed: 0, uploadDeleteFailed: false }),
  incDeleteProgress: value => () => ({ deleteLoading: true, deleteProgress: value }),
  uploadDeleteSuccess: res => () => ({ deleteCount: res.count, deleteElapsed: res.elapsed }),
  uploadDeleteFailed: e => () => ({ uploadDeleteFailed: e }),
  uploadDelete: data => async (state, actions) => {
    actions.incDeleteProgress(0);
    await uploadFile('/api/games/_uploaddelete', state.deleteFile, actions.incDeleteProgress, actions.uploadDeleteSuccess, actions.uploadDeleteFailed);
    actions.resetDelete();
  },
  setState: (state) => (state),
  getGames: (params) => async (state, actions) => {
    var { filter, order, skip, take, includeArchived, defaultOrder } = state;
    const req = {};

    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.includeArchived) !== 'undefined') {
      if (params.includeArchived)
        req.includeArchived = true;
    } else if (includeArchived) {
      req.includeArchived = true;
    }

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      for (var k in filter) {
        if (filter[k] !== "" && filter[k] !== undefined) {
          req[k] = filter[k];
        }
      }
    }

    const res = await client.getGames(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.loaded = true;
    res.includeArchived = req.includeArchived;
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    return actions.setState(res);
  },
  deleteGame: (slug) => async (state, actions) => {
    const res = await client.deleteGame(slug);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const items = state.items.filter(i => id === i.id)
    return actions.setState({ items });
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getGames({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getGames({ order });
  },
  setFilter: (filter) => async (state, actions) => {
    return actions.getGames({ filter });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  save: (g) => async (state, actions) => {
    const res = await client.saveGame(g);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const items = state.items.map(i => i.slug === g.slug ? Object.assign({}, i, g) : i);
    return actions.setState({ items });
  }
}
