import { h } from 'hyperapp'
import { QueryEvents } from './components/query-events'

export const Query = ({ state, actions }) => {
  return (
    <section class="section" key="query_view">
      <h1 class="title">
        Query events
      </h1>
      <hr />
      <QueryEvents state={state.query} actions={actions.query} />
    </section>
  )
}
