import { h } from 'hyperapp'
import { Keys } from '../modules/constants'
import { Divider } from '../views/components/common'
import { StringField, FieldValidators } from '../views/components/input-fields'

export const Roles = ({ state, actions }) => {

  const onKeydown = (e) => {
    const key = e.keyCode || e.which;
    const el = e.target;
    if (key === Keys.ENTER || key === Keys.COMMA || key === Keys.TAB) {
      if (!el.value && key !== Keys.COMMA) return;
      addRole(e.target.value, e.target);
    }
  }

  const onChange = ({ target }) => {
    addRole(target.value, target);
  }

  const hasRole = (role) => {
    return state.roles.items.find(r => (r.role == role));
  }

  const addRole = (text, target) => {
    target.value = "";
    const container = target.closest('.roles-editor');
    // Add multiple tags if the user pastes in data with SEPERATOR already in it
    if (~text.indexOf(',')) text = text.replace(/\s*,\s*/g, ',').split(',');
    if (Array.isArray(text)) {
      let res = true;
      text.forEach(t => (res = (res && addRole(t, target))));
      return res
    }

    let role = text && text.trim();
    // Ignore if text is empty
    if (!role) return false;

    // For duplicates, briefly highlight the existing tag
    const duplicate = hasRole(role);
    if (!!duplicate) {
      if (container) {
        const el = container.querySelector(`[data-role="${role}"]`);
        if (el) {
          el.classList.add('is-warning');
          setTimeout(() => el.classList.remove('is-warning'), 100);
        }
      }
      target.value = text;
      return false;
    }
    actions.roles.saveRole(role);
  }

  const deleteRole = (r) => {
    actions.roles.deleteRole(r);
  }

  return (
    <section class="section" key="roles_view">
      <h1 class="title">
        Roles
        </h1>
      <hr />
      <div class="roles-editor" oncreate={actions.roles.getRoles}>
        <div class="box">
          <div class="tags">
            {(state.roles.items || []).map(r => {
              return (<span key={r.id} class="tag is-info" data-role={r.role}>
                {r.role}
                <button class="delete is-small" onclick={() => deleteRole(r)}></button>
              </span>)
            })}
          </div>
          <StringField placeholder="New role" onchange={onChange} onkeydown={onKeydown} className="is-expanded" validators={[FieldValidators.nowhitespace]} />
        </div>
      </div>
      {state.roles.error ? (<div class="notification is-danger">{state.roles.error}</div>) : null}
    </section>
  )
}
