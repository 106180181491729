import { h } from 'hyperapp';
import { FieldValidators } from './input-fields'
import { Keys } from '../../modules/constants'

import '../../css/tags-input.css'

export default ({ type = 'string', placeholder = '', value, separator = ',', className, isUpperCase, isLowerCase, required, validators = [], onchange, disabled = false, datasource }) => {

  const attrs = {};
  validators = (validators || []);
  if (FieldValidators[type]) {
    validators.unshift(FieldValidators[type]);
  }
  if (required) {
    validators.unshift(FieldValidators.required);
    attrs.required = 'required';
  }

  if (disabled) {
    attrs.disabled = 'disabled'
  }

  const SEPARATOR = new RegExp(`\\s*${separator}\\s*`, 'g');
  const tags = (value || '').replace(SEPARATOR, separator).split(separator).filter(t => (t != ""));

  const hasTag = (text) => {
    return tags.find(t => (t == text));
  }

  // Return false if no need to add a tag
  const addTag = (text, tagsElement) => {
    // Add multiple tags if the user pastes in data with SEPERATOR already in it
    if (~text.indexOf(separator)) text = text.replace(SEPARATOR, separator).split(separator);
    if (Array.isArray(text)) {
      let res = true;
      text.forEach(t => (res = (res && addTag(t, tagsElement))));
      return res
    }

    let tag = text && text.trim();
    // Ignore if text is empty
    if (!tag) return false;

    if (isUpperCase) {
      tag = tag.toUpperCase();
    }
    if (isLowerCase) {
      tag = tag.toLowerCase();
    }

    // For duplicates, briefly highlight the existing tag
    const exisingTag = hasTag(tag);
    if (!!exisingTag && tagsElement) {
      const tagElement = tagsElement.querySelector(`[data-tag="${tag}"]`);
      if (tagElement) {
        tagElement.classList.add('is-dark');
        setTimeout(() => tagElement.classList.remove('is-dark'), 100);
      }
      return false;
    }
    tags.push(tag);
    return true;
  }

  const onBeforeChange = (el) => {
    let currentValue = el.value;
    if (!currentValue) {
      currentValue = el.getAttribute('data-tags');
    }
    const target = el.parentElement;
    const icon = target.previousElementSibling.firstChild;
    const p = target.nextSibling;
    const group = target.closest('.field.is-grouped');
    let i = 0;
    let error = "";
    if (datasource && el.value) {
      let j = 0;
      let value = el.value;
      if (isUpperCase) {
        value = value.toUpperCase();
      }
      if (isLowerCase) {
        value = value.toLowerCase();
      }
      const values = value.split(SEPARATOR)
      while (!error && j < values.length) {
        if (datasource.indexOf(values[j]) == -1) {
          error = `"${values[j]}" is not valid value`
        }
        j++;
      }
    }
    while (!error && i < validators.length) {
      const validator = validators[i];
      if (typeof validator == 'function') {
        error = validator(currentValue)
      } else if (validator.pattern && !validator.pattern.test(currentValue)) {
        error = validator.message
      }
      i++;
    }
    if (!!error) {
      icon.classList.remove('is-invisible');
      target.classList.add('is-danger');
      p.classList.remove('is-invisible');
      if (group)
        group.classList.add('has-error');
      p.innerText = error;
    } else {
      icon.classList.add('is-invisible');
      target.classList.remove('is-danger');
      p.classList.add('is-invisible');
      if (group)
        group.classList.remove('has-error');
      p.innerText = '';
    }
    return !!error
  }

  const oncreate = (el) => {
    el.addEventListener('validate', validate, false);
    const field = el.closest('.param_field');
    if (field) {
      field.classList.add('has-field');
    }
  }

  const ondestroy = (el) => {
    el.removeEventListener('validate', validate);
  }

  const onupdate = (el) => {
    el.setAttribute('data-tags', value)
  }

  const validate = (e) => {
    onBeforeChange(e.target);
  }

  const removeTag = (e, tag) => {
    e.preventDefault();
    onBeforeChange(e.currentTarget.parentElement.parentElement.nextElementSibling);
    onchange(tags.filter(t => (t != tag)).join(separator));
    return false;
  }

  const onKeydown = (e) => {
    let el = e.target,
      key = e.keyCode || e.which,
      atStart = (el.selectionStart === 0 && el.selectionEnd === 0);

    if (key === Keys.ENTER || key === Keys.COMMA || key === Keys.TAB) {
      if (!el.value && key !== Keys.COMMA) return;
      e.preventDefault();
      onBeforeChange(el);
      if (addTag(el.value, el.parentElement)) {
        el.value = "";
        onchange(tags.join(','));
      }
      return false;
    }
    else if (key === Keys.BACKSPACE && atStart && tags.length) {
      e.preventDefault();
      onBeforeChange(el);
      onchange(tags.slice(0, tags.length - 1).join(separator));
      return false;
    }
    if (key >= 65 && key <= 90) {
      if (isUpperCase && !e.shiftKey) {
        el.value += String.fromCharCode(e.keyCode).toUpperCase();
        e.preventDefault();
        return false
      }
      if (isLowerCase && e.shiftKey) {
        el.value += String.fromCharCode(e.keyCode).toLowerCase();
        e.preventDefault();
        return false
      }
    }
  }

  const onPaste = (e) => {
    const el = e.target;
    setTimeout(() => {
      onBeforeChange(el);
      if (addTag(el.value, el.parentElement)) {
        el.value = "";
        onchange(tags.join(separator));
      }
    }, 0)
  }

  const onBlur = (e) => {
    const el = e.currentTarget;
    el.parentElement.classList.remove("is-focused");
    onBeforeChange(el);
    if (el.value) {
      if (addTag(el.value, el.parentElement)) {
        el.value = "";
        onchange(tags.join(separator));
      }
    }
  }

  const onClick = (e) => {
    e.currentTarget.children[1].focus();
    e.currentTarget.classList.add("is-focused");
  }

  const onInputFocus = (e) => {
    e.currentTarget.parentElement.classList.add("is-focused");
  }

  return (<div class="control has-icons-right">
    <span class="icon is-small is-right">
      <i class="fas fa-exclamation-triangle is-invisible"></i>
    </span>
    <div class={"input tags-input" + (className ? ` ${className}` : '') + (disabled ? ' disabled' : '')} onclick={onClick} {...attrs}>
      <div class="tags">{tags.map(t => {
        return (<span key={t} class="tag has-addons" data-tag={t}>
          <span>{t}</span>
          {!disabled && (<a class="delete is-small" onclick={(e) => removeTag(e, t)}></a>)}
        </span>)
      })}</div>
      <input class="" type="text" placeholder={!disabled || !value ? placeholder : ''} oncreate={oncreate} onupdate={onupdate} ondestroy={ondestroy} onkeydown={onKeydown} onpaste={onPaste} onfocus={onInputFocus} onblur={onBlur} {...attrs} />
    </div>
    <p class="help is-danger is-invisible"></p>
  </div>)
}
