import { client } from '../modules/client'

export const gameUploadHistory = {
  setState: (state) => (state),
  getUploadHistory: (params) => async (state, actions) => {
    var { skip, take, activeTab } = state;
    const req = {};

    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }

    const res = await client.getUploadHistory(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.modal = null;
    res.loaded = true;
    res.activeTab = activeTab;
    return actions.setState(res);
  },
  restoreUploadHistory: ({ id }) => async (state, actions) => {
    const res = await client.restoreUploadHistory({ id });
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getUploadHistory();
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getUploadHistory({ skip });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  }
}
