import { app } from 'hyperapp'
import { location } from '@hyperapp/router'
import { state } from './state'
import { actions } from './actions'
import { view } from './views'

import './css/main.scss'

const main = app(state, actions, view, document.body)
const unsubscribe = location.subscribe(main.location)

main.init()

