import { Items } from './classes'
import { Entry } from './classes'

export class Siteconfigs extends Items {
    constructor() {
        super('name');
        this.modal = null;
        this.protectedField = 'isDefault';
    }
}

export class Siteconfig extends Entry {
    constructor(props) {
        super(props)
        const { id, name, country, type, enabled, config, clone } = props || {};
        this.id = id || '';
        this.name = !!id && !clone && name || '';
        this.country = country || '';
        this.type = type || '';
        this.config = config || '';
        this.enabled = !!enabled;
        this.loaded = !!id;
        this.modified = false;
        this.clone = null;
        this.error = null;
        this.isDefault = !!id && !clone && (this.country == '');
    }
}

export const validate = (p) => {
    return (!p.isDefault && !p.country) && 'Country is mandatory' ||
        !p.name && 'Name is mandatory' ||
        !p.type && 'Type is mandatory' ||
        !p.config && 'Config is mandatory';
}