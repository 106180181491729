import { h } from 'hyperapp'
import { tryParse, getFilterPath, propertyToString, capitalize } from '../modules/utils'
import { Tr, TrTagsCell, TrButton, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Capsules = ({ state, actions }) => {
  const { filter, order, methods } = state.capsules;
  const { isAdmin } = state.auth

  const getData = () => {
    actions.capsules.getCapsules();
  }

  const duplicateCapsule = ({ id, enabled, ...rest }) => {
    actions.capsule.setState({ clone: rest });
    actions.location.go('/capsules/new');
  }

  const deleteClick = ({ id, component }) => {
    const { error } = actions.capsules.deleteCapsule(id);
    if (!error) {
      actions.snackbar.open(<span>{`${component} deleted successfully.`}&nbsp;<button class="button is-text is-small is-light" onclick={() => actions.capsules.restoreCapsule({ id })}>Undo.</button></span>)
    }
  }

  const importCapsules = () => {
    const modal = {
      title: `Import capsules`,
      confirmText: "Import",
      content: (<textarea id="import_capsule" rows="25" class="textarea" placeholder="Import capsules" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_capsule').value;
        const res = tryParse(text);
        if (res)
          actions.capsules.importCapsules(res);
      },
      open: true,
      toggle: actions.capsules.toggleModal
    }
    actions.capsules.toggleModal(modal);
  }

  const exportCapsules = (r) => {
    actions.capsules.exportCapsules(r).then(capsules => {
      const modal = {
        title: `Exported capsules`,
        content: (<textarea id="export_capsule" rows="25" class="textarea" value={JSON.stringify(capsules, null, 2)} readonly autofocus></textarea>),
        open: true,
        toggle: actions.capsules.toggleModal
      }
      actions.capsules.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_capsule').focus();
      })
    });
  }

  const exportCapsulesClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    exportCapsules()
    return false
  }

  const toggleTag = (tags) => {
    actions.location.go(getFilterPath('tags', tags))
    actions.capsules.filterCapsules({ tags })
  }

  const toggleCapsule = (r) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    actions.capsules.toggleCapsule(r)
    return false
  }

  const toggleIncludeArchived = () => {
    actions.capsules.toggleIncludeArchived();
  }

  return (<div>
    <ListView name="capsule" columns={isAdmin && state.capsules.includeArchived ? "7" : "6"} getItems={getData} onDelete={deleteClick} goToPage={actions.capsules.goToPage} {...state.capsules}
      renderHead={() => (<Tr>
        <Th field="component" filter={filter} order={order} filterAction={actions.capsules.filterCapsules} setOrderAction={actions.capsules.setOrder} route={actions.location} />
        <Th field="tags" filter={filter} filterAction={actions.capsules.filterCapsules} setOrderAction={actions.capsules.setOrder} route={actions.location} />
        <Th field="defaultProps" filter={filter} items={methods} actions={actions} route={actions.location} format={propertyToString} />
        <Th field="enabled" items={['true', 'false']} filter={filter} filterAction={actions.capsules.filterCapsules} setOrderAction={actions.capsules.setOrder} route={actions.location} format={capitalize} />
        <th class="th-btn"></th>
        <th class="th-btn"></th>
        {isAdmin && state.capsules.includeArchived && (<th class="th-btn"></th>)}
      </Tr>)}
      renderBody={item => (<Tr>
        <td class="title-cell">
          <span>{item.component}</span>
          {item.desc ? (<span>
            <span class="icon is-small"><i class="fas fa-info-circle"></i></span>
            <div class="desc">
              <article class="message">
                <div class="message-body">
                  {item.desc.split('\n').map(l => {
                    return (<span>{l}<br /></span>)
                  })}
                </div>
              </article>
            </div></span>) : null}
        </td>
        <td>
          <TrTagsCell tags={item.tags && item.tags.split(',') || []} filter={filter && filter.tags} onchange={toggleTag} />
        </td>
        <td>{(item.defaultProps|| []).map(p => {
          return (<span>{p.property}:{p.value}<br /></span>)
        })}
        </td>
        <td>
          {item.error ? (<span class="icon has-text" style={{cursor:'help'}} title={item.error}><i class="fas fa-exclamation-triangle"></i></span>) : (
            <span class="icon is-small" onclick={toggleCapsule(item)}>
              {item.enabled ?
                <i class="far fa-check-square"></i> :
                <i class="far fa-square"></i>}
            </span>
          )}
        </td>
        <td>
          <TrButton name="capsule" item={item} title="Export" onclick={exportCapsules} icon="fas fa-file-export" />
        </td>
        <td>
          <TrButton name="capsule" item={item} title="Duplicate" onclick={duplicateCapsule} icon="fas fa-copy" />
        </td>
        {isAdmin && state.capsules.includeArchived && (<td>
          {item.archive && (<TrButton name="capsule" item={item} title="Restore" onclick={actions.capsules.restoreCapsule} icon="fas fa-trash-restore" />)}
        </td>)}
      </Tr>)
      }
      renderButtons={() => (<Tr>
        {isAdmin && (<label class={'button ' + (state.capsules.includeArchived ? ' is-dark' : '')}>
          {state.capsules.includeArchived ? (<input type="checkbox" onchange={toggleIncludeArchived} checked="checked" style={{display:'none'}} />) :
            (<input type="checkbox" onchange={toggleIncludeArchived} style={{display:'none'}} />)}
          {(state.capsules.includeArchived ? 'Hide' : 'Show') + ' archived'}
        </label>)}
        <button class="button is-primary" onclick={importCapsules}>Import</button>
        {!!state.capsules.items.length && (<button class="button is-info" onclick={exportCapsulesClick}>Export</button>)}
      </Tr>)} />
    <Modal {...state.capsules.modal} />
  </div>
  )
}
