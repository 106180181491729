import { h } from 'hyperapp'
import { TriggerAction } from './components/trigger-action'

export const Trigger = ({ state, actions }) => {
  return (
    <section class="section" key="trigger_view">
    <div class="">
      <h1 class="title">
        Trigger actions
      </h1>
      <hr />
      <TriggerAction state={state.trigger} actions={actions.trigger} />
    </div>
  </section>
  )
}
