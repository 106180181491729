import { location } from '@hyperapp/router'

import { debug } from '../modules/debug'
import { Auth } from '../modules/auth'
import { Capsules } from '../modules/capsules'
import { Countries, Country } from '../modules/country'
import { Currencies, Currency } from '../modules/currency'
import { Game, Games, GameUpload } from '../modules/game'
import { Languages, Language } from '../modules/language'
import { Rules } from '../modules/rules'
import { Trigger } from '../modules/trigger'
import { Tasks } from '../modules/tasks'
import { Users } from '../modules/users'
import { Policies } from '../modules/policies'
import { Siteconfigs, Siteconfig } from '../modules/siteconfig'
import { getUserAgent } from '../modules/utils'

const ua = getUserAgent();

export const state = {
  ua,
  sidepanel: !ua.mobile,
  error: '',
  location: location.state,
  debug: debug.state,
  snackbar: {
    open: false,
    content: ''
  },
  notification: {
    open: false,
    content: '',
    autoClose: false
  },
  auth: new Auth(),
  events: {},
  crmEvents: {},
  rule: {},
  rules: new Rules(),
  formula: {},
  formulas: new Rules(),
  capsule: {},
  capsules: new Capsules(),
  emulate: {
    file: undefined,
    count: 0,
    progress: 0,
    uploadSuccess: false,
    uploadFailed: false,
    dryRun: true
  },
  games: new Games(),
  game: new Game(),
  gameUpload: new GameUpload(),
  gameUploadHistory: {},
  trigger: new Trigger(),
  tasks: new Tasks(),
  query: {},
  user: {},
  users: new Users(),
  roles: {},
  policies: new Policies(),
  policy: {},
  login: {},
  configs: {},
  freespins: {},
  countries: new Countries(),
  country: new Country(),
  currencies: new Currencies(),
  currency: new Currency(),
  languages: new Languages(),
  language: new Language(),
  siteconfigs: new Siteconfigs(),
  siteconfig: new Siteconfig(),
}
