import { h } from 'hyperapp'

export const Home = ({ state }) => (
  <section class="section" key="home_view">
    <h1 class="title">
      {state.name}
    </h1>
    <p class="subtitle">
      Moonwalker <strong>Admin</strong>
    </p>
  </section>
)
