import { h } from 'hyperapp'
import { tryParse, capitalize } from '../modules/utils'
import { Tr, TrButton, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Siteconfigs = ({ state, actions }) => {
  const { filter, order } = state.siteconfigs;

  const deleteClick = ({ id, path }) => {
    const modal = {
      data: id,
      label: path,
      onConfirm: (id) => actions.siteconfigs.deleteSiteconfig(id),
      open: true,
      toggle: actions.siteconfigs.toggleModal
    }
    actions.siteconfigs.toggleModal(modal);
  }

  const toggleEnabled = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!item.protected) {
      c.enabled = !c.enabled
      actions.siteconfigs.save(c)
    }
    return false
  }

  const importSiteconfigs = () => {
    const modal = {
      title: `Import siteconfigs`,
      confirmText: "Import",
      content: (<textarea id="import_siteconfigs" rows="25" class="textarea" placeholder="Import siteconfigs" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_siteconfigs').value;
        const res = tryParse(text);
        if (res)
          actions.siteconfigs.importSiteconfigs(res);
      },
      open: true,
      toggle: actions.siteconfigs.toggleModal
    }
    actions.siteconfigs.toggleModal(modal);
  }

  const exportSiteconfig = (c) => {
    actions.siteconfigs.exportSiteconfigs(c).then(siteconfigs => {
      let exportDisabled;
      function siteconfigsToExport() {
        return siteconfigs.filter(r => r.enabled === true || exportDisabled)
      }
      const modal = {
        title: `Export siteconfigs`,
        content: (
          <div>
            <div style={{'padding-bottom':'5px'}}>
              <label class="checkbox">
                <input type="checkbox" id="cbExportDisabled" onclick={(e) => { exportDisabled = e.target.checked }} />
                <span>&nbsp;Export disabled siteconfigs</span>
              </label>
            </div>
            <textarea id="export_siteconfig" rows="25" class="textarea" value={JSON.stringify(siteconfigsToExport(), null, 2)} readonly autofocus></textarea>
          </div>
        ),
        open: true,
        toggle: actions.siteconfigs.toggleModal,
        buttons: [{
          text: "Copy to clipboard",
          class: "is-primary",
          onclick: () => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(JSON.stringify(siteconfigsToExport()))
            }
          }
        }, {
          text: "Save to file",
          class: "is-info",
          onclick: () => {
            const json = JSON.stringify(siteconfigsToExport());
            const blob = new Blob([json], { type: 'application/json' });
            const link = document.createElement('a');
            link.setAttribute('download', 'export.json');
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);

            // wait for the link to be added to the document
            window.requestAnimationFrame(function () {
              var event = new MouseEvent('click');
              link.dispatchEvent(event, true, true);
              document.body.removeChild(link);
              URL.revokeObjectURL(blob);
            });
          }
        }]
      }
      actions.siteconfigs.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_siteconfig').focus();
      })
    });
    return false
  }

  const exportSiteconfigs = (e) => {
    e.preventDefault()
    e.stopPropagation()
    exportSiteconfig()
    return false
  }

  const duplicateSiteconfig = ({ id, active, ...rest }) => {
    actions.siteconfig.setState({ clone: rest });
    actions.location.go('/siteconfigs/new');
  }

  return (<div>
    <ListView name="siteconfig" columns="6" getItems={actions.siteconfigs.getSiteconfigs} onDelete={deleteClick} goToPage={actions.stateconfigs.goToPage} {...state.siteconfigs}
      renderHead={() => (<Tr>
        <Th field="name" filter={filter} order={order} filterAction={actions.siteconfigs.setFilter} setOrderAction={actions.siteconfigs.setOrder} route={actions.location} />
        <Th field="country" filter={filter} order={order} filterAction={actions.siteconfigs.setFilter} setOrderAction={actions.siteconfigs.setOrder} route={actions.location} />
        <Th field="type" filter={filter} order={order} filterAction={actions.siteconfigs.setFilter} setOrderAction={actions.siteconfigs.setOrder} route={actions.location} />
        <Th field="enabled" items={['true', 'false']} filter={filter} filterAction={actions.siteconfigs.setFilter} setOrderAction={actions.siteconfigs.setOrder} route={actions.location} format={capitalize} />
        <th class="th-btn"></th>
        <th class="th-btn"></th>
      </Tr>)}
      renderBody={item => (<Tr>
        <td>{item.name}</td>
        <td>{item.country}</td>
        <td>{item.type}</td>
        <td>
          <span class="icon is-small" onclick={toggleEnabled(item)}>
            {item.enabled ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
        <td>
          <TrButton name="siteconfig" item={item} title="Export" onclick={() => exportSiteconfig(item)} icon="fas fa-file-export" />
        </td>
        <td>
          <TrButton name="siteconfig" item={item} title="Duplicate" onclick={() => duplicateSiteconfig(item)} icon="fas fa-copy" />
        </td>
      </Tr>)
      }
      renderButtons={() => (<Tr>
        <button class="button is-primary" onclick={importSiteconfigs}>Import</button>
        {!!state.siteconfigs.items.length && (<button class="button is-info" onclick={exportSiteconfigs}>Export</button>)}
      </Tr>)} />
    <Modal {...state.siteconfigs.modal} />
  </div>
  )
}
