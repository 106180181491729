import { emailRegex } from './constants'
import { Entry } from './classes'

export const validate = (user) => {
  return !user.email && 'Email is mandatory' ||
    !emailRegex.test(user.email) && 'Please provide a valid email' ||
    '';
}

export class User extends Entry {
  constructor(props) {
    super();
    const { id, displayName, email, avatarUrl, slackName, roles, providers, lastLogin, created, createdBy, updated, updatedBy, protected: userIsProtected } = props || {};
    this.id = id || '';
    this.displayName = displayName || '';
    this.email = email || '';
    this.avatarUrl = avatarUrl || '';
    this.slackName = slackName || '';
    this.roles = roles || '';
    this.providers = providers || '';
    this.lastLogin = lastLogin || '';
    this.created = created || '';
    this.createdBy = createdBy || '';
    this.updated = updated || '';
    this.updatedBy = updatedBy || '';
    this.protected = !!userIsProtected;
    if (id && !userIsProtected) {
      this.error = validate(this);
    }
    this.modified = false;
  }
}
