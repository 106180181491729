import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'
import { SidePanel } from './sidepanel'

export const Header = () => (state, actions) => {
  const { authed, user, rights } = state.auth;
  const avatarUrl = (authed && user && user.avatarUrl) ? user.avatarUrl : ''
  const oncreate = () => {
    document.documentElement.classList.add("has-navbar-fixed-top")
  }
  const ondestroy = () => {
    document.documentElement.classList.remove("has-navbar-fixed-top")
  }
  return (<header class="container">
    <div class="navbar is-dark is-fixed-top" key="header_view" oncreate={oncreate} ondestroy={ondestroy}>
      <div class="navbar-brand">
        {authed && (<SidePanel rights={rights} sidepanel={state.sidepanel} version={state.version} setstate={actions.setstate} logout={actions.auth.logout} />)}
        <Link to="/" class={"navbar-item" + (!state.sidepanel && state.location.pathname == '/' ? ' is-active' : '')}>{state.title}</Link>
        {authed && (
          <Link class={"navbar-item navbar-user is-pulled-right" + (!state.sidepanel && state.location.pathname == '/profile' ? ' is-active' : '')} to="/profile" >{avatarUrl ? <img src={avatarUrl} /> : <span class="icon"><i class="fas fa-user"></i></span>}<span class="is-hidden-mobile">&nbsp;{user && (user.displayName || user.email.split('@')[0])}</span></Link>
        )}
      </div>
    </div>
  </header>
  )
}
