import { h } from 'hyperapp';
import '../../css/loading.css'

export const Loading = () => {
  const circles = new Array(12).fill()
  return (
    <div class="loading">
      {circles.map((_, i) => (<div class="dot"></div>))}
    </div>
  );
}
