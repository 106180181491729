export const cookies = {
  write: function(name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }
    const cookie = name + '=' + value + expires + '; path=/';
    document.cookie = cookie
  },
  read: function(name) {
    if (document.cookie) {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    return null;
  },
  erase: function(name) {
    this.write(name, '', -1);
  },
  check: function() {
    this.create('mwtCookiesEnabled', 'moonwalker.tech');
    if (this.read('mwtCookiesEnabled') !== null) {
      this.erase('mwtCookiesEnabled');
      return false;
    }
    return true;
  },
  exists: function(name) {
    return this.read(name) !== null;
  }
};
