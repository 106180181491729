import { client } from '../modules/client'
import { Criteria, validateCriteria, getDatasources } from "../modules/rule";

export const query = {
  getEvents: () => async (state, actions) => {
    const internal = await client.getInternalEvents();
    if (internal.error) {
      return actions.setState({
        error: internal.error
      })
    }
    const transactions = await client.getTransactions();
    if (transactions.error) {
      return actions.setState({
        error: transactions.error
      })
    }
    return actions.setState({ events: (internal.events || []), transactions: (transactions.events || []) });
  },
  newQuery: () => async (state, actions) => {
    if (!state.events) await actions.getEvents();
    if (!state.datasources) await actions.getQueries();
    const criteria = new Criteria();
    const { datasource, params, filters, aggregates } = criteria;
    const previewData = JSON.stringify({ datasource, params, filters, aggregates }, null, 2);
    return actions.setState({ criteria, error: '', result: null, userId: '', preview: 'query', previewData });
  },
  runQuery: () => async (state, actions) => {
    const err = validateCriteria(state, state.datasources);
    if (!!err) {
      return actions.setState({
        error: err
      })
    }
    const res = await client.runQuery(state.userId, state.criteria);
    if (res.error) {
      if (!!err) {
        return actions.setState({
          error: res.error
        })
      }
    }
    return actions.setState({ result: res });
  },
  getQueries: () => async (state, actions) => {
    if (state.datasources)
      return actions.setState(state);
    const data = await client.getQueries();
    return actions.setState(getDatasources(data));
  },
  setState: (state) => () => {
    return state;
  }
}
