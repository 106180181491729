import { h } from 'hyperapp'
import { CapsuleEditor } from './components/capsule-editor'

export const Capsule = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  const { isAdmin } = state.auth;
  return (
    <section class="section" key="capsule_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} Capsule
      </h1>
      <CapsuleEditor state={state.capsule} actions={actions.capsule} id={id} isAdmin={isAdmin} />
    </section>
  )
}
