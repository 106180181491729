import { h } from 'hyperapp'

import '../../css/emulate.css'

const example = `
Slug,Game_Name,Provider,Studio,Type,Category,Themes,Bonus_Features,Wild_Features,Win_Features,Format,Minimum_Bet,Maximum_Bet,RTP,Volatility,Max_Win_Multiplier,Reels,Rows,Maximum_Paylines,Minimum_Bet,Hit_Frequency,Hit_Frequency_Bonus,Excluded_Markets,Release_Date,Fee_Group,String,Valid,String,Valid
10001-nights,10001 Nights,red-tiger,red-tiger,video,slots,arabic,"free-spins,special-features,pick-and-win,"wild-reels,random-wilds","collectibles,play-and-progress",standard,0.1,40,0.9573,5,0,0,4,20,0,0,0,,,,,false,,true
`

const exampleBrand = `
Slug,Provider_Ids,Priority,Tags,Labels,Enabled
10001-nights,android-phone:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 android-tablet:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 desktop:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 ipad:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 iphone:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 ipod:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 wm7:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084 wm8:EUR:ROW:redtiger/10001Nights/32124 demo:ROW:redtiger/10001Nights/32084,971,,,true
`

const semantics = `
column separator is the comma ','
lists need to be between quotes (eg: '"first,second"')
object field value follows field name after colon ':' (eg: 'field:value')
objects fields are space separated ' '  (eg: 'maximumBet:40 maximumPaylines:20')
embedded objects are also. the number of colons define the depth (eg: 'desktop:EUR:ROW:a DE:b CAD:ROW:c CA:d iphone:EUR:ROW:e DE:f CAD:ROW:g CA:h')
`

export const GameUpload = ({ state, actions }) => {
  const select = (e) => {
    actions.setFile(e.target.files[0])
  }
  const info = () => {
    const modal = document.querySelector('[id="games_upload_view"]').querySelector('.modal')
    modal.querySelector('.example').innerText = example
    modal.classList.add('is-active')
  }
  const selectBrand = (e) => {
    actions.setBrandFile(e.target.files[0])
  }
  const infoBrand = () => {
    const modal = document.querySelector('[id="games_upload_view"]').querySelector('.modal')
    modal.querySelector('.example').innerText = exampleBrand
    modal.classList.add('is-active')
  }
  const close = () => {
    document.querySelector('[id="games_upload_view"]').querySelector('.modal').classList.remove('is-active')
  }
  return (
    <section class="section" key="games_upload_view" id="games_upload_view">
      <h1 class="title">
        Upload Meta Data
      </h1>
      <hr />
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="file" onchange={select} />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file
                </span>
              </span>
              <span class="file-name">
                {state.file ? state.file.name : '...'}
              </span>
            </label>
            <a onclick={info}>
              <span class="icon has-text-info">
                <i class="fas fa-info-circle"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          {(!state.uploadFailed && state.count == 0 && typeof state.progress != 'undefined') &&
            <div>
              <h2>
                <i class="fas fa-spinner progress-spinner"></i>&nbsp;
                {state.progress < 1 ?
                  (<span>{Math.round(state.progress * 100)}%</span>) :
                  (<span>Upload complete. Processing...</span>)}
              </h2>
              <progress class="progress is-info" value={state.progress} />
            </div>
          }
          {state.uploadFailed &&
            <h2>Upload failed: {state.uploadFailed}</h2>
          }
          {!state.uploadFailed && (state.count > 0 || state.elapsed > 0) &&
            <h2>{state.count} meta data uploaded in {state.elapsed}s</h2>
          }
        </div>
        <div class="column">
          <a class="button" onclick={actions.upload} disabled={!state.file || state.loading}>
            <span class="icon">
              <i class="fas fa-cloud-upload-alt"></i>
            </span>
            <span>Upload</span>
          </a>
        </div>
      </div>
      {/* Brand specific data */}
      <h1 class="title">
        Upload Brand Data
      </h1>
      <hr />
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="file has-name">
            <label class="file-label">
              <input class="file-input" type="file" name="brandFile" onchange={selectBrand} />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                  Choose a file
                </span>
              </span>
              <span class="file-name">
                {state.brandFile ? state.brandFile.name : '...'}
              </span>
            </label>
            <a onclick={infoBrand}>
              <span class="icon has-text-info">
                <i class="fas fa-info-circle"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          {(!state.uploadBrandFailed && state.brandCount == 0 && typeof state.brandProgress != 'undefined') &&
            <div>
              <h2>
                <i class="fas fa-spinner progress-spinner"></i>&nbsp;
                {state.brandProgress < 1 ?
                  (<span>{Math.round(state.brandProgress * 100)}%</span>) :
                  (<span>Upload complete. Processing...</span>)}
              </h2>
              <progress class="progress is-info" value={state.brandProgress} />
            </div>
          }
          {state.uploadBrandFailed &&
            <h2>Upload failed: {state.uploadBrandFailed}</h2>
          }
          {!state.uploadBrandFailed && (state.brandCount > 0 || state.brandElapsed > 0) &&
            <h2>{state.brandCount} brand data uploaded in {state.brandElapsed}s</h2>
          }
        </div>
        <div class="column">
          <a class="button" onclick={actions.uploadBrand} disabled={!state.brandFile || state.brandLoading}>
            <span class="icon">
              <i class="fas fa-cloud-upload-alt"></i>
            </span>
            <span>Upload</span>
          </a>
        </div>
      </div>
      <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">CSV help</p>
            <button class="delete" onclick={close}></button>
          </header>
          <section class="modal-card-body">
            <h2>Semantics</h2>
            <pre class="textlog">{semantics}</pre>
            <h2>Example</h2>
            <pre class="textlog example"></pre>
          </section>
          <footer class="modal-card-foot">
            <button class="button" onclick={close}>OK</button>
          </footer>
        </div>
      </div>
    </section >
  )
}
