import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

import { untagify, validateButton, validateEditor } from '../../modules/utils';

import { validate } from '../../modules/country';
import { Loading } from './loading';
import { RequiredField, TagsInput, MetaData } from './common';
import { EditorButtons } from './editor-buttons';

export const CountryEditor = ({ state, actions, id }) => {

  const getData = () => {
    actions.getCountry(id);
  }

  const setValue = (prop, value) => {
    actions.setState({
      [prop]: value,
      modified: true
    });
  }

  const validateSaveButton = (el) => {
    validateButton(el, validate(state))
  }

  const save = (e, exit, empty) => {
    if (state.modified) {
      const err = validate(state);
      validateEditor(e.target.closest('.country-editor'));
      if (err) {
        actions.setState({ error: err });
      } else {
        actions.saveCountry({ exit, empty });
      }
    }
  }

  const saveAndExit = (e) => {
    save(e, true);
  }

  const saveAndNew = (e) => {
    save(e, false, true);
  }

  const onChange = (prop) => ({ target }) => {
    setValue(prop, target.value);
  }

  const onCurrenciesChange = (currencies) => {
    setValue('currencies', currencies.split(','));
  }

  const onLanguagesChange = (languages) => {
    setValue('languages', languages.split(','));
  }

  const onStatesChange = (states) => {
    setValue('states', states.split(/\s*,\s*/));
  }

  return (
    <div class="country-editor" oncreate={getData} >
      {state.loaded ? (<div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Name</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Name" value={state.name} onchange={onChange('name')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Code</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Code" value={state.code} onchange={onChange('code')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Alpha Code</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Alpha Code" value={state.alphaCode3} onchange={onChange('alphaCode3')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Phone Prefix</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Phone Prefix" value={state.phonePrefix} onchange={onChange('phonePrefix')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Legal Age</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Legal Age" value={state.legalAge} onchange={onChange('legalAge')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">States</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <TagsInput placeholder="States" value={untagify((state.states || []).join(','))} onchange={onStatesChange} className="is-expanded" />
              </p>
            </div>
          </div>
        </div>
        {/* <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Currencies</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <TagsInput placeholder="Currencies" value={untagify((state.currencies || []).join(','))} onchange={onCurrenciesChange} className="is-expanded" datasource={state.availableCurrencies} isUpperCase={true} />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Languages</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <TagsInput placeholder="Languages" value={untagify((state.languages || []).join(','))} onchange={onLanguagesChange} className="is-expanded" datasource={state.availableLanguages} isLowerCase={true} />
              </p>
            </div>
          </div>
        </div> */}
        <MetaData {...state} />
        <EditorButtons clear={actions.getCountry} cancelTo="countries" save={save} validateSave={validateSaveButton} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!state.error} />
      </div>) : (<Loading />)}
      {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
    </div>)
}
