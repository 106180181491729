import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'
import { getDatasources, getActionMethods } from '../modules/rule'
import { mapRuleItems } from '../modules/rules'

export const rules = {
  setRules: (rules) => (rules),
  getRules: (params) => async (state, actions) => {
    var { filter, order, skip, take, includeArchived, defaultOrder } = state;
    const req = {};

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      filter = Object.keys(filter).filter(f => (filter[f] !== "" && filter[f] !== undefined)).sort().map(f => `${f}:${filter[f]}`).join(';');
      if (Object.keys(filter)) {
        req.filter = filter;
      }
    }
    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.includeArchived) !== 'undefined') {
      if (params.includeArchived)
        req.includeArchived = true;
    } else if (includeArchived) {
      req.includeArchived = true;
    }
    const res = await client.getRules(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    const { actionMethods } = await actions.getActions();
    const { datasources } = await actions.getQueries();
    res.items = res.items.map(mapRuleItems(datasources || [], actionMethods || []));
    res.methods = (actionMethods || []).map(a => a.name);
    res.loaded = true;
    res.includeArchived = req.includeArchived;
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    res.repository = res.repo;
    return actions.setRules(res);
  },
  toggleRule: (r) => async (state, actions) => {
    if (r.canActivate) {
      r.active = !r.active;
      const res = await client.saveRule(r);
      if (res.error) {
        return actions.setRules({
          error: res.error
        })
      }
      const items = state.items.map(i => r.id === i.id ? Object.assign({}, i, r) : i)
      return actions.setRules({ items });
    }
  },
  deleteRule: (id) => async (state, actions) => {
    const res = await client.deleteRule(id);
    if (res.error) {
      return actions.setRules({
        error: res.error
      })
    }
    return actions.getRules();
  },
  restoreRule: ({ id }) => async (state, actions) => {
    const res = await client.restoreRule(id);
    if (res.error) {
      return actions.setRules({
        error: res.error
      })
    }
    return actions.getRules();
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getRules({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getRules({ order });
  },
  filterRules: (filter) => async (state, actions) => {
    return actions.getRules({ filter, skip: 0 });
  },
  getEvents: () => async (state, actions) => {
    if (!state.events) {
      const internal = await client.getInternalEvents();
      if (internal.error) {
        return actions.setRules({
          error: internal.error
        })
      }
      const transactions = await client.getTransactions();
      if (transactions.error) {
        return actions.setRules({
          error: transactions.error
        })
      }
      const res = await client.getEvents();
      if (res.error) {
        return actions.setRules({
          error: res.error
        })
      }
      return actions.setRules({ events: (internal.events || []).concat(transactions.events || []).concat(res.events || []) });
    }
    return state
  },
  getActions: () => async (state, actions) => {
    if (!state.actionMethods) {
      const data = await client.getActions();
      if (data.error) {
        return actions.setRules({
          error: data.error
        })
      }
      return actions.setRules(getActionMethods(data));
    }
    return state
  },
  getQueries: () => async (state, actions) => {
    if (!state.datasources) {
      const data = await client.getQueries();
      return actions.setRules(getDatasources(data, true));
    }
    return state
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  exportRules: (r) => async (state, actions) => {
    const rules = await client.export(r && r.id);
    return rules.items;
  },
  importRules: (data) => async (state, actions) => {
    const res = await client.import(data);
    if (res.error) {
      return actions.setRules({
        error: res.error
      })
    }
    return actions.getRules();
  },
  toggleIncludeArchived: () => async (state, actions) => {
    return actions.getRules({ includeArchived: !state.includeArchived });
  },
  regenerateScripts: () => async (state, actions) => {
    const res = await client.regenerateScripts();
    if (res.error) {
      return actions.setRules({
        error: res.error
      })
    }
    return actions.getRules();
  },
}
