import { client } from '../modules/client'
import { Capsule, CapsuleProp, validate } from "../modules/capsule";

export const capsule = {
  setState: (state) => (state),
  getEvents: () => async (state, actions) => {
    if (state.events) return actions.setState({});
    const res = await client.getCapsuleEvents();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({ events: res.events });
  },
  getCapsule: (id) => async (state, actions) => {
    if (id) {
      const res = await client.getCapsule(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.capsule) {
        return actions.setState(new Capsule(res.capsule));
      }
      return actions.setState({
        error: "Capsule not found",
        loaded: true
      })
    }
    return actions.setState(new Capsule(state.clone));
  },
  saveCapsule: (onDone) => async (state, actions) => {
    var err = validate(state, state);
    if (state.enabled && !!err) {
      return actions.setState({
        error: err
      })
    }
    const res = await client.saveCapsule(state);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      })
    }
    if (onDone) {
      if (onDone.exit) {
        history.pushState(location.pathname, '', '/capsules')
      } else if (onDone.empty) {
        history.pushState(location.pathname, '', '/capsules/new')
        return actions.setState(new Capsule());
      } else {
        if (location.pathname == '/capsules/new' && res.items.length) {
          actions.setState({ id: res.items[0].id });
          history.pushState(location.pathname, '', '/capsules/' + res.items[0].id)
        }
        return actions.setState({ modified: false });
      }
    }
  },
  restoreCapsule: () => async (state, actions) => {
    const res = await client.restoreCapsule(state.id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({ modified: false, archive: false });
  },
  togglePreview: () => state => {
    return { preview: !state.preview }
  },
  addDefaultProp: () => (state) => {
    return {
      modified: true,
      defaultProps: (state.defaultProps || []).concat(new CapsuleProp())
    }
  },
  setDefaultProp: (p) => (state) => {
    return {
      modified: true,
      defaultProps: state.defaultProps.map(i => p.id === i.id ? Object.assign({}, i, p) : i)
    }
  },
  removeDefaultProp: (p) => (state) => {
    const defaultProps = state.defaultProps.filter(i => p.id !== i.id)
    return { modified: true, defaultProps }
  },
}
