import { Items } from './classes'
import { Entry } from './classes'

export class Languages extends Items {
    constructor() {
        super('name');
        this.modal = null;
    }
}

export class Language extends Entry {
    constructor(props) {
        super(props)
    }
}

export const validate = (p) => {
    return !p.code && 'Code is mandatory' ||
        !p.name && 'Name is mandatory' ||
        !p.months && 'Months are mandatory' ||
        p.months.length != 12 && 'Please enter exactly 12 months';
}