import { client } from '../modules/client'
import { mapToObject, getParamValues } from '../modules/utils'
import { Rule, Action, Criteria, validate, getDatasources, getActionMethods, ruleFromState, connectors } from "../modules/rule";

export const formula = {
  setState: (state) => (state),
  getEvents: () => async (state, actions) => {
    if (state.events) return actions.setState({});
    const res = await client.getFormulaEvents();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({ events: res.events });
  },
  getFormula: (id) => async (state, actions) => {
    await actions.getEvents();
    await actions.getQueries();
    await actions.getActions();
    if (id) {
      const res = await client.getFormula(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.rule) {
        return actions.setState(new Rule(res.rule));
      }
      return actions.setState({
        error: "Formula not found",
        loaded: true
      })
    }
    return actions.setState(new Rule(state.clone));
  },
  saveFormula: (onDone) => async (state, actions) => {
    var err = validate(state, state);
    if (state.active && !!err) {
      return actions.setState({
        error: err
      })
    }
    const rule = ruleFromState(state);
    const res = await client.saveFormula(rule);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      })
    }
    if (onDone) {
      if (onDone.exit) {
        history.pushState(location.pathname, '', '/formulas')
      } else if (onDone.empty) {
        history.pushState(location.pathname, '', '/formulas/new')
        return actions.setState(new Rule());
      } else {
        if (location.pathname == '/formulas/new' && res.items.length) {
          actions.setState({ id: res.items[0].id });
          history.pushState(location.pathname, '', '/formulas/' + res.items[0].id)
        }
        return actions.setState({ modified: false });
      }
    }
  },
  restoreFormula: () => async (state, actions) => {
    const res = await client.restoreFormula(state.id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({ modified: false, archive: false });
  },
  selectEvent: (event) => async (state, actions) => {
    // const event = events.find(e => e.name === eventName);
    var actionData = state.actionData;
    if (state.actionData && state.actionData['rules/event/id']) {
      const data = await actions.getActionData({ source: 'rules', kind: 'event', key: 'id', event: event });
      if (data.error) {
        return actions.setState({
          error: data.error
        })
      }
      actionData = data.actionData;
    }
    return actions.setState({
      modified: true,
      event,
      actionData
    })
  },
  addCriteria: () => (state) => {
    const connector = state.criterias.length ? connectors.AND : connectors.OR;
    return {
      modified: true,
      criterias: state.criterias.concat(new Criteria({ connector }))
    }
  },
  addCriteriaGroup: () => (state) => {
    const connector = state.criterias.length ? connectors.AND : connectors.OR;
    return {
      modified: true,
      criterias: state.criterias.concat(new Criteria({ connector, items: [new Criteria({ connector })] }))
    }
  },
  setCriteria: (c) => (state) => {
    return {
      modified: true,
      criterias: state.criterias.map(i => c.id === i.id ? Object.assign({}, i, c) : i)
    }
  },
  removeCriteria: (c) => (state) => {
    const criterias = state.criterias.filter(i => c.id !== i.id)
    if (criterias.length) {
      criterias[0].connector == connectors.OR;
    }
    return { criterias }
  },
  addAction: () => (state) => {
    return {
      modified: true,
      actions: state.actions.concat(new Action())
    }
  },
  setAction: (r) => (state) => {
    return {
      modified: true,
      actions: state.actions.map(i => r.id === i.id ? Object.assign({}, i, r) : i)
    }
  },
  removeAction: (r) => (state) => {
    const actions = state.actions.filter(i => r.id !== i.id);
    if (!actions.length) actions.push(new Action());
    return { modified: true, actions }
  },
  getActions: () => async (state, actions) => {
    if (state.actionMethods) return actions.setState({});
    const data = await client.getReactions();
    if (data.error) {
      return actions.setState({
        error: data.error
      })
    }
    return actions.setState(getActionMethods(data));
  },
  getActionData: ({ source, kind, key, query, event }) => async (state, actions) => {
    const path = `${source}/${kind}` + (!!key ? `/${key}` : '') + (!!query ? `/${query}` : '');
    if (source == 'rules' && kind == 'event') {
      actions.setActionData({ [path]: { loading: true } });
      const data = await client.getEvents(event || state.event);
      if (data.error) {
        return actions.setState({
          error: `Something went wrong. ${data.error}`
        })
      }
      return actions.setActionData({ [path]: { data: data.events.filter(e => (e.id != event)), loading: false } });
    } else if (source == 'rules' && kind == 'capsule') {
      actions.setActionData({ [path]: { loading: true } });
      const data = await client.getCapsules({ take: 0 });
      if (data.error) {
        return actions.setState({
          error: `Something went wrong. ${data.error}`
        })
      }
      return actions.setActionData({ [path]: { data: data.items.filter(c => (`${c.component.toUpperCase()}_LOAD` != event)), loading: false } });
    }
    if (state.actionData && state.actionData[path]) {
      return actions.setState(state);
    }
    actions.setActionData({ [path]: { loading: true } });
    const data = await client.getActionData(source, kind, key, query);
    return actions.setActionData({ [path]: { data: (data || []), loading: false } });
  },
  setActionData: data => state => {
    return { actionData: Object.assign(state.actionData || {}, data) }
  },
  getParamsData: ({ source, kind, key, query, display }) => async (state, actions) => {
    key = key || 'code';
    display = display || 'name';
    const path = `${source}/${kind}` + (!!query ? `/${query}` : '');
    if (state.paramsData && state.paramsData[path]) {
      return actions.setState(state);
    }
    actions.setParamsData({ [path]: { loading: true } });
    const data = await client.getParamsData(source, kind, `${key},${display}`);
    return actions.setParamsData({ [path]: { data: mapToObject(data, key, display), loading: false } });
  },
  setParamsData: data => state => {
    return { paramsData: Object.assign(state.paramsData || {}, data) }
  },
  getQueries: () => async (state, actions) => {
    if (state.datasources)
      return actions.setState(state);
    const data = await client.getDatasources();
    if (data.error) {
      console.error(data.error);
      return;
    }
    return actions.setState(getDatasources(data, true));
  },
  togglePreview: () => state => {
    return { preview: !state.preview }
  },
  showEventFields: ({ action, index, value }) => (state) => {
    const triggerEvent = state.events.find(e => (e.id == state.event));
    const event = state.events && state.events.find(e => (e.id == value))
    var eventFields
    if (!!event) {
      eventFields = (event.fields || []).filter(f => !f.readonly)
    }
    return {
      eventFields: {
        open: true,
        event,
        eventFields,
        action,
        index,
        fields: getParamValues(triggerEvent, state),
        values: action.parameters[index].value || {}
      }
    }
  },
  setEventFields: ({ action, index, data }) => (state, actions) => {
    action.parameters[index].value = data;
    const res = actions.setAction(action);
    res.eventFields = state.eventFields;
    res.eventFields.values = data;
    return res;
  }
}
