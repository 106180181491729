import { Items } from './classes'
import { Entry } from './classes'

export class Games extends Items {
    constructor() {
        super('slug');
        this.modal = null;
        this.activeTab = 'upload';
    }
}

export class Game extends Entry {
    constructor(props) {
        super(props)
    }
}

export class GameUpload {
    constructor() {
        return {
            file: undefined,
            progress: undefined,
            count: 0,
            elapsed: 0,
            uploadFailed: false,
            slugs: '',
            brandFile: undefined,
            brandProgress: undefined,
            brandCount: 0,
            brandElapsed: 0,
            uploadBrandFailed: false,
            brandSlugs: '',
            deleteFile: undefined,
            deleteProgress: undefined,
            deleteCount: 0,
            deleteElapsed: 0,
            uploadDeleteFailed: false
        }
    }
}

const isValidSlug = (s) => {
    return /^[a-z0-9_-]{1,50}$/.test(s);
}

export const validate = (p) => {
    return !p.gameName && 'Name is mandatory' ||
        !p.slug && 'Slug is mandatory' ||
        isValidSlug(p.slug) && 'Slug is invalid'
}