import { h } from 'hyperapp'
import { Route, Switch } from '@hyperapp/router'

import { Layout } from './layout'
import { Login } from './login'
import { Home } from './home'
import { Rules } from './rules'
import { Rule } from './rule'
import { Emulate } from './emulate'
import { Events } from './events'
import { CrmEvents } from './crmevents'
import { Trigger } from './trigger'
import { Tasks } from './tasks'
import { User } from './user'
import { Users } from './users'
import { Query } from './query'
import { Roles } from './roles'
import { Policies } from './policies'
import { Policy } from './policy'
import { Profile } from './profile'
import { UnAuthorized } from './403'
import { NotFound } from './404'
import { Formulas } from './formulas'
import { Formula } from './formula'
import { Capsules } from './capsules'
import { Capsule } from './capsule'
import { Config } from './config'
import { Countries } from './countries'
import { Country } from './country'
import { Currencies } from './currencies'
import { Currency } from './currency'
import { Languages } from './languages'
import { Language } from './language'
import { Siteconfigs } from './siteconfigs'
import { Siteconfig } from './siteconfig'
import { Games } from './games'
import { Freespins } from './freespins'

const r = (state, actions, view, match) => {
  return () => state.auth.authed ? Layout({ state, actions, match }, view) : Login({ state, actions })
}

export const view = (state, actions) => (
  <div oncreate={() => actions.auth.authorize()}>
    <Switch>
      <Route path="/" render={() => r(state, actions, Home)} />

      <Route path="/rules" render={() => r(state, actions, Rules)} />
      <Route parent path="/rules/:id" render={({ match }) => r(state, actions, Rule, match)} />
      <Route path="/rules/new" render={() => r(state, actions, Rule)} />

      <Route path="/formulas" render={() => r(state, actions, Formulas)} />
      <Route parent path="/formulas/:id" render={({ match }) => r(state, actions, Formula, match)} />
      <Route path="/formulas/new" render={() => r(state, actions, Formula)} />

      <Route path="/capsules" render={() => r(state, actions, Capsules)} />
      <Route parent path="/capsules/:id" render={({ match }) => r(state, actions, Capsule, match)} />
      <Route path="/capsules/new" render={() => r(state, actions, Capsule)} />

      <Route path="/events" render={() => r(state, actions, Events)} />
      <Route parent path="/events/:id" render={({ match }) => r(state, actions, Events, match)} />

      <Route path="/crmevents" render={() => r(state, actions, CrmEvents)} />
      <Route parent path="/crmevents/:id" render={({ match }) => r(state, actions, CrmEvents, match)} />

      <Route path="/emulate" render={() => r(state, actions, Emulate)} />

      <Route path="/trigger" render={() => r(state, actions, Trigger)} />

      <Route path="/query" render={() => r(state, actions, Query)} />

      <Route path="/tasks" render={() => r(state, actions, Tasks)} />
      <Route parent path="/tasks/:queue" render={({ match }) => r(state, actions, Tasks, match)} />

      <Route path="/users" render={() => r(state, actions, Users)} />
      <Route parent path="/users/:id" render={({ match }) => r(state, actions, User, match)} />
      <Route path="/users/new" render={() => r(state, actions, User)} />

      <Route path="/roles" render={() => r(state, actions, Roles)} />

      <Route path="/policies" render={() => r(state, actions, Policies)} />
      <Route parent path="/policies/:id" render={({ match }) => r(state, actions, Policy, match)} />
      <Route path="/policies/new" render={() => r(state, actions, Policy)} />

      <Route path="/profile" render={() => r(state, actions, Profile)} />

      <Route path="/config" render={() => r(state, actions, Config)} />

      <Route path="/countries" render={() => r(state, actions, Countries)} />
      <Route parent path="/countries/:id" render={({ match }) => r(state, actions, Country, match)} />
      <Route path="/countries/new" render={() => r(state, actions, Countries)} />

      <Route path="/currencies" render={() => r(state, actions, Currencies)} />
      <Route parent path="/currencies/:id" render={({ match }) => r(state, actions, Currency, match)} />
      <Route path="/currencies/new" render={() => r(state, actions, Currency)} />

      <Route path="/languages" render={() => r(state, actions, Languages)} />
      <Route parent path="/languages/:id" render={({ match }) => r(state, actions, Language, match)} />
      <Route path="/languages/new" render={() => r(state, actions, Language)} />

      <Route path="/siteconfigs" render={() => r(state, actions, Siteconfigs)} />
      <Route parent path="/siteconfigs/:id" render={({ match }) => r(state, actions, Siteconfig, match)} />
      <Route path="/siteconfigs/new" render={() => r(state, actions, Siteconfig)} />

      <Route path="/games" render={() => r(state, actions, Games)} />
      <Route path="/games/upload" render={({ match }) => r(state, actions, Games, match)} />
      <Route path="/games/export" render={({ match }) => r(state, actions, Games, match)} />
      <Route path="/games/delete" render={({ match }) => r(state, actions, Games, match)} />
      <Route path="/games/history" render={({ match }) => r(state, actions, Games, match)} />
      {/* <Route parent path="/games/:id" render={({ match }) => r(state, actions, Game, match)} />
      <Route path="/games/new" render={() => r(state, actions, Games)} /> */}

      <Route path="/freespins" render={() => r(state, actions, Freespins)} />

      <Route path="/restricted" render={() => r(state, actions, UnAuthorized)} />

      <Route render={() => r(state, actions, NotFound)} />
    </Switch>
  </div>
)
