import { h } from 'hyperapp';
import { EditorButtons } from './editor-buttons';

export const RuleButtons = ({ clear, cancelTo = "rules", canPlay, openPlay, playDisabled, validatePlay, save, saveAndNew, saveAndExit, saveDisabled, preview, togglePreview }) => {
  return (<EditorButtons clear={clear} cancelTo={cancelTo} save={save} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={saveDisabled} >
    {canPlay && (<p class="control">
      <button class={"button is-warning" + (playDisabled ? " disabled" : "")} onclick={openPlay} oncreate={validatePlay} onupdate={validatePlay}>
        <span class="icon">
          <i class="fas fa-play-circle"></i>
        </span>
        <span>Play</span>
      </button>
    </p>)}
    <p class="control">
      <button class="button is-info" title={(preview ? 'Close' : 'Open') + ' rule'} onclick={togglePreview}>
        <span class="icon is-small">
          {preview ? <i class="far fa-minus-square"></i> : <i class="far fa-plus-square"></i>}
        </span>
        <span>Preview</span>
      </button>
    </p>
  </EditorButtons>)
}

export const RestoreButton = ({ restore, preview, togglePreview }) => {
  return (<EditorButtons save={restore} saveText="Restore" saveIcon="fas fa-trash-restore">
    <p class="control">
      <button class="button is-info" title={(preview ? 'Close' : 'Open') + ' rule'} onclick={togglePreview}>
        <span class="icon is-small">
          {preview ? <i class="far fa-minus-square"></i> : <i class="far fa-plus-square"></i>}
        </span>
        <span>Preview</span>
      </button>
    </p></EditorButtons>)
}
