import { h } from 'hyperapp'
import { tryParse } from '../../modules/utils'
import { messageBroker } from '../../modules/messagebroker'

import '../../css/rules-status.css'

export const RulesStatus = ({ compact, onclick }) => {
  const oncreate = (el) => {
    messageBroker.on('rules.status', setStatus(el))
  }

  const ondestroy = (el) => {
    messageBroker.removeListener('rules.status', setStatus(el))
  }

  const setStatus = (el) => (status) => {
    if (status) {
      sessionStorage.setItem('rules.status', JSON.stringify(status))
      el.classList.add('initialized')
      const code = el.querySelector('code')
      if (code) {
        code.innerText = status ? JSON.stringify(status, null, 2) : ""
      }
      if (status.engineStats && status.engineStats.engineEnabled) {
        el.classList.add('enabled')
        if (!compact) {
          const icon = el.querySelector('.icon i')
          if (icon) {
            icon.classList.remove('fa-times-circle')
            icon.classList.add('fa-check-circle')
          }
        }
      } else {
        el.classList.remove('enabled')
        if (!compact) {
          const icon = el.querySelector('.icon i')
          if (icon) {
            icon.classList.add('fa-times-circle')
            icon.classList.remove('fa-check-circle')
          }
        }
      }
    }
  }

  const toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onclick) {
      onclick(e);
    }
  }

  var _status = tryParse(sessionStorage.getItem('rules.status'))
  var engineEnabled = _status && _status.engineStats && _status.engineStats.engineEnabled

  return (
    <div class={'rules_status' + (_status ? ' initialized' : '') + (compact ? ' compact' : '') + (engineEnabled ? ' enabled' : '')} oncreate={oncreate} ondestroy={ondestroy}>
      {!compact ? (
        <span class="icon is-small" onclick={toggle}>
          {engineEnabled ? <i class="far fa-check-circle"></i> : <i class="far fa-times-circle"></i>}
        </span>
      ) : _status && (<div class="rules_status_info">
        <pre><code class="language-javascript" >{JSON.stringify(_status, null, 2)}</code></pre>
      </div>)}


    </div>
  )
}
