import { h } from 'hyperapp'
import { tryParse, getFilterPath, splitUnderscore, propertyToString, capitalize } from '../modules/utils'
import { Tr, TrTagsCell, TrButton, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Formulas = ({ state, actions }) => {
  const { filter, order, methods, events } = state.formulas;
  const { isAdmin } = state.auth

  const getData = () => {
    actions.formulas.getEvents();
    actions.formulas.getFormulas();
  }

  const duplicateFormula = ({ id, active, ...rest }) => {
    actions.formula.setState({ clone: rest });
    actions.location.go('/formulas/new');
  }

  const deleteClick = ({ id, title }) => {
    const { error } = actions.formulas.deleteFormula(id);
    if (!error) {
      actions.snackbar.open(<span>{`${title} deleted successfully.`}&nbsp;<button class="button is-text is-small is-light" onclick={() => actions.formulas.restoreFormula({ id })}>Undo.</button></span>)
    }
  }

  const importFormulas = () => {
    const modal = {
      title: `Import formulas`,
      confirmText: "Import",
      content: (<textarea id="import_formula" rows="25" class="textarea" placeholder="Import formulas" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_formula').value;
        const res = tryParse(text);
        if (res)
          actions.formulas.importFormulas(res);
      },
      open: true,
      toggle: actions.formulas.toggleModal
    }
    actions.formulas.toggleModal(modal);
  }

  const exportFormulas = (r) => {
    actions.formulas.exportFormulas(r).then(formulas => {
      const modal = {
        title: `Exported formulas`,
        content: (<textarea id="export_formula" rows="25" class="textarea" value={JSON.stringify(formulas, null, 2)} readonly autofocus></textarea>),
        open: true,
        toggle: actions.formulas.toggleModal
      }
      actions.formulas.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_formula').focus();
      })
    });
  }

  const exportFormulasClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    exportFormulas()
    return false
  }

  const toggleTag = (tags) => {
    actions.location.go(getFilterPath('tags', tags))
    actions.formulas.filterFormulas({ tags })
  }

  const toggleFormula = (r) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    actions.formulas.toggleFormula(r)
    return false
  }

  const toggleIncludeArchived = () => {
    actions.formulas.toggleIncludeArchived();
  }

  return (<div>
    <ListView name="formula" columns={isAdmin && state.formulas.includeArchived ? "7" : "6"} getItems={getData} onDelete={deleteClick} goToPage={actions.formulas.goToPage} {...state.formulas}
      renderHead={() => (<Tr>
        <Th field="title" filter={filter} order={order} filterAction={actions.formulas.filterFormulas} setOrderAction={actions.formulas.setOrder} route={actions.location} />
        <Th field="tags" filter={filter} filterAction={actions.formulas.filterFormulas} setOrderAction={actions.formulas.setOrder} route={actions.location} />
        <Th field="event" order={order} filter={filter} items={events} filterAction={actions.formulas.filterFormulas} setOrderAction={actions.formulas.setOrder} route={actions.location} valueField="id" displayField="label" />
        <Th field="methods" filter={filter} items={methods} actions={actions} route={actions.location} format={propertyToString} />
        <Th field="active" items={['true', 'false']} filter={filter} filterAction={actions.formulas.filterFormulas} setOrderAction={actions.formulas.setOrder} route={actions.location} format={capitalize} />
        <th class="th-btn"></th>
        <th class="th-btn"></th>
        {isAdmin && state.formulas.includeArchived && (<th class="th-btn"></th>)}
      </Tr>)}
      renderBody={item => (<Tr>
        <td class="title-cell">
          <span>{item.title}</span>
          {item.desc ? (<span>
            <span class="icon is-small"><i class="fas fa-info-circle"></i></span>
            <div class="desc">
              <article class="message">
                <div class="message-body">
                  {item.desc.split('\n').map(l => {
                    return (<span>{l}<br /></span>)
                  })}
                </div>
              </article>
            </div></span>) : null}
        </td>
        <td>
          <TrTagsCell tags={item.tags && item.tags.split(',') || []} filter={filter && filter.tags} onchange={toggleTag} />
        </td>
        <td>{(events.find(e => e.id == item.event) || {}).label || item.event}</td>
        <td>{item.actions && item.actions.map(a => {
          return (<span style={{'white-space':'nowrap'}}>{propertyToString(a.method)}<br /></span>)
        })}
        </td>
        <td>
          {item.error ? (<span class="icon has-text" style={{cursor:'help'}} title={item.error}><i class="fas fa-exclamation-triangle"></i></span>) : (
            <span class="icon is-small" onclick={toggleFormula(item)}>
              {item.active ?
                (item.isScheduled ?
                  (item.isInPeriod ? <i class="far fa-clock"></i> : <i class="far fa-times-circle"></i>) :
                  <i class="far fa-check-square"></i>) :
                <i class="far fa-square"></i>}
            </span>
          )}
        </td>
        <td>
          <TrButton name="formula" item={item} title="Export" onclick={exportFormulas} icon="fas fa-file-export" />
        </td>
        <td>
          <TrButton name="formula" item={item} title="Duplicate" onclick={duplicateFormula} icon="fas fa-copy" />
        </td>
        {isAdmin && state.formulas.includeArchived && (<td>
          {item.archive && (<TrButton name="formula" item={item} title="Restore" onclick={actions.formulas.restoreFormula} icon="fas fa-trash-restore" />)}
        </td>)}
      </Tr>)
      }
      renderButtons={() => (<Tr>
        {isAdmin && (<label class={'button ' + (state.formulas.includeArchived ? ' is-dark' : '')}>
          {state.formulas.includeArchived ? (<input type="checkbox" onchange={toggleIncludeArchived} checked="checked" style={{display:'none'}} />) :
            (<input type="checkbox" onchange={toggleIncludeArchived} style={{display:'none'}} />)}
          {(state.formulas.includeArchived ? 'Hide' : 'Show') + ' archived'}
        </label>)}
        <button class="button is-primary" onclick={importFormulas}>Import</button>
        {!!state.formulas.items.length && (<button class="button is-info" onclick={exportFormulasClick}>Export</button>)}
      </Tr>)} />
    <Modal {...state.formulas.modal} />
  </div>
  )
}
