import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

import { validateButton, validateEditor } from '../../modules/utils';

import { validate } from '../../modules/currency';
import { Loading } from './loading';
import { CheckBox, FloatField, RequiredField, StringField, MetaData } from './common';
import { EditorButtons } from './editor-buttons';

export const CurrencyEditor = ({ state, actions, id }) => {

  const getData = () => {
    actions.getCurrency(id);
  }

  const setValue = (prop, value) => {
    actions.setState({
      [prop]: value,
      modified: true
    });
  }

  const validateSaveButton = (el) => {
    validateButton(el, validate(state))
  }

  const save = (e, exit, empty) => {
    if (state.modified) {
      const err = validate(state);
      validateEditor(e.target.closest('.currency-editor'));
      if (err) {
        actions.setState({ error: err });
      } else {
        actions.saveCurrency({ exit, empty });
      }
    }
  }

  const saveAndExit = (e) => {
    save(e, true);
  }

  const saveAndNew = (e) => {
    save(e, false, true);
  }

  const onChange = (prop) => ({ target }) => {
    setValue(prop, target.value);
  }

  return (
    <div class="currency-editor" oncreate={getData} >
      {state.loaded ? (<div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Name</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Name" value={state.name} onchange={onChange('name')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Code</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Code" value={state.code} onchange={onChange('code')} className="is-expanded" nowhitespace={true} toUpperCase={true} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Symbol</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Symbol" value={state.symbol} onchange={onChange('symbol')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Plural Name</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Plural Name" value={state.pluralName} onchange={onChange('pluralName')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Code Alias</label>
          </div>
          <div class="field-body">
            <StringField placeholder="Code Alias" value={state.codeAlias} onchange={onChange('codeAlias')} className="is-expanded" nowhitespace={true} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Base Conversion Rate</label>
          </div>
          <div class="field-body">
            <FloatField placeholder="Base Conversion Rate" value={state.baseConversionRate} onchange={onChange('baseConversionRate')} className="is-expanded" required={true} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">String Pattern</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="String Pattern" value={state.stringPattern} onchange={onChange('stringPattern')} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_enabled">Enabled</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_enabled" active={state.enabled} toggle={value => setValue('enabled', !!value)} />
          </div>
        </div>
        <MetaData {...state} />
        <EditorButtons clear={actions.getCurrency} cancelTo="currencies" save={save} validateSave={validateSaveButton} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!state.error} />
      </div>) : (<Loading />)}
      {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
    </div>)
}
