import { h } from 'hyperapp'
import { setPosition, capitalize } from '../../modules/utils'
import { Keys } from '../../modules/constants'

export default ({ text, items, value, onchange, valueField, displayField, orderField, disabled, required, className }) => {
  const hasValue = (typeof value != 'undefined')

  const oncreate = (e) => {
    if (!text) {
      text = ""
    }
    if (hasValue) {
      const selected = e.querySelector(`.dropdown-item[data-value="${value}"]`);
      if (selected) {
        setActive(selected)
      }
    }
  }

  const onupdate = (e) => {
    if (hasValue) {
      const selected = e.querySelector(`.dropdown-item[data-value="${value}"]`);
      if (selected) {
        setActive(selected)
      }
    } else {
      const active = e.querySelector('.dropdown-item.is-active')
      if (active) {
        active.classList.remove('is-active');
      }
      e.querySelector('.text').innerText = text || '';
    }
  }

  const toggle = (e) => {
    const dropdown = e.currentTarget.closest('.dropdown');
    dropdown.classList.toggle('is-active');
    if (dropdown.classList.contains('is-active')) {
      dropdown.querySelector('span.dropdown-item input').removeAttribute('tabindex');
      const dm = dropdown.querySelector('.dropdown-menu');
      dm.classList.add('is-invisible');
      // setPosition(dm);
      dm.classList.remove('is-invisible');
    } else {
      const input = dropdown.querySelector('span.dropdown-item input')
      input.setAttribute('tabindex', '-1')
      input.value = ""
      dropdown.querySelectorAll('a.dropdown-item').forEach(e => e.classList.remove('collapsed'))
      const dm = dropdown.querySelector('.dropdown-menu')
      dm.removeAttribute("style")
    }
  }

  const setActive = (selected) => {
    const active = selected.closest('.dropdown').querySelector('a.dropdown-item.is-active')
    if (active) active.classList.remove('is-active')
    selected.classList.add('is-active')
    const text = selected.closest('.dropdown').querySelector('.text')
    const value = selected.dataset.value || selected.innerText
    text.innerText = selected.innerText
    return value
  }

  const selectClick = (e) => {
    const selected = e.currentTarget
    select(selected)
    toggle(e)
  }

  const select = (selected) => {
    const v = setActive(selected)
    if (v != value) {
      onchange(v)
    }
  }

  let search = "";
  var serachTimeout;
  const setSearch = (key) => {
    search += key
    if (serachTimeout) {
      clearTimeout(serachTimeout);
    }
    serachTimeout = setTimeout(() => {
      search = "";
    }, 2000);
  }
  const onkeyup = (e) => {
    const keyCode = e.keyCode || e.which;
    const open = e.currentTarget.closest('.dropdown').classList.contains('is-active');
    if (!open && keyCode != Keys.DOWN && keyCode != Keys.ENTER) return;
    e.preventDefault();
    e.stopPropagation();

    if (keyCode == Keys.ESCAPE || keyCode == Keys.TAB) {
      if (open) {
        toggle(e);
      }
      return;
    }
    const parent = e.target.parentElement.nextElementSibling.firstElementChild;
    const active = parent.querySelector('a.dropdown-item.is-active');
    if (keyCode == Keys.ENTER) {
      if (open && active) {
        select(active);
      }
      toggle(e);
    } else if (keyCode == Keys.UP) {
      if (!!active && !!active.previousElementSibling && active.previousElementSibling.nodeName == "A") {
        select(active.previousElementSibling)
      } else {
        select(parent.lastElementChild)
      }
    } else if (keyCode == Keys.DOWN) {
      if (!open) {
        toggle(e)
      } else if (!!active && !!active.nextElementSibling) {
        select(active.nextElementSibling)
      } else {
        select(parent.firstElementChild.nextElementSibling)
      }
    } else if (keyCode <= 90 && keyCode >= 48) {
      setSearch(e.key)
      text = search.toLowerCase();
      const items = parent.querySelectorAll('a.dropdown-item');
      for (var i = 0, j = items.length; i < j; i++) {
        const disp = items[i].innerText;
        if (disp.toLowerCase().startsWith(text)) {
          select(items[i])
          return
        }
      }
    }
    return false;
  }

  const filter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const search = new RegExp(`.*${e.currentTarget.value}.*`, 'i');
    const items = e.currentTarget.closest('.dropdown').querySelectorAll('a.dropdown-item');
    for (var i = 0, j = items.length; i < j; i++) {
      const disp = items[i].innerText;
      if (!search.test(disp)) {
        items[i].classList.add('collapsed')
      } else {
        items[i].classList.remove('collapsed')
      }
    }
  }

  const isObject = typeof (items || []) == 'object' // object and array
  displayField = (displayField || isObject && 'label') // default to display label for array and object
  valueField = (valueField || isObject && 'id') // default key to id for array and object

  const sortItems = (items) => {
    return items.sort((a, b) => {
      const da = orderField && a[orderField] !== "" && typeof a[orderField] !== 'undefined' ? a[orderField] : displayField && a[displayField] !== "" && typeof a[displayField] !== 'undefined' ? a[displayField] : (valueField && typeof a[valueField] !== 'undefined' ? a[valueField] : a);
      const db = orderField && b[orderField] !== "" && typeof b[orderField] !== 'undefined' ? b[orderField] : displayField && b[displayField] !== "" && typeof b[displayField] !== 'undefined' ? b[displayField] : (valueField && typeof b[valueField] !== 'undefined' ? b[valueField] : b);
      return da > db ? 1 : -1;
    })
  }

  let dropdownItems;
  let keys;
  if (Array.isArray(items || [])) {
    dropdownItems = sortItems(Array.prototype.slice.call(items || [], 0))
  } else {
    keys = Object.keys(items);
    displayField = (displayField || 'label') // default to display label for array and object
    valueField = (valueField || 'id') // default key to id for array and object
    dropdownItems = sortItems(keys).map(k => {
      if (Array.isArray(items[k])) return { group: capitalize(k), items: sortItems(items[k]), _dropdowngroup: true };
      else if (typeof items[k] == 'object') return { group: capitalize(k), items: sortItems(Object.keys(items[k])).map(ik => ({ id: ik, label: items[k][ik] })), _dropdowngroup: true };
      else return { id: k, label: items[k] };
    });
  }

  return (
    <div class={'dropdown' + (className ? ` ${className}` : '')} oncreate={oncreate} onupdate={onupdate}>
      <div class="dropdown-trigger">
        <DropdownButton text={text} toggle={toggle} disabled={disabled} onkeyup={onkeyup} />
      </div>
      <div class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <span class="dropdown-item"><input class="input" type="text" placeholder="Search..." onkeyup={filter} /></span>
          <hr class="dropdown-divider" />
          {dropdownItems.map((e, i) => {
            if (e._dropdowngroup) {
              return e.items.length ? (<div>
                {keys ? (<div>
                  {i > 0 && (<hr class="dropdown-divider" />)}
                  <div class="dropdown-item menu-label dropdown-group"><p>{e.group}</p></div>
                  <hr class="dropdown-divider" />
                </div>) : null}
                {(e.items || []).map((el, idx) => (<DropDownItem item={el} index={idx} valueField={valueField} displayField={displayField} selectClick={selectClick} />))}
              </div>) : null;
            }
            return (<DropDownItem item={e} index={i} valueField={valueField} displayField={displayField} selectClick={selectClick} />)
          }
          )}
        </div>
      </div>
    </div>
  )
}

const DropdownButton = ({ text, toggle, disabled, onkeyup }) => {
  const onkeydown = (e) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode == Keys.ENTER) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  if (disabled) {
    return (<button class="button is-fullwidth" disabled>
      <span class="text">{text || ''}</span>
    </button>)
  }
  return (<button class="button is-fullwidth" onclick={toggle} onkeyup={onkeyup} onkeydown={onkeydown}>
    <span class="text">{text || ''}</span>
    <i class="caret"></i>
  </button>)
}

const DropDownItem = ({ item, index, valueField, displayField, selectClick }) => {
  const value = valueField && typeof item[valueField] !== 'undefined' ? item[valueField] : item;
  const display = displayField && item[displayField] !== '' && typeof item[displayField] !== 'undefined' ? item[displayField] : value;
  return (
    <a key={index} class="dropdown-item" onclick={selectClick} data-value={value}>
      {display}
    </a>
  )
}
