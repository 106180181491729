import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

export const EditorButtons = ({
  clear, clearText = "Clear", clearIcon = "fas fa-eraser",
  cancelTo = "", cancelText = "Cancel", cancelIcon = "fas fa-times",
  save, saveText = "Save", saveIcon = "fas fa-save",
  saveAndNew, saveAndNewText = "Save & New", saveAndNewIcon = "fas fa-sync-alt",
  saveAndExit, saveAndExitText = "Save & Exit", saveAndExitIcon = "fas fa-external-link-square-alt",
  validateSave, saveDisabled }, children) => {

  const onSaveClick = (method) => (e) => {
    if (e.currentTarget.classList.contains('disabled')) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    method(e)
  }
  const onSaveUpdate = (e) => {
    if (!saveDisabled && validateSave) validateSave(e)
  }
  return (<div class="is-clearfix">
    <br />
    <div class="field is-grouped is-pulled-left">
      {clear && (<p class="control">
        <button class="button" onclick={clear}>
          <span class="icon">
            <i class={clearIcon}></i>
          </span>
          <span>{clearText}</span>
        </button>
      </p>)}
      {children}
    </div>
    <div class="field is-grouped is-pulled-right">
      <p class="control">
        <Link to={"/" + cancelTo}>
          <button class="button">
            <span class="icon">
              <i class={cancelIcon}></i>
            </span>
            <span>{cancelText}</span>
          </button>
        </Link>
      </p>
      {save && (<p class="control">
        <button class={"button is-danger" + (saveDisabled ? " disabled" : "")} onclick={onSaveClick(save)} onupdate={onSaveUpdate}>
          <span class="icon">
            <i class={saveIcon}></i>
          </span>
          <span>{saveText}</span>
        </button>
      </p>)}
      {saveAndNew && (<p class="control">
        <button class={"button is-danger" + (saveDisabled ? " disabled" : "")} onclick={onSaveClick(saveAndNew)} onupdate={onSaveUpdate}>
          <span class="icon">
            <i class={saveAndNewIcon}></i>
          </span>
          <span>{saveAndNewText}</span>
        </button>
      </p>)}
      {saveAndExit && (<p class="control">
        <button class={"button is-danger" + (saveDisabled ? " disabled" : "")} onclick={onSaveClick(saveAndExit)} onupdate={onSaveUpdate}>
          <span class="icon">
            <i class={saveAndExitIcon}></i>
          </span>
          <span>{saveAndExitText}</span>
        </button>
      </p>)}
    </div>
  </div>)
}
