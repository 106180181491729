import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'

import '../css/notfound.css'

export const UnAuthorized = () => (
  <section class="hero is-fullheight-with-navbar error-page" key="403_view">
    <div class="notfound">
      <div class="restricted-403">
        <h1><span>O</span><span>o</span><span>p</span><span>s</span><span>!</span></h1>
      </div>
      <h2>These are not the droids you are looking for...</h2>
      <Link class="button is-primary" to="/">Home</Link>
    </div>
  </section>
)
