import { parseDate } from './utils'
import { validate } from './rule'
import { Items } from './classes'

export const mapRuleItems = (datasources, actionMethods) => i => {
  const validFrom = parseDate(i.validFrom)
  const validTo = parseDate(i.validTo)
  const isScheduled = !!validFrom || !!validTo;
  let isInPeriod = false;
  if (isScheduled) {
    const now = new Date();
    isInPeriod = ((!validFrom || validFrom <= now) && (!validTo || now <= validTo));
  }
  const r = {
    isScheduled,
    isInPeriod
  }
  var err = validate(i, { datasources, actionMethods });
  if (err) {
    return Object.assign(r, {
      error: err
    }, i);
  }
  return Object.assign(r, {
    canActivate: true
  }, i);
}

export class Rules extends Items {
  constructor() {
    super('title');
    this.events = null;
    this.modal = null;
  }
}
