import { h } from 'hyperapp'
import { getFilterPath } from '../modules/utils'
import { Tr, TrTagsCell, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Users = ({ state, actions }) => {
  const { filter, order } = state.users;

  const toggleRoles = (roles) => {
    actions.location.go(getFilterPath('roles', roles));
    actions.users.filterUsers({ roles });
  }
  const deleteClick = ({ id, email }) => {
    const modal = {
      data: id,
      label: email,
      title: `Delete user`,
      content: (`Are you sure you want to delete ${email}?`),
      onConfirm: (id) => actions.users.deleteUser(id),
      open: true,
      toggle: actions.users.toggleModal
    }
    actions.users.toggleModal(modal);
  }

  return (<div>
    <ListView name="user" columns="5" protectedField="protected" getItems={actions.users.getUsers} goToPage={actions.users.goToPage} onDelete={deleteClick} {...state.users}
      renderHead={() => (<Tr>
        <th></th>
        <th>Display Name</th>
        <Th name="Email" field="email" order={order} actions={actions.users} route={actions.location} />
        <th>Slack Name</th>
        <th>Role(s)</th>
      </Tr>)}
      renderBody={item => (<Tr>
        <td class="avatar">{item.avatarUrl ? <img src={item.avatarUrl} /> : <span class="icon"><i class="fas fa-user"></i></span>}</td>
        <td>{item.displayName}</td>
        <td>{item.email}</td>
        <td>{item.slackName}</td>
        <td>
          <TrTagsCell tags={item.roles} filter={filter && filter.roles} onchange={toggleRoles} />
        </td>
      </Tr>)
      } />
    <Modal {...state.users.modal} />
  </div>
  )
}
