import { h } from 'hyperapp'
import { TaskList } from './components/task-list'

export const Tasks = ({ state, actions, match }) => {
  return (
    <section class="section" key="schedule_view">
      <h1 class="title">
        Tasks
      </h1>
      <hr />
      <TaskList state={state.tasks} actions={actions.tasks} queue={match && match.params.queue} route={actions.location} />
    </section>
  )
}
