import { Items } from './classes'
import { Entry } from './classes'

export class Countries extends Items {
    constructor() {
        super('name');
        this.modal = null;
    }
}

export class Country extends Entry {
    constructor(props) {
        super(props)
    }
}

export const validate = (p) => {
    return !p.code && 'Code is mandatory' ||
      !p.name && 'Name is mandatory' ||
      !p.alphaCode3 && 'Alpha Code is mandatory' ||
      p.alphaCode3.length != 3 && 'Alpha Code should be 3 character' ||
      !p.phonePrefix && 'Phone Prefix is mandatory' ||
      !p.legalAge && 'Legal Age is mandatory'
  }