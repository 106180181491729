import { h } from 'hyperapp'
import '../../css/notification.css'

export const Notification = ({ state, actions }) => {
    var timeout;
    const onupdate = () => {
        if (state.open && state.autoClose && !timeout) {
            timeout = setTimeout(actions.close, state.timeout || 3000);
        } else if (!state.open) {
            if (!!timeout) {
                clearTimeout(timeout);
            }
            setTimeout(actions.clear, 1000);
        }
    }
    return state.content ? (<div class={'notification notify' + (state.level ? (' is-' + state.level) : '') + (state.open ? ' open' : '')} onupdate={onupdate}>{state.content}<button class="delete" type="button" onclick={actions.close}>Close</button></div>) : null;
}
