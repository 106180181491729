import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

import { dateToLocaleString, untagify, validateButton, validateEditor } from '../../modules/utils';

import { validate } from '../../modules/user';
import { Loading } from './loading';
import { RequiredField, EmailField, TagsInput, StringField } from './common';
import { EditorButtons } from './editor-buttons';

export const UserEditor = ({ state, actions, id }) => {

  const getData = () => {
    actions.getUser(id);
  }

  const setValue = (prop, value) => {
    actions.setState({
      [prop]: value,
      modified: true
    });
  }

  const validateSaveButton = (el) => {
    validateButton(el, validate(state))
  }

  const save = (e) => {
    if (state.modified) {
      const err = validate(state);
      validateEditor(e.target.closest('.user-editor'));
      if (err) {
        actions.setState({ error: err });
      } else {
        actions.saveUser();
      }
    }
  }

  const onChange = (prop) => ({ target }) => {
    setValue(prop, target.value);
  }

  const onRolesChange = (roles) => {
    setValue('roles', roles.split(','));
  }

  return (
    <div class="user-editor" oncreate={getData} >
      {state.loaded ? (<div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Display Name</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Display Name" value={state.displayName} onchange={onChange('displayName')} className="is-expanded" disabled={state.protected} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Email</label>
          </div>
          <div class="field-body">
            <EmailField placeholder="Email" value={state.email} onchange={onChange('email')} className="is-expanded" disabled={state.protected} required={true} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Avatar URL</label>
          </div>
          <div class="field-body">
            <StringField placeholder="Avatar URL" value={state.avatarUrl} onchange={onChange('avatarUrl')} className="is-expanded" disabled={state.protected} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Slack Name</label>
          </div>
          <div class="field-body">
            <StringField placeholder="Slack Name" value={state.slackName} onchange={onChange('slackName')} className="is-expanded" disabled={state.protected} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Role(s)</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control">
                <TagsInput placeholder="Role(s)" value={untagify((state.roles || []).join(','))} onchange={onRolesChange} className="is-expanded" disabled={state.protected} datasource={state.rolesAvailable} />
              </p>
            </div>
          </div>
        </div>
        {id && (<div>
          <div class="columns" style={{width:'100%'}}>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Last Login</label>
                </div>
                <div class="field-body">
                  <input type="text" placeholder="Last Login" value={dateToLocaleString(state.lastLogin) || 'never'} class="input is-expanded" disabled="disabled" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Provider(s)</label>
                </div>
                <div class="field-body">
                  <input placeholder="Providers" value={state.providers} class="input is-expanded" disabled="disabled" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns" style={{width:'100%'}}>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Created</label>
                </div>
                <div class="field-body">
                  <input type="text" placeholder="Created" value={dateToLocaleString(state.created)} class="input is-expanded" disabled="disabled" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Created By</label>
                </div>
                <div class="field-body">
                  <input placeholder="Created By" value={state.createdBy} class="input is-expanded" disabled="disabled" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns" style={{width:'100%'}}>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Updated</label>
                </div>
                <div class="field-body">
                  <input type="text" placeholder="Updated" value={dateToLocaleString(state.updated)} class="input is-expanded" disabled="disabled" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Updated By</label>
                </div>
                <div class="field-body">
                  <input placeholder="Updated By" value={state.updatedBy} class="input is-expanded" disabled="disabled" />
                </div>
              </div>
            </div>
          </div>
        </div>)}
        <EditorButtons clear={!state.protected && actions.getUser} cancelTo="users" save={!state.protected && save} validateSave={validateSaveButton} saveDisabled={!state.modified || !!state.error} />
      </div>) : (<Loading />)}
      {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
    </div>)
}
