import { h } from 'hyperapp'

import '../../css/snackbar.css'

export const Snackbar = ({ state, actions }) => {
  var timeout;
  const onupdate = () => {
    if (state.open && !timeout) {
      timeout = setTimeout(actions.close, state.timeout || 5000)
    } else if (!state.open && timeout) {
      clearTimeout(timeout);
      setTimeout(actions.clear, 1000)
    }
  }
  return (<div class={'snackbar' + (state.open ? ' open' : '')} onupdate={onupdate}>{state.content}</div>)
}
