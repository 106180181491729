import { Entry } from './classes'
import { messageBroker } from './messagebroker'

export class Trigger extends Entry {
  constructor() {
    super();
    this.file = '';
    this.columns = [];
    this.rows = [];
    this.action = {};
    this.schedule = '_now';
    this.dateTime = '';
    this.params = {};
    this.paramValues = {};
    this.nopublish = true;
    this.loading = false;
    this.idColumn = '';

    // messageBroker.on("trigger.complete", (msg) => {
    //   messageBroker.emit('notify', msg)
    // })
  }
}
