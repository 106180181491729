import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'

export const currencies = {
  setState: (state) => (state),
  getCurrencies: (params) => async (state, actions) => {
    var { filter, order, skip, take, enabled, defaultOrder } = state;
    const req = {};

    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.enabled) !== 'undefined') {
      if (params.enabled)
        req.enabled = true;
    } else if (enabled) {
      req.enabled = true;
    }

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      for (var k in filter) {
        if (filter[k] !== "" && filter[k] !== undefined) {
          req[k] = filter[k];
        }
      }
    }

    const res = await client.getCurrencies(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.loaded = true;
    res.enabled = req.enabled;
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    return actions.setState(res);
  },
  deleteCurrency: (id) => async (state, actions) => {
    const res = await client.deleteCurrency(id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const items = state.items.filter(i => id === i.id)
    return actions.setState({ items });
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getCurrencies({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getCurrencies({ order });
  },
  setFilter: (filter) => async (state, actions) => {
    return actions.getCurrencies({ filter });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  exportCurrencies: (r) => async (state, actions) => {
    const currencies = await client.exportCurrencies(r && r.id);
    return currencies.items;
  },
  importCurrencies: (data) => async (state, actions) => {
    const res = await client.importCurrencies(data);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getCurrencies();
  },
  save: (c) => async (state, actions) => {
    let items = state.items.map(i => i.id === c.id ? Object.assign({}, i, { loading: true }) : i);
    actions.setState({ items });
    const res = await client.saveCurrency(c);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    items = state.items.map(i => i.id === c.id ? Object.assign({}, i, c) : i);
    return actions.setState({ items });
  }
}
