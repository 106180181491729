import { Items } from './classes'
import { Entry } from './classes'

export class Currencies extends Items {
    constructor() {
        super('name');
        this.modal = null;
    }
}
export class Currency extends Entry {
    constructor(props) {
        super(props)
    }
}

export const currencyFromState = (s) => {
    return Object.assign({}, s, { baseConversionRate: Number(s.baseConversionRate) });
}

export const validate = (p) => {
    return !p.code && 'Code is mandatory' ||
        !p.name && 'Name is mandatory' ||
        !p.symbol && 'Symbol is mandatory' ||
        !p.pluralName && 'Plural Name is mandatory' ||
        !p.baseConversionRate && 'Base Conversion Rate is mandatory' ||
        !Number(p.baseConversionRate) && 'Base Conversion Rate is invalid' ||
        !p.stringPattern && 'String Pattern is mandatory' ||
        p.stringPattern.indexOf('#AMOUNT') < 0 && 'String Pattern has to contain #AMOUNT' ||
        p.stringPattern.indexOf('#SYMBOL') < 0 && 'String Pattern has to contain #SYMBOL';
}