import { h } from 'hyperapp'
import Dropdown from './dropdown';

export const ParamSelector = ({ TypeField, typeValues, isDynamic, value, onchange, paramLabel, valueField, displayField, orderField, loading, ...rest }) => {
  const hasValues = typeValues && !!Object.keys(typeValues).length;
  let isSelected = false;
  let fieldClass = "param-selector field"
  let controlClass = "control"
  if (TypeField && hasValues) fieldClass += " has-values";
  if (isDynamic) {
    fieldClass += " is-dynamic";
    isSelected = true;
  } else if (value && hasValues) {
    for (let k of Object.values(typeValues)) {
      if (Array.isArray(k)) {
        for (let v of k) {
          if ((v[valueField] || v) == value) {
            fieldClass += " is-dynamic";
            isSelected = true;
          }
        }
      } else if (typeof k == 'object') {
        for (let o in k) {
          if ((o[valueField] || o) == value) {
            fieldClass += " is-dynamic";
            isSelected = true;
          }
        }
      } else if ((k[valueField] || k) == value) {
        fieldClass += " is-dynamic";
        isSelected = true;
      }
    }
  }
  if (loading) controlClass += " is-loading"

  const onselect = (value) => {
    let dynamic = false;
    let found = false;
    if (hasValues)
      for (let k of Object.values(typeValues)) {
        if (Array.isArray(k)) {
          for (let v of k) {
            if ((v[valueField] || v) == value) {
              found = true;
              dynamic = v.isDynamic;
              break;
            }
          }
        } else if (typeof k == 'object') {
          for (let o in k) {
            if ((o[valueField] || o) == value) {
              found = true;
              dynamic = o.isDynamic;
              break;
            }
          }
        } else if ((k[valueField] || k) == value) {
          found = true;
          dynamic = k.isDynamic;
        }
        if (found) break;
      }
    onchange(value, dynamic);
  }

  return (<div class={fieldClass}>
    {TypeField && (<p class="control">
      {TypeField({ value: value, onchange: ({ target: { value } }) => onchange(value), ...rest, isDynamic: isDynamic, onclick: () => (isSelected ? onchange("") : null) })}
    </p>)}
    {(hasValues || loading) && (<p class={controlClass}>
      <Dropdown text={` --- select ${paramLabel} --- `} items={typeValues} onchange={(value) => onselect(value)} valueField={valueField} displayField={displayField} orderField={orderField} value={(isSelected ? value : undefined)} loading={loading} />
    </p>)}
  </div>)
}
