import { getParam, getFilterParam } from './utils'

export class Entry {
  constructor() {
    this.loaded = false;
    this.error = '';
  }
}

export class Items extends Entry {
  constructor(order = '') {
    super();
    this.items = [];
    this.count = 0;
    this.skip = getParam('skip') || 0;
    this.take = getParam('take') || 10;
    this.order = getParam('order') || order;
    this.filter = getFilterParam();
    this.loaded = false;
    this.error = '';
    this.defaultOrder = order;
  }
}
