import { h } from 'hyperapp'
import { Keys } from '../../modules/constants';
import { validateButton } from '../../modules/utils';

export default ({ data, title, open = false, toggle, confirmText = "OK", cancelText = "Close", onConfirm, onCancel, onValidate, content, buttons, footer, alwaysOpen = false }, children) => {

  if (!open) return null;

  const close = () => {
    if (toggle)
      toggle();
  }
  const onInput = (e) => {
    if (alwaysOpen) return false;
    if (!e) e = window.event;
    var keyCode = e.keyCode || e.which;
    if (keyCode == Keys.ESCAPE) {
      cancelClick();
    } else if (keyCode == Keys.ENTER) {
      confirmClick();
    }
  }
  const confirmClick = () => {
    if (onConfirm) onConfirm(data);
    close();
  }
  const cancelClick = () => {
    if (onCancel) onCancel(data);
    close();
  }
  const validateConfim = (el) => {
    if (onValidate) {
      validateButton(el, onValidate(el))
    }
  }
  const oncreate = () => {
    document.addEventListener('keyup', onInput)
    document.documentElement.classList.add('is-clipped')
  }

  const ondestroy = () => {
    document.removeEventListener('keyup', onInput)
    document.documentElement.classList.remove('is-clipped')
  }

  return (<div class="modal is-active" oncreate={oncreate} ondestroy={ondestroy} >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        {title ? <p class="modal-card-title">{title}</p> : null}
        {!alwaysOpen && <button class="delete" aria-label="close" onclick={cancelClick}></button>}
      </header>
      {children && children.length || content ? <section class="modal-card-body">
        {children}
        {content}
      </section> : null}
      <footer class="modal-card-foot">
        {onConfirm ? <button class="button is-success" onclick={confirmClick} onupdate={validateConfim}>{confirmText}</button> : null}
        {!alwaysOpen && <button class="button" onclick={cancelClick}>{cancelText}</button>}
        {buttons && buttons.map(btn => (<button class={"button " + btn.class} onclick={btn.onclick}>{btn.text}</button>))}
        {footer}
      </footer>
    </div>
  </div>);
}
