import { h } from 'hyperapp';
import { Loading } from './loading';
import { ConfirmDelete, Pagination, TrButton } from './common'

import '../../css/items-list.css'

export const ItemsList = ({ columns, className, name, loaded, error, items = [], count, skip, take, goToPage, onDelete, renderHead, renderBody, confirmModal, protectedField, disabled }) => {
  if (!loaded) return (<Loading />);

  const rowClick = item => e => {
    e.preventDefault()
    e.stopPropagation()
    if (!disabled)
      history.pushState(location.pathname, '', `${location.pathname}/${item.id}`)
  }

  const hasItems = !!(items && items.length)

  return (<div>
    <div class={className}>
      <table class={"table is-narrow is-fullwidth is-hoverable item-list" + (disabled ? ' is-disabled' : '')}>
        <thead>
          <tr>
            {renderHead()}
            <th class="th-btn"></th>
          </tr>
        </thead>
        {hasItems && (<tfoot>
          <tr>
            <th colspan={parseInt(columns) + (disabled ? 0 : 1)} class="has-text-right">
              <Pagination count={count} skip={skip} take={take} goToPage={goToPage} />
            </th>
          </tr>
        </tfoot>)}
        {hasItems && (<tbody>
          {items.map(item => {
            return (<tr key={item.id} onclick={rowClick(item)} class={item.loading ? 'is-disabled' : ''}>
              {renderBody(item)}
              <td>
                {(!protectedField || !item[protectedField]) && (<TrButton name={name} item={item} onclick={onDelete} title="Remove" icon="fas fa-trash" />)}
              </td>
            </tr>)
          })}
        </tbody>)}
      </table>
      {!hasItems && (<div class="message">
        <div class="message-body">
          There are no items in the collection
        </div>
      </div>)}
      < ConfirmDelete {...confirmModal} />
    </div>
    {error && (<div class="notification">{error}</div>)}
  </div>)
}
