import { client } from '../modules/client'
import { Siteconfig, validate } from "../modules/siteconfig";

export const siteconfig = {
  setState: (state) => (state),
  getCountries: () => async (state, actions) => {
    const res = await client.getAllCountries();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({
      countries: res.items
    });
  },
  getTypes: () => async (state, actions) => {
    const res = await client.getSiteconfigTypes();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({
      types: res.items
    });
  },
  getSiteconfig: (id) => async (state, actions) => {
    actions.setState(new Siteconfig());
    if (!state.countries) {
      await actions.getCountries();
    }
    if (!state.types) {
      await actions.getTypes();
    }
    if (id) {
      var res = await client.getSiteconfig(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.item) {
        const scfg = new Siteconfig(res.item);
        const err = validate(scfg);
        return actions.setState(Object.assign(scfg, { error: err, loaded: true }));
      }
      return actions.setState({
        error: "Siteconfig not found",
        loaded: true
      })
    }
    return actions.setState((Object.assign(new Siteconfig(state.clone), { loaded: true })));
  },
  saveSiteconfig: (onDone) => async (state, actions) => {
    const err = validate(state);
    if (!!err) {
      return actions.setState({
        error: err
      })
    }
    const res = await client.saveSiteconfig(state);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      })
    }
    if (onDone) {
      if (onDone.exit) {
        history.pushState(location.pathname, '', '/siteconfigs')
        return actions.setState(new Siteconfig());
      } else if (onDone.empty) {
        history.pushState(location.pathname, '', '/siteconfigs/new')
        return actions.setState(new Siteconfig({ loaded: true }));
      } else {
        if (location.pathname == '/siteconfigs/new' && res.items.length) {
          actions.setState({ id: res.items[0].id });
          history.pushState(location.pathname, '', '/siteconfigs/' + res.items[0].id)
        }
        return actions.setState({ modified: false });
      }
    }
  }
}
