import { h } from 'hyperapp';
import { Link } from '@hyperapp/router'

import { validateButton, validateEditor } from '../../modules/utils';

import { validate } from '../../modules/siteconfig';
import { Loading } from './loading';
import { CheckBox, RequiredField, JsonField, Dropdown, MetaData } from './common';
import { EditorButtons } from './editor-buttons';

export const SiteconfigEditor = ({ state, actions, id }) => {
  const configJson = state.config ? JSON.stringify(state.config, null, 2) : '';
  const isNew = !id;

  const getData = () => {
    actions.getSiteconfig(id);
  }

  const setValue = (prop, value) => {
    actions.setState({
      [prop]: value,
      modified: true
    });
  }

  const validateSaveButton = (el) => {
    validateButton(el, validate(state))
  }

  const save = (e, exit, empty) => {
    if (state.modified) {
      const err = validate(state);
      validateEditor(e.target.closest('.siteconfig-editor'));
      if (err) {
        actions.setState({ error: err });
      } else {
        actions.saveSiteconfig({ exit, empty });
      }
    }
  }

  const saveAndExit = (e) => {
    save(e, true);
  }

  const saveAndNew = (e) => {
    save(e, false, true);
  }

  const onChange = (prop) => ({ target }) => {
    setValue(prop, target.value);
  }

  const onSelect = (prop) => (value) => {
    setValue(prop, value);
  }

  const setJson = ({ target }, isvalid) => {
    if (isvalid) {
      setValue('config', JSON.parse(target.value));
    }
  }

  return (
    <div class="siteconfig-editor" oncreate={getData} >
      {state.loaded ? (<div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Name</label>
          </div>
          <div class="field-body">
            <RequiredField placeholder="Name" value={state.name} onchange={onChange('name')} className="is-expanded" disabled={!!state.isDefault} />
          </div>
        </div>
        {!state.isDefault && (<div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Country</label>
          </div>
          <div class="field-body">
            <Dropdown text=" --- select an country --- " items={state.countries} onchange={onSelect('country')} value={state.country} valueField="code" displayField="name" className="is-expanded" />
          </div>
        </div>)}
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Type</label>
          </div>
          <div class="field-body">
            <Dropdown text=" --- select an type --- " items={state.types} onchange={onSelect('type')} value={state.type} className="is-expanded" valueField="key" displayField="value" disabled={!!state.isDefault} />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Config</label>
          </div>
          <div class="field-body">
            <JsonField placeholder="Config" value={configJson} onchange={setJson} className="is-expanded" />
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label" for="is_enabled">Enabled</label>
          </div>
          <div class="field-body">
            <CheckBox id="is_enabled" active={state.enabled} toggle={value => setValue('enabled', !!value)} disabled={!!state.isDefault} />
          </div>
        </div>
        {!state.isDefault && !isNew && (<MetaData {...state} />)}
        <EditorButtons clear={actions.getSitemapconfig} cancelTo="siteconfigs" save={save} validateSave={validateSaveButton} saveAndExit={saveAndExit} saveAndNew={saveAndNew} saveDisabled={!state.modified || !!state.error} />
      </div>) : (<Loading />)}
      {state.error ? (<div class="notification is-danger">{state.error}</div>) : null}
    </div>)
}
