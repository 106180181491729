import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'

export const games = {
  setState: (state) => (state),
  getGames: (params) => async (state, actions) => {
    var { filter, order, skip, take, includeArchived, defaultOrder, showList } = state;
    const req = {};

    if (params && params.order || ((!params || params.order == '') && defaultOrder) || order) {
      req.order = params && params.order || (!params || params.order == '') && defaultOrder || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    if (params && typeof (params.includeArchived) !== 'undefined') {
      if (params.includeArchived)
        req.includeArchived = true;
    } else if (includeArchived) {
      req.includeArchived = true;
    }

    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      for (var k in filter) {
        if (filter[k] !== "" && filter[k] !== undefined) {
          req[k] = filter[k];
        }
      }
    }

    const res = await client.getGames(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    res.loaded = true;
    res.showList = showList;
    res.includeArchived = req.includeArchived;
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {};
    }
    return actions.setState(res);
  },
  deleteGame: (slug) => async (state, actions) => {
    const res = await client.deleteGame({ slug });
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getGames();
  },
  restoreGame: (slug) => async (state, actions) => {
    const res = await client.restoreGame({slug});
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getGames();
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getGames({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getGames({ order });
  },
  setFilter: (filter) => async (state, actions) => {
    return actions.getGames({ filter });
  },
  toggleModal: (modal) => (state) => {
    if (state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleConfirmModal: (confirmModal) => (state) => {
    if (state.confirmModal) return {
      confirmModal: null
    };
    return { confirmModal }
  },
  save: (g) => async (state, actions) => {
    let items = state.items.map(i => i.slug === g.slug ? Object.assign({}, i, { loading: true }) : i);
    actions.setState({ items });
    const res = await client.saveGame(g);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    items = state.items.map(i => i.slug === g.slug ? Object.assign({}, i, g) : i);
    return actions.setState({ items });
  }
}
