import { h } from 'hyperapp';
import { Criteria, Condition, Datasource, connectors, RULES_EXEC_DONE } from '../../modules/rule';
import { Connector, Dropdown, Buttons, Fields, RequiredField } from './common';
import { toHashCode, getParamValues, getLastCondition, slugify } from '../../modules/utils'
import { ConditionItem } from './condition-editor';

const getItemsVariables = (items) => {
  var res = '';
  (items || []).forEach((i, idx) => {
    if (idx > 0) res += i.connector;
    if (i.items && i.items.length) res += getItemsVariables(i.items);
    else if (i.field && i.value) res += ((i.method ? (i.method + '_') : '') + i.field + i.comparer + i.value.toLowerCase());
  })
  return res;
}

const generateAlias = (criteria) => {
  var name = criteria.datasource.key;
  (criteria.parameters || []).forEach(p => {
    if (p && p.value)
      name += ('_' + p.value.toLowerCase());
  })
  if (name === 'count') {
    name = criteria.datasource.id + 'Count'
  }

  var filters = getItemsVariables(criteria.filters || []);
  var aggregations = getItemsVariables(criteria.aggregations || []);
  const hash = filters || aggregations ? ('_' + toHashCode(filters + ':' + aggregations)) : '';
  return slugify(name + hash);
}

export const Criterias = ({ state, actions }) => {
  const event = state.events.find(e => e.id == state.event);
  const last = state.criterias.length && state.criterias[state.criterias.length - 1];
  const canAdd = (state.event != RULES_EXEC_DONE || state.ruleId) && (!last || last.datasource || last.items && last.items.length > 1 && last.items[last.items.length - 1].datasource);
  const paramValues = getParamValues(event, state);

  const criteriaChange = (criteria) => {
    if (criteria.datasource && !criteria.datasource.changed) {
      criteria.datasource.alias = generateAlias(criteria);
    }
    actions.setCriteria(criteria);
  }

  return (<div class="field">
    <div class="control">
      <ul class="criteria_list">
        {state.criterias.filter((c, i) => (!!i || c.datasource.id != 'event' || !c.conditions?.length || c.conditions[0].field != 'ruleId'))
          .map((criteria, idx) => (<CriteriaItem event={event} criteria={criteria} events={state.internals} transactions={state.transactions} onchange={criteriaChange} onremove={actions.removeCriteria} datasources={state.datasources} paramValues={paramValues} paramsData={state.paramsData} fetchData={actions.getParamsData} moveCriteria={state.criterias.length > 1 ? actions.moveCriteria : null} canMoveUp={idx > 0} canMoveDown={idx < state.criterias.length - 1} />))}
      </ul>
    </div>
    {canAdd && (<Buttons addTitle="Add criteria" onAddClick={actions.addCriteria} addIcon="fa-plus" groupTitle="Add criteria group" onGroupClick={actions.addCriteriaGroup} groupIcon="fa-layer-group" hasGroup={true} />)}
  </div>)
}

export const CriteriaItem = ({ event, criteria, onchange, onremove, events, transactions, datasources, paramValues, paramsData, fetchData, moveCriteria, canMoveUp, canMoveDown }) => {
  if (criteria.items && criteria.items.length) {
    return (<CriteriaGroup criteria={criteria} event={event} onchange={onchange} onremove={onremove} events={events} transactions={transactions} datasources={datasources} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} moveCriteria={moveCriteria} canMoveUp={canMoveUp} canMoveDown={canMoveDown} />)
  }

  const setDatasource = (value) => {
    const ds = datasources.find(d => d.id == value);
    if (!criteria.datasource || criteria.datasource.id != value) {
      criteria.datasource = new Datasource(ds);
      if (criteria.datasource) {
        criteria.parameters = [];
        criteria.conditions = [];
        criteria.filters = [];
        criteria.aggregations = [];
      }
      criteria.returns = ds && ds.returns || '';
      onchange(criteria);
    }
  }

  const setAlias = (alias) => {
    criteria.datasource.alias = alias;
    criteria.datasource.changed = true;
    onchange(criteria);
  }

  const onConditionAdd = () => {
    let field = '';
    let type = '';
    if (datasource && (!datasource.props && datasource.returns != "eventType" && datasource.returns != "propType")) {
      field = datasource.key;
      if (!datasource.props) {
        type = datasource.returns;
      }
    }
    const connector = criteria.conditions.length ? connectors.AND : connectors.OR;
    criteria.conditions = criteria.conditions.concat(new Condition({ connector, field, type }))
    onchange(criteria);
  }

  const onConditionGroupAdd = () => {
    const connector = criteria.conditions.length ? connectors.AND : connectors.OR;
    criteria.conditions = criteria.conditions.concat(new Condition({ connector, items: [new Condition({ connector })] }))
    onchange(criteria);
  }

  const onConditionChange = (c) => {
    criteria.conditions = criteria.conditions.map(i => c.id === i.id ? Object.assign({}, i, c) : i);
    onchange(criteria);
  }

  const onConditionRemove = (c) => {
    criteria.conditions = criteria.conditions.filter(i => c.id !== i.id);
    if (criteria.conditions.length) {
      criteria.conditions[0].connector == '||'
    }
    onchange(criteria);
  }

  const onAggregationAdd = () => {
    const connector = criteria.conditions.length ? connectors.AND : connectors.OR;
    criteria.aggregations = criteria.aggregations.concat(new Condition({ connector }))
    onchange(criteria);
  }

  const onAggregationGroupAdd = () => {
    const connector = criteria.conditions.length ? connectors.AND : connectors.OR;
    criteria.aggregations = criteria.aggregations.concat(new Condition({ connector, items: [new Condition({ connector })] }))
    onchange(criteria);
  }

  const onAggregationChange = (c) => {
    criteria.aggregations = criteria.aggregations.map(i => c.id === i.id ? Object.assign({}, i, c) : i);
    onchange(criteria);
  }

  const onAggregationRemove = (c) => {
    criteria.aggregations = criteria.aggregations.filter(i => c.id !== i.id);
    if (criteria.aggregations.length) {
      criteria.aggregations[0].connector == '||'
    }
    onchange(criteria);
  }

  const onFilterAdd = () => {
    criteria.filters = criteria.filters.concat(new Condition())
    onchange(criteria);
  }

  const onFilterGroupAdd = () => {
    criteria.filters = criteria.filters.concat(new Condition({ items: [new Condition()] }))
    onchange(criteria);
  }

  const onFilterChange = (c) => {
    criteria.filters = criteria.filters.map(i => c.id === i.id ? Object.assign({}, i, c) : i);
    onchange(criteria);
  }

  const onFilterRemove = (c) => {
    criteria.filters = criteria.filters.filter(i => c.id !== i.id);
    if (criteria.filters.length) {
      criteria.filters[0].connector == '||'
    }
    onchange(criteria);
  }

  const setConnector = (value) => {
    criteria.connector = value;
    onchange(criteria);
  }

  const setParam = (idx) => (value) => {
    const type = datasource.params[idx].type;
    criteria.parameters[idx] = { value, type };
    onchange(criteria);
  }

  const getDataFields = (ds) => {
    if (!ds) return null;
    if (ds.returns == 'eventType') return event.fields;
    if (ds.returns == 'paramType') {
      if (!criteria.parameters.length) return null;
      const p = criteria.parameters[0];
      if (p.value) {
        var ev;
        if (p.type == 'eventType') {
          ev = events.find(e => e.id === p.value);
        } else if (p.type == 'transactionType') {
          ev = transactions.find(e => e.id === p.value);
        }
        return ev && ev.fields;
      }
      return null;
    }
    return ds.props;
  }

  const datasource = criteria.datasource && datasources.find(d => d.id == criteria.datasource.id);
  const lastCondition = getLastCondition(criteria.conditions);
  const lastAggregate = criteria.aggregations.length && criteria.aggregations[criteria.aggregations.length - 1];
  const hasAllParams = datasource && (!datasource.params || !datasource.params.length || criteria.parameters.length == datasource.params.length);
  const dataFields = getDataFields(datasource);
  const canAddMore = (!!dataFields || datasource.returns) && hasAllParams && (!lastCondition || lastCondition.field && lastCondition.value || lastCondition.field && lastCondition.comparer && (lastCondition.comparer == '!!' || lastCondition.comparer == 'null')) || (hasAllParams && !dataFields && datasource.returns && !criteria.conditions.length);
  const canAddAggregate = !!dataFields && hasAllParams && (!lastAggregate || lastAggregate.method && lastAggregate.field && lastAggregate.value || lastAggregate.items && lastAggregate.items.length > 1 && lastAggregate.items[lastAggregate.items.length - 1].value);
  const lastFilter = criteria.filters.length && criteria.filters[criteria.filters.length - 1];
  const canAddFilter = datasource && datasource.filters && hasAllParams && (!lastFilter || lastFilter.field && lastFilter.value || lastFilter.items && lastFilter.items.length > 1 && lastFilter.items[lastFilter.items.length - 1].value);
  let filters = datasource && datasource.filters ? [] : null;
  if (datasource && datasource.filters) {
    datasource.filters.forEach(f => {
      if (f.id == 'eventData') {
        if (event && event.fields)
          filters = filters.concat(event.fields);
      } else if (f.id == 'paramData') {
        if (criteria.parameters.length) {
          const p = criteria.parameters[0];
          if (p.value && p.type == 'eventType') {
            const ev = events.find(e => e.id === p.value);
            if (ev) {
              filters = filters.concat(ev.fields);
            }
          } else if (p.value && p.type == 'transactionType') {
            const tx = transactions.find(e => e.id === p.value);
            if (tx) {
              filters = filters.concat(tx.fields);
            }
          }
        }
      } else {
        filters.push(f);
      }
    })
  }
  let methodFields = datasource && datasource.methods ? [] : null;
  if (datasource && datasource.methods && dataFields) {
    dataFields.forEach(f => {
      if (f.type == 'number' || f.type == 'float' || f.type == 'fraction' || f.type == 'date' || f.type == 'datetime') {
        methodFields.push(f)
      }
    })
  }

  return (
    <li class="field criteria_item" key={criteria.id} >
      <MovePosition item={criteria} move={moveCriteria} canMoveUp={canMoveUp} canMoveDown={canMoveDown} />
      <Connector item={criteria} setConnector={setConnector} />
      <div class="field is-grouped is-grouped-multiline datasource">
        <div class="control">
          <Dropdown text="--- select a datasource ---" items={datasources} valueField="id" displayField="desc" onchange={val => setDatasource(val)} value={criteria.datasource.id} />
        </div>
        {criteria.datasource && datasource.params && datasource.params.map((p, i) => {
          const param = criteria.parameters.length >= i && criteria.parameters[i] || {};
          return (<div class="control">
            <CriteriaParam param={param} type={p.type} events={events} transactions={transactions} setParam={setParam(i)} />
          </div>)
        })}
        {hasAllParams && (<div class="control criteria_alias">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">as</label>
            </div>
            <div class="field-body">
              <RequiredField placeholder="Datasource Alias" onchange={({ target: { value } }) => setAlias(value)} value={criteria.datasource.alias} disabled={datasource && datasource.disabled} />
            </div>
          </div>
        </div>)}
        <div class="control">
          <button class="rm_btn delete" title="Remove criteria" onclick={() => onremove(criteria)}></button>
        </div>
      </div>
      {criteria.datasource && filters &&
        (<div class="field filter_item">
          {criteria.filters && (!!criteria.filters.length || canAddFilter) && (<div class="field is-grouped filters">
            <div class="control">
              <ul class="filter_list" >
                {criteria.filters.map((f, i) => (<ConditionItem condition={f} items={filters} events={events} type={datasource.returns} onchange={onFilterChange} onremove={() => onFilterRemove(f)} className="filter" paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} />))}
              </ul>
            </div>
          </div>)}
          {canAddFilter && (<Buttons addTitle="Add filter" onAddClick={onFilterAdd} addIcon="fa-filter" groupTitle="Add filter group" onGroupClick={onFilterGroupAdd} groupIcon="fa-layer-group" hasGroup={true} />)}
        </div>)}
      {criteria.datasource && (!!criteria.conditions.length || canAddMore) && (<div class="field is-grouped conditions">
        <div class="control is-expanded">
          <ul class="condition_list">
            {!datasource.methods && criteria.conditions.map((condition, i) => (<ConditionItem condition={condition} isFirst={i == 0} items={dataFields} events={events} type={datasource.returns} datasourceValues={datasource.values} datasourceKey={criteria.datasource.alias} onchange={onConditionChange} onremove={() => onConditionRemove(condition)} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} />))}
            {!!datasource.methods && criteria.aggregations.map((condition, i) => (<ConditionItem condition={condition} methods={datasource.methods} items={methodFields} events={events} type={datasource.returns} onchange={onAggregationChange} onremove={() => onAggregationRemove(condition)} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} />))}
          </ul>
        </div>
      </div>)}
      {canAddMore && !datasource.methods && (<Buttons addTitle="Add condition" onAddClick={onConditionAdd} addIcon="fa-plus" groupTitle="Add condition group" onGroupClick={onConditionGroupAdd} groupIcon="fa-layer-group" hasGroup={true} />)}
      {canAddAggregate && !!datasource.methods && (<Buttons addTitle="Add aggregation" onAddClick={onAggregationAdd} addIcon="fa-plus" groupTitle="Add aggregation group" onGroupClick={onAggregationGroupAdd} groupIcon="fa-layer-group" hasGroup={true} />)}
    </li>
  )
}

const CriteriaParam = ({ param, type, events, transactions, setParam }) => {
  if (type == 'eventType') {
    return (<Dropdown text=" --- select an event --- " items={events} onchange={setParam} value={param.value} />)
  } else if (type == 'transactionType') {
    return (<Dropdown text=" --- select an transaction --- " items={transactions} onchange={setParam} value={param.value} />)
  }
  if (Fields[type])
    return Fields[type]({ value: param.value, onchange: ({ target: { value } }) => setParam(value), placeholder: type })
  return null
}

const CriteriaGroup = ({ event, criteria, onchange, onremove, events, transactions, datasources, paramValues, paramsData, fetchData, moveCriteria, canMoveUp, canMoveDown }) => {
  const onItemAdd = () => {
    criteria.items = criteria.items.concat(new Criteria())
    onchange(criteria);
  }

  const onItemGroupAdd = () => {
    criteria.items = criteria.items.concat(new Criteria({ items: [new Criteria()] }))
    onchange(criteria);
  }

  const onItemChange = (c) => {
    criteria.items = criteria.items.map(i => c.id === i.id ? Object.assign({}, i, c) : i);
    onchange(criteria);
  }

  const onItemRemove = (c) => {
    criteria.items = criteria.items.filter(i => c.id !== i.id);
    if (!criteria.items.length)
      onremove(criteria);
    else
      onchange(criteria);
  }

  const moveInGroup = ({ c, d }) => {
    const idx = criterias.items.findIndex(i => c.id == i.id)
    var items = criterias.items
    if (idx > -1) {
      items.splice((idx + d), 0, items.splice(idx, 1)[0])
    }
    criteria.items = items
    onchange(criteria);
  }

  const last = criteria.items.length && criteria.items[criteria.items.length - 1];
  const canAdd = (!last || last.datasource);

  return (<div>
    <MovePosition item={criteria} move={moveCriteria} canMoveUp={canMoveUp} canMoveDown={canMoveDown} />
    <div class="field is-grouped">
      <div class="control">
        <ul class="criteria_group_list">
          {criteria.items.map((c, i) => (<CriteriaItem event={event} criteria={c} onchange={onItemChange} onremove={() => onItemRemove(c)} events={events} transactions={transactions} datasources={datasources} paramValues={paramValues} paramsData={paramsData} fetchData={fetchData} moveCriteria={moveInGroup} canMoveUp={i > 0} canMoveDown={i < criteria.items.length - 1} />))}
        </ul>
      </div>
    </div>
    {canAdd && (<Buttons addTitle="Add criteria" onAddClick={onItemAdd} addIcon="fa-plus" groupTitle="Add condition group" onGroupClick={onItemGroupAdd} groupIcon="fa-layer-group" hasGroup={true} />)}
  </div>)
}

const MovePosition = ({ item, move, canMoveUp, canMoveDown }) => {
  if (!move) return null;
  var styles = {}
  if (canMoveUp && canMoveDown) {
    styles['margin-top'] = '-18px';
  }
  return (<div class="move_position" style={styles}>
    {canMoveUp && (
      <button class="button" onclick={() => move({ item, direction: -1 })}>
        <span class="icon is-small">
          <i class="fas fa-long-arrow-alt-up"></i>
        </span>
      </button>)}
    {canMoveDown && (
      <button class="button" onclick={() => move({ item, direction: 1 })}>
        <span class="icon is-small">
          <i class="fas fa-long-arrow-alt-down"></i>
        </span>
      </button>)}
  </div>)
}
