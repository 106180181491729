import qs from 'querystring';
import { token } from './token'

export const client = {
  init: () => {
    return get('/api/init')
  },
  login: (data) => {
    return post('/api/login', data)
  },
  me: () => {
    return get('/api/me')
  },
  priviliges: () => {
    return get(`/api/me/priviliges`)
  },
  saveProfile: (data) => {
    return post('/api/me', data)
  },
  getActions: () => {
    return get('/api/actions')
  },
  getQueries: () => {
    return get('/api/queries')
  },
  emulateEvent: (data) => {
    return post('/api/emulate', data)
  },
  getRules: (params) => {
    return get('/api/rules', params)
  },
  getRule: (id) => {
    return get(`/api/rules/${id}`)
  },
  saveRule: (data) => {
    return post('/api/rules', data)
  },
  deleteRule: (id) => {
    return del('/api/rules', { id })
  },
  restoreRule: (id) => {
    return post('/api/rules/restore', { id })
  },
  getActionData: (src, kind, key, query) => {
    return get(`/api/actions/data/${src}/${kind}?key=${key}&q=${query || ''}`)
  },
  getParamsData: (src, kind, keys, query) => {
    return get(`/api/rules/data/${src}/${kind}?keys=${keys}&q=${query || ''}`)
  },
  import: (data) => {
    return post(`/api/rules/_import`, data)
  },
  export: (id) => {
    return get(`/api/rules/_export` + (id ? `?id=${id}` : ''))
  },
  play: (data) => {
    return post('/api/rules/play', data)
  },
  getEvents: (id) => {
    return get(`/api/events/${id || ''}`)
  },
  getInternalEvents: () => {
    return get(`/api/events/internal`)
  },
  getTransactions: () => {
    return get(`/api/events/transaction`)
  },
  saveEvent: (data) => {
    return post('/api/events', data)
  },
  deleteEvent: (id) => {
    return del('/api/events', { id })
  },
  getCrmEvents: (id) => {
    return get(`/api/crm_events/${id || ''}`)
  },
  saveCrmEvent: (data) => {
    return post('/api/crm_events', data)
  },
  deleteCrmEvent: (id) => {
    return del('/api/crm_events', { id })
  },
  importCrmEvents: (data) => {
    return post(`/api/crm_events/_import`, data)
  },
  exportCrmEvents: (id) => {
    return get(`/api/crm_events/_export` + (id ? `?id=${id}` : ''))
  },
  triggerAction: (data) => {
    return post('/api/trigger', data)
  },
  getScheduledQueues: () => {
    return get('/api/tasks')
  },
  getQueueTasks: (queue, params) => {
    return get(`/api/tasks/${queue}`, params)
  },
  cancelTask: (queue, index) => {
    return del(`/api/tasks`, { queue, index })
  },
  cancelBatch: (queue, batchId) => {
    return del(`/api/tasks`, { queue, batchId })
  },
  runQuery: (userId, data) => {
    return post(`/api/query/${userId}`, data)
  },
  getUsers: (params) => {
    return get('/api/users', params)
  },
  getUser: (id) => {
    return get(`/api/users/${id}`)
  },
  saveUser: (data) => {
    return post('/api/users', data)
  },
  deleteUser: (id) => {
    return del('/api/users', { id })
  },
  getRoles: () => {
    return get('/api/roles')
  },
  saveRole: (data) => {
    return post('/api/roles', data)
  },
  deleteRole: (role) => {
    return del('/api/roles', { role })
  },
  getPolicies: (params) => {
    return get('/api/policies', params)
  },
  getPolicy: (id) => {
    return get(`/api/policies/${id}`)
  },
  savePolicy: (data) => {
    return post('/api/policies', data)
  },
  deletePolicy: (id) => {
    return del('/api/policies', { id })
  },
  getFormulaEvents: () => {
    return get('/api/events/formula')
  },
  getReactions: () => {
    return get('/api/reactions')
  },
  getDatasources: () => {
    return get('/api/queries/datasources')
  },
  getFormulas: (params) => {
    return get('/api/formulas', params)
  },
  getFormula: (id) => {
    return get(`/api/formulas/${id}`)
  },
  saveFormula: (data) => {
    return post('/api/formulas', data)
  },
  deleteFormula: (id) => {
    return del('/api/formulas', { id })
  },
  restoreFormula: (id) => {
    return post('/api/formulas/restore', { id })
  },
  importFormulas: (data) => {
    return post(`/api/formulas/_import`, data)
  },
  exportFormulas: (id) => {
    return get(`/api/formulas/_export` + (id ? `?id=${id}` : ''))
  },
  getCapsules: (params) => {
    return get('/api/capsules', params)
  },
  getCapsule: (id) => {
    return get(`/api/capsules/${id}`)
  },
  saveCapsule: (data) => {
    return post('/api/capsules', data)
  },
  deleteCapsule: (id) => {
    return del('/api/capsules', { id })
  },
  restoreCapsule: (id) => {
    return post('/api/capsules/restore', { id })
  },
  importCapsules: (data) => {
    return post(`/api/capsules/_import`, data)
  },
  exportCapsules: (id) => {
    return get(`/api/capsules/_export` + (id ? `?id=${id}` : ''))
  },
  getConfigs: () => {
    return get(`/api/config`)
  },
  saveConfig: (data) => {
    return post(`/api/config`, data)
  },
  deleteConfig: (data) => {
    return del('/api/config', data)
  },
  getCountries: (params) => {
    return get(`/api/countries`, params)
  },
  getCountry: (id) => {
    return get(`/api/countries/${id}`)
  },
  saveCountry: (data) => {
    return post(`/api/countries`, data)
  },
  deleteCountry: (data) => {
    return del('/api/countries', data)
  },
  importCountries: (data) => {
    return post(`/api/countries/_import`, data)
  },
  exportCountries: () => {
    return get(`/api/countries/_export`)
  },
  getAllCountries: () => {
    return get(`/api/countries/_all`)
  },
  getCurrencies: (params) => {
    return get(`/api/currencies`, params)
  },
  getCurrency: (id) => {
    return get(`/api/currencies/${id}`)
  },
  saveCurrency: (data) => {
    return post(`/api/currencies`, data)
  },
  deleteCurrency: (data) => {
    return del('/api/currencies', data)
  },
  importCurrencies: (data) => {
    return post(`/api/currencies/_import`, data)
  },
  exportCurrencies: () => {
    return get(`/api/currencies/_export`)
  },
  getLanguages: (params) => {
    return get(`/api/languages`, params)
  },
  getLanguage: (id) => {
    return get(`/api/languages/${id}`)
  },
  saveLanguage: (data) => {
    return post(`/api/languages`, data)
  },
  deleteLanguage: (data) => {
    return del('/api/languages', data)
  },
  importLanguages: (data) => {
    return post(`/api/languages/_import`, data)
  },
  exportLanguages: () => {
    return get(`/api/languages/_export`)
  },
  getSiteconfigs: (params) => {
    return get(`/api/siteconfigs`, params)
  },
  getSiteconfig: (id) => {
    return get(`/api/siteconfigs/${id}`)
  },
  saveSiteconfig: (data) => {
    return post(`/api/siteconfigs`, data)
  },
  deleteSiteconfig: (data) => {
    return del('/api/siteconfigs', data)
  },
  getSiteconfigTypes: (id) => {
    return get(`/api/siteconfigs/_types`)
  },
  importSiteconfigs: (data) => {
    return post(`/api/siteconfigs/_import`, data)
  },
  exportSiteconfigs: () => {
    return get(`/api/siteconfigs/_export`)
  },
  getGames: (params) => {
    return get(`/api/games`, params)
  },
  getGame: (slug) => {
    return get(`/api/games/${slug}`)
  },
  saveGame: (data) => {
    return post(`/api/games`, data)
  },
  deleteGame: (data) => {
    return del('/api/games', data)
  },
  restoreGame: (data) => {
    return post('/api/games/_restore', data)
  },
  exportGames: (data) => {
    return post('/api/games/_export', data)
  },
  exportBrandGames: (data) => {
    return post('/api/games/_exportbrand', data)
  },
  getUploadHistory: (data) => {
    return get('/api/games/_history', data)
  },
  restoreUploadHistory: (data) => {
    return post('/api/games/_restorehistory', data)
  },
  getFreespins: () => {
    return get(`/api/freespins`)
  },
  saveFreespins: (data) => {
    return post(`/api/freespins`, data)
  }
}

const get = (url, params) => {
  if (params) {
    url += `?${qs.stringify(params)}`
  }
  return call('GET', url)
}

const post = (url, data) => {
  return call('POST', url, data)
}

const del = (url, data) => {
  return call('DELETE', url, data)
}

const call = async (method, url, data) => {
  const body = data ? JSON.stringify(data) : null
  const headers = {
    'Content-Type': 'application/json'
  }

  if (token.exists()) {
    Object.assign(headers, {
      'Authorization': `Bearer ${token.get()}`
    })
  }

  const response = (await fetch(url, { method, headers, body }))
  const res = response.json()
  if (!response.ok) {
    return res.then(data => {
      if (data.code == 403) {
        history.replaceState(location.pathname, '', '/restricted')
      }
      return { error: data.message };
    })
  }

  return res;
}
