import { h } from 'hyperapp';
import { validateButton } from '../../modules/utils';
import { Divider, Dropdown, Fields } from './common'
import { Trigger } from '../../modules/trigger';
import { EditorButtons } from './editor-buttons';

const separator = /\s*,\s*/;

export const TriggerAction = ({ state, actions }) => {

  const propToColumnName = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/[A-Z]/g, (s) => {
      return (' ' + s);
    }) + ' Column';
  }

  // const paramToColumnName = str => {
  //   return str.replace(/[A-Z]/g, (s) => {
  //     return ('_' + s.toLowerCase());
  //   });
  // }

  const getColumnsByType = (props, data) => {
    const columns = {};
    props.forEach((p, i) => {
      const col = data[p];
      let type = "string";
      if (col != "") {
        const cl = col.toLowerCase()
        if (cl == 'true' || cl == 'false') {
          type = "bool"
        } else if (!isNaN(col)) {
          if (col[0] != '+' && col.substr(0, 2) != "00") {
            // if (col > 0 && col < 1) {
            //   type = "fraction"
            // } else if (col.indexOf('.') > -1) {
            //   type = "float"
            // } else {
            //   type = "number"
            // }
            type = "number"
          }
        } else if (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/.test(col)) {
          var timestamp = Date.parse(col);
          if (isNaN(timestamp) == false) {
            type = "datetime"
          }
        } else if (/\d{4}-[01]\d-[0-3]\dT?/.test(col)) {
          var timestamp = Date.parse(col);
          if (isNaN(timestamp) == false) {
            type = "date"
          }
        }
      }
      if (!columns[type]) {
        columns[type] = [];
      }
      columns[type].push({ id: p, label: propToColumnName(p), idx: i });
    })
    for (var t in Fields) {
      if (!columns[t]) {
        columns[t] = [];
      }
    }
    for (var t in columns) {
      columns[t].forEach((v, i) => {
        v.idx = i;
      })
      columns[t].push({ id: '_set', label: 'Select Manually', idx: -1 });
    }
    columns.date.push({ id: '_now', label: 'Now', idx: -2 });

    columns.float = columns.fraction = columns.number;

    return columns;
  }

  const columnToProperty = col => {
    return col.split('_').map((s, i) => {
      return (i == 0 ? s : (s.charAt(0).toUpperCase() + s.slice(1)));
    }).join('');
  }

  const processData = (csv) => {
    var allTextLines = csv.split(/\r\n|\n/);
    var rows = [];
    var colData = {};
    var columns = {};
    var columnMap = {};
    if (allTextLines.length > 1) {
      var header = allTextLines[0].split(separator);
      var props = header.map(columnToProperty);
      var idColumn = props[0]; // first column is the id
      var allColLength = header.length;
      for (var i = 0; i < allColLength; i++) {
        columnMap[props[i]] = i + '';
      }
      for (var i = 1; i < allTextLines.length; i++) {
        var re = new RegExp(separator, 'g');
        var t = allTextLines[i].replace(re, '').trim()
        if (!!t) {
          var data = allTextLines[i].split(separator);
          if (allColLength > 0) {
            for (var j = 0; j < data.length; j++) {
              if (colData[props[j]] === undefined) {
                colData[props[j]] = data[j];
                allColLength--;
              }
            }
          }
          rows.push(data);
        }
      }
      columns = getColumnsByType(props, colData);
    }
    return { idColumn, rows, columns, columnMap };
  }

  const select = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = () => {
        actions.setTrigger(Object.assign(new Trigger(), { file: file.name, ...processData(fr.result) }));
      }
      fr.readAsText(file);
    }
  }

  const setNoPublish = (value) => {
    actions.setTrigger({ nopublish: !!value });
  }

  const setMethod = (value) => {
    const action = state.actionMethods.find(a => a.name == value);
    actions.setTrigger({ error: '', action });
  }

  const setSchedule = (value) => {
    actions.setTrigger({ schedule: value, dateTime: '' });
  }

  const setParam = (param, value) => {
    const params = Object.assign({}, state.params, { [param.id]: value });
    const paramValues = Object.assign({}, state.paramValues, { [param.id]: '' });
    actions.setTrigger({ params, paramValues });
  }

  const setParamValue = (param, value) => {
    const paramValues = Object.assign({}, state.paramValues, { [param.id]: value });
    actions.setTrigger({ paramValues });
  }

  const setDateTime = (value) => {
    actions.setTrigger({ dateTime: value });
  }

  const clear = () => {
    actions.clear();
  }

  const post = () => {
    const err = validateTrigger();
    if (err) {
      actions.setTrigger({ error: err });
    } else {
      actions.post();
      document.querySelector('input[type="file"]').value = '';
    }
  }

  const validateTrigger = () => {
    if (state.loading) return 'Already loading. Please wait.'
    if (!state.file) return 'Please select a file'
    if (!state.rows || !state.rows.length || state.rows.length < 1) return 'No data rows to upload'
    if (!state.action || !state.action.name) return 'Please select an action to trigger'
    if (state.action.params) {
      for (var i = 0; i < state.action.params.length; i++) {
        const param = state.action.params[i];
        const value = state.params[param.id];
        if (!value) {
          var found = false;
          let type = state.action.data && state.action.data[param.type] ? (state.action.data[param.type].type || param.type) : param.type;
          if (state.columns[type]) {
            for (var j = 0; j < state.columns[type].length; j++) {
              if (state.columns[type][j].id == param.id) {
                found = true;
                break
              }
            }
            if (!found) return `Insufficient data: ${param.id} missing`
          }
        } else if (value == '_set' && !state.paramValues[param.id] && !param.optional) return `Insufficient data: ${param.id} is not set`
      }
    }
    if (!state.schedule) return "Execution time is not set"
    if (state.schedule == '_set' && (!state.dateTime || isNaN(Date.parse(state.dateTime)))) return "Execution time is not valid"
    return '';
  }

  const validate = (el) => {
    validateButton(el, validateTrigger())
  }

  const getData = () => {
    actions.getActions();
    actions.setTrigger(new Trigger())
  }

  return (
    <div class="trigger-action" oncreate={getData} >
      <div class="columns">
        <div class="column">
          <div class="file has-name is-right is-fullwidth">
            <label class="file-label">
              <input class="file-input" type="file" name="csv" accept=".csv" onchange={select} />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-file"></i>
                </span>
                <span class="file-label">
                  Select file
                </span>
              </span>
              <span class="file-name">{state.file ? state.file : 'No csv file selected'}
              </span>
            </label>
          </div>
        </div>
        {/* <div class="column is-one-fifth">
          <div class="field is-horizontal">
            <div class="field-label is-normal" style={{'white-space':'nowrap'}}>
              <label class="label" for="no_publish">No publish</label>
            </div>
            <div class="field-body">
              <CheckBox id="no_publish" active={state.nopublish} toggle={setNoPublish} />
            </div>
          </div>
        </div>*/}
      </div>
      {state.file ? (<div>
        <Divider text="trigger action" />
        <div class="field">
          <div class="control">
            <Dropdown text="--- select an action ---" items={state.actionMethods} valueField="name" displayField="desc" onchange={val => setMethod(val)} value={state.action.name} />
          </div>
        </div>
      </div>) : null}
      {state.action.name ? (<div>
        <Divider text="parameters" />
        {(state.action.params || []).map(p => {
          let type = state.action.data && state.action.data[p.type] ? (state.action.data[p.type].type || p.type) : p.type;
          const TypeField = Fields[type];
          return (<div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{p.label}</label>
            </div>
            <div class="field-body">
              <div class="field is-grouped">
                <div class="control">
                  <Dropdown text={`--- select ${p.label.toLowerCase()} ---`} items={state.columns[type]} valueField="id" displayField="label" orderField="idx" onchange={val => setParam(p, val)} value={state.params[p.id] || p.id} />
                </div>
                {state.params[p.id] == '_set' ? (
                  <div class="control">
                    {TypeField != null ?
                      TypeField({ value: state.paramValues[p.id], onchange: ({ target: { value } }) => setParamValue(p, value), placeholder: p.label }) :
                      (<input class="input" type="string" value={state.paramValues[p.id]} onchange={({ target: { value } }) => setParamValue(p, value)} placeholder={p.label} />)
                    }
                  </div>) : null}
              </div>
            </div>
          </div>)
        })}
        <Divider text="schedule at" />
        <div class="field is-grouped">
          <div class="control">
            <Dropdown text="--- select date/time ---" items={state.columns.date} valueField="id" displayField="label" orderField="idx" onchange={val => setSchedule(val)} value={state.schedule} />
          </div>
          {state.schedule == '_set' ? (<div class="control">
            <input class="input" type="datetime-local" value={state.dateTime} onchange={({ target: { value } }) => setDateTime(value)} placeholder="Set date and time" />
          </div>) : null}
        </div>
      </div>) : null}
      <EditorButtons clear={clear} save={post} saveText={state.schedule != '_now' ? "Schedule" : "Trigger"} saveIcon={state.schedule != '_now' ? "fas fa-clock" : "fas fa-file-import"} validateSave={validate} saveDisabled={!!validateTrigger() || state.loading || state.error} />
      {state.error && (<div class="notification is-danger">{state.error}</div>)}
    </div>
  )
}
