import { h } from 'hyperapp'
import { UserEditor } from './components/user-editor'

export const User = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  return (
    <section class="section" key="user_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} User
      </h1>
      <UserEditor state={state.user} actions={actions.user} id={id} />
    </section>
  )
}
