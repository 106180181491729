import { location } from '@hyperapp/router'
import ReconnectingWebSocket from 'reconnecting-websocket'

import { cookies } from '../modules/cookies'
import { client } from '../modules/client'
import { debug } from '../modules/debug'
import { tryParse } from '../modules/utils'
import { messageBroker } from '../modules/messagebroker'

import { auth } from './auth'
import { capsule } from './capsule'
import { capsules } from './capsules'
import { config } from './config'
import { countries } from './countries'
import { country } from './country'
import { crmEvents } from './crm-events'
import { currencies } from './currencies'
import { currency } from './currency'
import { emulate } from './emulate'
import { events } from './events'
import { formula } from './formula'
import { formulas } from './formulas'
import { freespins } from './freespins'
import { game } from './game'
import { games } from './games'
import { gamesUpload } from './games-upload'
import { gameUploadHistory } from './games-upload-history'
import { language } from './language'
import { languages } from './languages'
import { policies } from './policies'
import { policy } from './policy'
import { query } from './query'
import { rule } from './rule'
import { rules } from './rules'
import { roles } from './roles'
import { siteconfig } from './siteconfig'
import { siteconfigs } from './siteconfigs'
import { tasks } from './tasks'
import { trigger } from './trigger'
import { user } from './user'
import { users } from './users'

const wsproto = window.location.protocol === 'https:' ? 'wss' : 'ws'

const init = () => async (state, actions) => {
  // fetch init state
  actions.setstate(await client.init())
  // fetch current user
  if (state.auth.authed) {
    await actions.auth.me()
    await actions.auth.priviliges()
  }
  // get flash error message if any
  const c = cookies.read('flasherr')
  if (c) {
    actions.setstate({ error: atob(c) })
    cookies.erase('flasherr')
  }
  // start receiving ws messages
  const ws = new ReconnectingWebSocket(`${wsproto}://${window.location.host}/api/ws`)
  ws.onmessage = msg => {
    const data = tryParse(msg.data)
    const { topic, ...rest } = data;
    messageBroker.emit(topic, rest);
  }
}

export const actions = {
  init,
  setstate: (res) => () => res,
  location: location.actions,
  debug: debug.actions,
  login: {
    flip: () => (state) => ({ flipped: !state.flipped, error: '' })
  },
  snackbar: {
    open: (content) => ({ open: true, content }),
    close: () => ({ open: false }),
    clear: () => ({ content: '' })
  },
  notification: {
    open: (content, level, autoClose) => ({ open: true, content, level, autoClose }),
    close: () => ({ open: false }),
    clear: () => ({ content: '', level: '', autoClose: false })
  },
  auth,
  capsule,
  capsules,
  config,
  countries,
  country,
  crmEvents,
  currencies,
  currency,
  emulate,
  events,
  formula,
  formulas,
  freespins,
  game,
  games,
  gamesUpload,
  gameUploadHistory,
  language,
  languages,
  policies,
  policy,
  query,
  roles,
  rule,
  rules,
  siteconfig,
  siteconfigs,
  tasks,
  trigger,
  user,
  users,
}
