import { h } from 'hyperapp'
import { RuleEditor } from './components/rule-editor'

export const Rule = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  const { isAdmin } = state.auth;
  return (
    <section class="section" key="rule_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} Rule
      </h1>
      <RuleEditor state={state.rule} actions={actions.rule} id={id} isAdmin={isAdmin} />
    </section>
  )
}
