import { h } from 'hyperapp';
import { dateToLocaleString } from '../../modules/utils';

export const MetaData = ({ created, createdBy, updated, updatedBy }) => {
    return (<div><div class="columns" style={{width:'100%'}}>
        <div class="column">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Created</label>
                </div>
                <div class="field-body">
                    <input type="text" placeholder="Created" value={dateToLocaleString(created)} class="input is-expanded" disabled="disabled" />
                </div>
            </div>
        </div>
        <div class="column">
            <div class="field is-horizontal">
                <div class="field-label is-normal">
                    <label class="label">Created By</label>
                </div>
                <div class="field-body">
                    <input placeholder="Created By" value={createdBy} class="input is-expanded" disabled="disabled" />
                </div>
            </div>
        </div>
    </div>
        <div class="columns" style={{width:'100%'}}>
            <div class="column">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Updated</label>
                    </div>
                    <div class="field-body">
                        <input type="text" placeholder="Updated" value={dateToLocaleString(updated)} class="input is-expanded" disabled="disabled" />
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label">Updated By</label>
                    </div>
                    <div class="field-body">
                        <input placeholder="Updated By" value={updatedBy} class="input is-expanded" disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>
    </div>)
}