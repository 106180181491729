import { h } from 'hyperapp'
import { CrmEvents as CrmEventsComponent } from './components/crm-events'

export const CrmEvents = ({ state, actions, match }) => {
  return (
    <section class="section" key="crm_events_view">
      <h1 class="title">
        CRM Events
      </h1>
      <hr />
      <CrmEventsComponent state={state.crmEvents} actions={actions.crmEvents} id={match && match.params.id} />
    </section>
  )
}
