

import { Entry } from './classes'
import { generateID } from './utils'

export const validate = (p) => {
  return !p.component && 'Component is mandatory' ||
    (!p.defaultProps || !p.defaultProps.length) && 'Please add at least one default property'
}

export const propTypes = ["string", "number", "float", "fraction", "bool", "date", "datetime", "duration"];

export class Capsule extends Entry {
  constructor(props) {
    super(props)
    const { id, component, defaultProps, enabled } = props || {};
    this.id = id || '';
    this.component = component || '';
    this.defaultProps = (defaultProps || []).map(p => new CapsuleProp(p));
    this.enabled = !!enabled;
    this.modified = false;
    this.loaded = true;
    this.showDetails = !id;
    this.preview = false;
    this.clone = null;
  }
}

export class CapsuleProp extends Entry {
  constructor(props) {
    super(props)
    const { id, property, type, value } = props || {};
    this.id = id || generateID();
    this.property = property || '';
    this.type = type || '';
    this.value = value || [];
  }
}
