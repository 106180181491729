import { h } from 'hyperapp'
import { Buttons } from './components/common';
import { Loading } from './components/loading'
import { RequiredField } from './components/input-fields'

const help = `## Log Levels

Panic = 0
Fatal = 1
Error = 2
Warn  = 3
Info  = 4
Debug = 5
Trace = 6`

export const Config = ({ state, actions }) => {
  const { configs, loaded, error } = state.config;
  const getData = () => {
    actions.config.getConfigs();
  }
  const toggleInfo = () => {
    document.querySelector('[id="config_view"]').querySelector('.modal').classList.toggle('is-active');
  }
  return (
    <section class="section" key="config_view" oncreate={getData} id="config_view">
      <h1 class="title">Config</h1>
      <a onclick={toggleInfo} class="title-help">
        <span class="icon has-text-info">
          <i class="fas fa-info-circle"></i>
        </span>
      </a>
      <hr />
      {loaded ? (<ConfigEditor configs={configs} {...actions.config} error={error} />) : (<Loading />)}
      <div class="modal">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Help</p>
            <button class="delete" onclick={toggleInfo}></button>
          </header>
          <section class="modal-card-body">
            <pre class="textlog">{help}</pre>
          </section>
          <footer class="modal-card-foot">
            <button class="button" onclick={toggleInfo}>OK</button>
          </footer>
        </div>
      </div>
    </section>
  )
}

const ConfigEditor = ({ configs, error, saveConfig, addConfig, deleteConfig, editConfig, setState }) => {
  const showAddButton = !!configs.length && !configs[configs.length - 1].error && !!configs[configs.length - 1].saved;
  const beforeSaveConfig = (cfg) => {
    if (!cfg.value) return setState({ error: 'Config key is missing.' })
    if (cfg.key == "") return setState({ error: 'Config value is missing.' })
    if (cfg.value.indexOf('.') > -1) return setState({ error: 'Config key cannot contain `.`' })
    const exists = (configs || []).find(c => c.key == cfg.key && c.id != cfg.id);
    if (exists) return setState({ error: 'Config with the same key already exists.' });

    saveConfig(cfg);
  }
  const changeConfig = (cfg, value, prop) => {
    cfg[prop] = value;
    return editConfig(cfg);
  }
  return (<div>
    <ul class="action_list">
      {configs.map(cfg => (
        <li class="field" key={cfg.id} >
          <div class="columns">
            <div class="column">
              <div class="control">
                <RequiredField placeholder="Key" value={cfg.key} onchange={({ target: { value } }) => changeConfig(cfg, value, 'key')} disasbled={cfg.saved} />
              </div>
            </div>
            <div class="column">
              <div class="control">
                <RequiredField placeholder="Value" value={cfg.value} onchange={({ target: { value } }) => changeConfig(cfg, value, 'value')} />
              </div>
            </div>
            <div class="column is-one-fifth">
              <div class="field is-grouped">
                {cfg.modified && (<div class="control">
                  <button class="button is-danger is-rounded" title="Save config" onclick={() => beforeSaveConfig(cfg)}>
                    <span class="icon">
                      <i class="fas fa-save"></i>
                    </span>
                  </button>
                </div>)}
                {cfg.saved && (<div class="control">
                  <button class="button is-light is-rounded" title="Remove config" onclick={() => deleteConfig(cfg)}>
                    <span class="icon">
                      <i class="fas fa-trash"></i>
                    </span>
                  </button>
                </div>)}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
    {error ? (<div class="notification is-danger">{error}</div>) : null}
    {showAddButton && (<div class="control add_buttons">
      <button class="button is-rounded" title="Add new config" onclick={addConfig}>
        <span class="icon is-small">
          <i class="fas fa-plus"></i>
        </span>
      </button>
    </div>)}
  </div >)
}