import { h } from 'hyperapp'
import { ListView } from './list-view'
import Th from './table-header'
import { Tr, TrButton, Modal, Loading } from './common'
import { dateToLocaleString } from '../../modules/utils'

export const GameUploadHistory = ({ state, actions }) => {
  const restoreUploadHistory = ({ id, type }) => {
    actions.gameUploadHistory.toggleModal({
      title: 'Restoring uploaded ' + type + ' games',
      content: (<Loading />),
      open: true,
      alwaysOpen: true
    });
    actions.gameUploadHistory.restoreUploadHistory({ id })
  }
  const toggleCsv = (i) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const modal = {
      title: `Export history`,
      content: (
        <div>
          <textarea id="upload_history" rows="25" class="textarea" value={i.csv} readonly autofocus></textarea>
        </div>
      ),
      open: true,
      toggle: actions.gameUploadHistory.toggleModal,
      buttons: [{
        text: "Copy to clipboard",
        class: "is-primary",
        onclick: () => {
          if (navigator.clipboard) {
            navigator.clipboard.writeText(i.csv)
          }
        }
      }, {
        text: "Save to file",
        class: "is-info",
        onclick: () => {
          const blob = new Blob([i.csv], { type: 'application/text' });
          const link = document.createElement('a');
          link.setAttribute('download', 'upload_history.csv');
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);

          // wait for the link to be added to the document
          window.requestAnimationFrame(function () {
            var event = new MouseEvent('click');
            link.dispatchEvent(event, true, true);
            document.body.removeChild(link);
            URL.revokeObjectURL(blob);
          });
        }
      }]
    }
    actions.gameUploadHistory.toggleModal(modal);
    setTimeout(() => {
      document.getElementById('upload_history').focus();
    })
    return false;
  }
  return (
    <div>
      <ListView title="upload history" name="gameUploadHistory" columns="7" getItems={actions.gameUploadHistory.getUploadHistory} disabled={true} protectedField="created" goToPage={actions.gameUploadHistory.goToPage} {...state.gameUploadHistory}
        renderHead={() => (<Tr>
          <Th name="Uploaded" />
          <Th name="Uploaded By" />
          <Th name="Type" />
          <Th name="Games count" />
          <Th name="CSV" class="th-btn" />
          <Th name="Success" />
          <th class="th-btn"></th>
        </Tr>)}
        renderBody={item => (<Tr>
          <td>{dateToLocaleString(item.created)}</td>
          <td><span title={item.createdBy} style={{ cursor: 'help' }}>{item.createdBy.split('@')[0]}</span></td>
          <td>{item.type}</td>
          <td>{item.count || 0}</td>
          <td>
            <a class="button is-inverted is-outlined is-small" onclick={toggleCsv(item)}>
              <span class="icon is-small">
                <i class="fas fa-info-circle"></i>
              </span>
            </a>
          </td>
          <td>
            {item.success ? (<span class="icon is-small"><i class="fas fa-check"></i></span>) :
              (<span class="icon is-small has-text has-text-danger" style={{ cursor: 'help' }} title={item.reason}>
                <i class="fas fa-exclamation-triangle"></i>
              </span>)}
          </td>
          <td>
            {item.success && (item.type == "meta" || item.type == "brand") && (<TrButton name="gamehistory" item={item} title="Restore" onclick={restoreUploadHistory} icon="fas fa-trash-arrow-up" />)}
          </td>
        </Tr>)
        } />
      <Modal {...state.gameUploadHistory.modal} />
    </div>
  )
}
