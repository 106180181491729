import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'
import { capitalize } from '../modules/utils'
import { Tr, TrButton, Modal } from './components/common'
import { GameDelete } from './components/game-delete'
import { GameExport } from './components/game-export'
import { GameUpload } from './components/game-upload'
import { GameUploadHistory } from './components/game-upload-history'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Games = ({ state, actions, match }) => {
  const { filter, order } = state.games;
  const activeTab = match && match.path.replace('/games/', '') || '';

  const deleteClick = ({ slug }) => {
    const { error } = actions.games.deleteGame(slug);
    if (!error) {
      actions.snackbar.open(<span>{`${slug} deleted successfully.`}&nbsp;<button class="button is-text is-small is-light" onclick={() => actions.games.restoreGame(slug)}>Undo.</button></span>)
    }
  }

  const toggleEnabled = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.enabled = !c.enabled
    actions.games.save(c)
    return false
  }

  const exportGame = (g) => {
    const modal = {
      title: `Exported game`,
      content: (<textarea id="export_games" rows="25" class="textarea" value={JSON.stringify(g, null, 2)} readonly autofocus></textarea>),
      open: true,
      toggle: actions.games.toggleModal
    }
    actions.games.toggleModal(modal)
    setTimeout(() => {
      document.getElementById('export_games').focus();
    })
    return false
  }

  const toggleCategies = (g) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    const items = state.games.items.map(i => i.slug === g.slug ? Object.assign({}, i, { showCategories: !i.showCategories }) : Object.assign({}, i, { showCategories: false }));
    actions.games.setState({ items })
    return false
  }

  return (<div class="section">
    <div class="tabs is-boxed">
      <ul>
        <li class={activeTab == 'upload' ? "is-active" : ''}>
          <Link to="/games/upload">
            <span class="icon is-small"><i class="fas fa-file-upload"></i></span>
            <span>Upload</span>
          </Link>
        </li>
        <li class={activeTab == 'export' ? "is-active" : ''}>
          <Link to="/games/export">
            <span class="icon is-small"><i class="fas fa-file-export"></i></span>
            <span>Export</span>
          </Link>
        </li>
        <li class={activeTab == 'delete' ? "is-active" : ''}>
          <Link to="/games/delete">
            <span class="icon is-small"><i class="fas fa-trash"></i></span>
            <span>Delete</span>
          </Link>
        </li>
        <li class={activeTab == '' ? "is-active" : ''}>
          <Link to="/games">
            <span class="icon is-small"><i class="fas fa-clipboard-list"></i></span>
            <span>List</span>
          </Link>
        </li>
        <li class={activeTab == 'history' ? "is-active" : ''}>
          <Link to="/games/history">
            <span class="icon is-small"><i class="fas fa-history"></i></span>
            <span>Upload History</span>
          </Link>
        </li>
      </ul>
    </div>
    {activeTab == '' && (<div>
      <ListView name="game" columns="10" getItems={actions.games.getGames} onDelete={deleteClick} disabled={true} goToPage={actions.games.goToPage} {...state.games}
        renderHead={() => (<Tr>
          <Th field="slug" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
          <Th field="studio" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
          <Th field="category" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
          <Th field="type" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
          <Th name="Collections" />
          {/* <Th field="themes" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
            <Th field="bonusFeatures" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
            <Th field="wildFeatures" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
            <Th field="winFeatures" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
            <Th field="format" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} /> */}
          {/* <Th field="payoutProperties" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} /> */}
          <Th name="Excluded" field="excludedMarkets" filter={filter} filterAction={actions.games.setFilter} route={actions.location} />
          {/* <Th field="labels" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} /> */}
          {/* <Th field="releaseDate" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} /> */}
          {/* <Th field="feeGroup" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} /> */}
          {/* <Th field="gameId" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} /> */}
          <Th field="tags" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
          <Th field="priority" filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} />
          <Th field="enabled" items={['true', 'false']} filter={filter} order={order} filterAction={actions.games.setFilter} setOrderAction={actions.games.setOrder} route={actions.location} format={capitalize} />
          <th class="th-btn"></th>
        </Tr>)}
        renderBody={item => (<Tr>
          <td>{item.slug}</td>
          <td>{item.studio}</td>
          <td>{item.category}</td>
          <td>{item.type}</td>
          <td>
            <a class="button is-inverted is-outlined is-small" onclick={toggleCategies(item)}>
              <span class="icon is-small">
                <i class="fas fa-info-circle"></i>
              </span>
            </a>
            <div class={'game-collections ' + (item.showCategories ? 'notification' : 'is-hidden')}>
              <button class="delete" onclick={toggleCategies(item)}></button>
              <div class="menu">
                <p class="menu-label">
                  Provider
                </p>
                <ul class="menu-list">
                  <li>{item.provider}</li>
                </ul>
                <p class="menu-label">
                  Format
                </p>
                <ul class="menu-list">
                  <li>{item.format}</li>
                </ul>
                {item.labels && item.labels.length && (
                  <div><p class="menu-label">
                    Labels
                  </p>
                    <ul class="menu-list">
                      {item.labels.map(t => (<li>{t}</li>))}
                    </ul>
                  </div>
                ) || ''}
                {item.themes && item.themes.length && (
                  <div><p class="menu-label">
                    Themes
                  </p>
                    <ul class="menu-list">
                      {item.themes.map(t => (<li>{t}</li>))}
                    </ul>
                  </div>
                ) || ''}
                {item.bonusFeatures && item.bonusFeatures.length && (
                  <div><p class="menu-label">
                    Bonus Features
                  </p>
                    <ul class="menu-list">
                      {item.bonusFeatures.map(t => (<li>{t}</li>))}
                    </ul>
                  </div>
                ) || ''}
                {item.wildFeatures && item.wildFeatures.length && (
                  <div><p class="menu-label">
                    Wild Features
                  </p>
                    <ul class="menu-list">
                      {item.wildFeatures.map(t => (<li>{t}</li>))}
                    </ul>
                  </div>
                ) || ''}
                {item.winFeatures && item.winFeatures.length && (
                  <div><p class="menu-label">
                    Win Features
                  </p>
                    <ul class="menu-list">
                      {item.winFeatures.map(t => (<li>{t}</li>))}
                    </ul>
                  </div>
                ) || ''}
              </div>
            </div>
          </td>
          <td>{item.excludedMarkets && item.excludedMarkets.join(',') || ''}</td>
          <td>{item.tags && item.tags.join(',') || ''}</td>
          <td>{item.priority}</td>
          <td>
            <span class="icon is-small" onclick={toggleEnabled(item)}>
              {item.enabled ?
                <i class="far fa-check-square"></i> :
                <i class="far fa-square"></i>}
            </span>
          </td>
          <td>
            <TrButton name="game" item={item} title="Export" onclick={exportGame} icon="fas fa-file-export" />
          </td>
        </Tr>)
        } />
      <Modal {...state.games.modal} />
    </div>)}
    {activeTab == 'upload' && (
      <GameUpload state={state.gamesUpload} actions={actions.gamesUpload} />
    )}
    {activeTab == 'export' && (
      <GameExport state={state.gamesUpload} actions={actions.gamesUpload} />
    )}
    {activeTab == 'delete' && (
      <GameDelete state={state.gamesUpload} actions={actions.gamesUpload} />
    )}
    {activeTab == 'history' && (
      <GameUploadHistory state={state} actions={actions} />
    )}
  </div>
  )
}