import { h } from 'hyperapp'
import { tryParse, getFilterPath, capitalize } from '../modules/utils'
import { Tr, Modal } from './components/common'
import { ListView } from './components/list-view'
import Th from './components/table-header'

export const Currencies = ({ state, actions }) => {
  const { filter, order } = state.currencies;

  const toggleFilter = (prop) => (filters) => {
    actions.location.go(getFilterPath(prop, filters))
    actions.currencies.filter({ [prop]: filters })
  }

  const deleteClick = ({ id, path }) => {
    const modal = {
      data: id,
      label: path,
      onConfirm: (id) => actions.currencies.deleteCurrency(id),
      open: true,
      toggle: actions.currencies.toggleModal
    }
    actions.currencies.toggleModal(modal);
  }

  const toggleEnabled = (c) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    c.enabled = !c.enabled
    actions.currencies.save(c)
    return false
  }

  const importCurrencies = () => {
    const modal = {
      title: `Import currencies`,
      confirmText: "Import",
      content: (<textarea id="import_currencies" rows="25" class="textarea" placeholder="Import currencies" autofocus></textarea>),
      onConfirm: () => {
        const text = document.getElementById('import_currencies').value;
        const res = tryParse(text);
        if (res)
          actions.currencies.importCurrencies(res);
      },
      open: true,
      toggle: actions.currencies.toggleModal
    }
    actions.currencies.toggleModal(modal);
  }

  const exportCurrencies = (c) => {
    actions.currencies.exportCurrencies(c).then(currencies => {
      let exportDisabled;
      function currenciesToExport() {
        return currencies.filter(r => r.enabled === true || exportDisabled)
      }
      const modal = {
        title: `Export currencies`,
        content: (
          <div>
            <div style={{'padding-bottom':'5px'}}>
              <label class="checkbox">
                <input type="checkbox" id="cbExportDisabled" onclick={(e) => { exportDisabled = e.target.checked }} />
                <span>&nbsp;Export disabledcurrencies</span>
              </label>
            </div>
            <textarea id="export_currency" rows="25" class="textarea" value={JSON.stringify(currenciesToExport(), null, 2)} readonly autofocus></textarea>
          </div>
        ),
        open: true,
        toggle: actions.currencies.toggleModal,
        buttons: [{
          text: "Copy to clipboard",
          class: "is-primary",
          onclick: () => {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(JSON.stringify(currenciesToExport()))
            }
          }
        }, {
          text: "Save to file",
          class: "is-info",
          onclick: () => {
            const json = JSON.stringify(currenciesToExport());
            const blob = new Blob([json], { type: 'application/json' });
            const link = document.createElement('a');
            link.setAttribute('download', 'export.json');
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);

            // wait for the link to be added to the document
            window.requestAnimationFrame(function () {
              var event = new MouseEvent('click');
              link.dispatchEvent(event, true, true);
              document.body.removeChild(link);
              URL.revokeObjectURL(blob);
            });
          }
        }]
      }
      actions.currencies.toggleModal(modal)
      setTimeout(() => {
        document.getElementById('export_currency').focus();
      })
    });
    return false
  }

  return (<div>
    <ListView name="currency" columns="8" getItems={actions.currencies.getCurrencies} onDelete={deleteClick} goToPage={actions.currencies.goToPage} {...state.currencies}
      renderHead={() => (<Tr>
        <Th field="name" filter={filter} order={order} filterAction={actions.currencies.setFilter} setOrderAction={actions.currencies.setOrder} route={actions.location} />
        <Th field="code"  filter={filter} order={order} filterAction={actions.currencies.setFilter} setOrderAction={actions.currencies.setOrder} route={actions.location}  />
        <Th field="symbol" />
        <Th field="pluralName" order={order} setOrderAction={actions.currencies.setOrder} route={actions.location} />
        <Th field="codeAlias" order={order} setOrderAction={actions.currencies.setOrder} route={actions.location} />       
        <Th field="baseConversionRate" order={order} setOrderAction={actions.currencies.setOrder} route={actions.location} />
        <Th field="stringPattern"/>
        <Th field="enabled" items={['true', 'false']} filter={filter} filterAction={actions.currencies.setFilter} setOrderAction={actions.currencies.setOrder} route={actions.location} format={capitalize} />
      </Tr>)}
      renderBody={item => (<Tr>
        <td>{item.name}</td>
        <td>{item.code}</td>
        <td>{item.symbol}</td>
        <td>{item.pluralName}</td>
        <td>{item.codeAlias}</td>
        <td>{item.baseConversionRate}</td>
        <td>{item.stringPattern}</td>
        <td>
          <span class="icon is-small" onclick={toggleEnabled(item)}>
            {item.isLive ?
              <i class="far fa-check-square"></i> :
              <i class="far fa-square"></i>}
          </span>
        </td>
      </Tr>)
      }
      renderButtons={() => (<Tr>
        <button class="button is-primary" onclick={importCurrencies}>Import</button>
        {!!state.currencies.items.length && (<button class="button is-info" onclick={exportCurrencies}>Export</button>)}
      </Tr>)} />
    <Modal {...state.currencies.modal} />
  </div>
  )
}
