import { client } from '../modules/client'
import { getFilterObj } from '../modules/utils'

export const users = {
  setState: (state) => (state),
  getUsers: (params) => async (state, actions) => {
    var { filter, order, skip, take } = state;
    const req = {};
    if (params && params.filter || filter) {
      if (params && params.filter) {
        filter = Object.assign(filter, params.filter);
      }
      filter = Object.keys(filter).filter(f => (filter[f] !== "" && filter[f] !== undefined)).sort().map(f => `${f}:${filter[f]}`).join(';');
      if (Object.keys(filter)) {
        req.filter = filter;
      }
    }
    if (params && params.order || order) {
      req.order = params && params.order || order;
    }
    if (params && !isNaN(params.skip) || !isNaN(skip)) {
      req.skip = (params && !isNaN(params.skip) ? params.skip : skip);
    }
    if (params && params.take || take) {
      req.take = params && params.take || take;
    }
    const res = await client.getUsers(req);
    if (res.error) {
      return {
        error: res.error
      }
    }
    if (!!res.filter) {
      res.filter = getFilterObj(res.filter);
    } else {
      res.filter = {}
    }
    res.loaded = true;
    return actions.setState(res);
  },
  deleteUser: (id) => async (state, actions) => {
    const res = await client.deleteUser(id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.getUsers({});
  },
  goToPage: (p) => async (state, actions) => {
    const skip = (p - 1);
    return actions.getUsers({ skip });
  },
  setOrder: (order) => async (state, actions) => {
    return actions.getUsers({ order });
  },
  filterUsers: (filter) => async (state, actions) => {
    return actions.getUsers({ filter, skip: 0 });
  },
  toggleModal: (modal) => (state, actions) => {
    if (!modal || state.modal) return {
      modal: null
    };
    return actions.setState({ modal })
  }
}
