import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'
import { capitalize, pluralize } from '../../modules/utils'
import { ItemsList } from './items-list'

export const ListView = (props, Children) => {
  const { name, getItems, renderButtons, renderTitle, disabled, title } = props;
  const plural = pluralize(name);
  return (
    <section class="section" key={`${plural}_view`} oncreate={getItems}>
      <h1 class="title">
        {capitalize(title || plural)}
        {renderTitle ? renderTitle() : null}
      </h1>
      <hr />
      <div class="add-new buttons">
        {renderButtons ? renderButtons() : null}
        {!disabled ? (<Link to={`/${plural}/new`} class="button is-success">Create {name}</Link>) : null}
      </div>
      <ItemsList {...props} />
      {Children}
    </section>
  )
}
