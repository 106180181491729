import { h } from 'hyperapp'

import { messageBroker } from '../../modules/messagebroker'

import { Header } from './header';
import { Footer } from './footer';
import { Snackbar } from './snackbar'
import { Notification } from './notification'

export const Layout = (args, children) => {
  const { state, actions } = args;

  const close = target => {
    document.querySelectorAll('.dropdown.is-active').forEach(d => {
      if (!target || !d.contains(target)) {
        d.classList.remove('is-active')
      }
    })
  }
  const onClick = (e) => {
    close(e.target)
  }

  // let ticking = false;
  // const onScroll = (e) => {
  //   document.querySelectorAll('.dropdown.is-active > .dropdown-menu').forEach(d => {
  //     if (!ticking) {
  //       window.requestAnimationFrame(function () {
  //         if (!Number(d.dataset.fixed)) {
  //           const bounds = d.getBoundingClientRect()
  //           const x = Number(d.dataset.scrollX) - window.scrollX;
  //           const y = Number(d.dataset.scrollY) - window.scrollY;
  //           d.style.top = bounds.top + y + "px";
  //           d.style.left = bounds.left + x + "px";
  //           d.dataset.scrollX = window.scrollX;
  //           d.dataset.scrollY = window.scrollY;
  //           ticking = false;
  //         }
  //       });

  //       ticking = true;
  //     }
  //   })
  // }

  const onResize = (e) => {
    close()
  }

  const oncreate = (e) => {
    document.addEventListener('click', onClick)
    //document.addEventListener('scroll', onScroll)
    document.addEventListener('resize', onResize)
  }

  const ondestroy = () => {
    document.removeEventListener('click', onClick)
    //document.removeEventListener('scroll', onScroll)
    document.removeEventListener('resize', onResize)
  }

  messageBroker.on("notify", (content) => {
    actions.notification.open(content.message, 'primary');
  })


  return (
    <div class={'layout' + (state.sidepanel ? ' sidemenu-open' : '') + (state.snackbar.open ? ' snackbar-open' : '')} key="layout_view" oncreate={oncreate} ondestroy={ondestroy}>
      <Header {...args} />
      <Notification state={state.notification} actions={actions.notification} />
      {state.error ? (<div class="site-error notification is-danger">
        <button class="delete" onclick={() => actions.setstate({ error: '' })}></button>
        {state.error}
      </div>) : null}
      <div class="container">
        <div class="layout-content">
          {children(args)}
        </div>
      </div>
      <Snackbar state={state.snackbar} actions={actions.snackbar} />
      <Footer {...args} />
    </div>
  )
}
