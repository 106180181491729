export class Event {
  constructor(props) {
    const { id, fields, changed } = props || {};
    this.id = id || '';
    this.fields = (fields || []).map(f => (new EventField(f))).sort((a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1));
    if (changed)
      this.changed = changed;
  }
}

export class InternalEvent extends Event {
  constructor(props) {
    super(props)
    this.internal = true;
  }
}

export class EventField {
  constructor(props) {
    const { id, label, type, values, readonly } = props || {};
    this.id = id || '';
    this.label = label || '';
    this.type = type || '';
    this.values = (values || []).sort((a, b) => (a.key.toLowerCase() < b.key.toLowerCase() ? -1 : 1));
    this.readonly = !!readonly;
  }
}

export const toBaseEvent = (event) => {
  return Object.assign({}, event, {
    fields: (event.fields || []).reduce((r, f) => {
      if (!f.readonly) {
        r.push(f)
      }
      return r
    }, [])
  })
}
