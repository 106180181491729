import { h } from 'hyperapp'
import Dropdown from './dropdown'
import { Loading } from './loading';
import { MetaData } from './meta-data'
import Modal from './modal'
import Pagination from './pagination'
import TagsInput from './tags-input'
import { dateToLocaleString } from '../../modules/utils'

import { StringField, NumericField, FloatField, FractionField, DateField, DateTimeField, RequiredField, FieldWithValidation, EmailField, JsonField } from './input-fields'

export { Dropdown, MetaData, Modal, Pagination, TagsInput, StringField, NumericField, FloatField, FractionField, DateField, DateTimeField, RequiredField, FieldWithValidation, EmailField, JsonField, Loading }

export const Divider = ({ text }) => (<div class="divider"><hr /><span>{text}</span></div>)

export const BoolField = ({ placeholder, onchange, value, required, disabled }) => (
  <Dropdown text={`${placeholder}?`} items={[{ key: 'true', label: 'True' }, { key: 'false', label: 'False' }]} valueField="key" displayField="label" onchange={(value) => onchange({ target: { value } })} value={value} required={required} disabled={disabled} />
)

export const Fields = {
  string: StringField,
  number: NumericField,
  float: FloatField,
  fraction: FractionField,
  date: DateField,
  datetime: DateTimeField,
  value: StringField,
  bool: BoolField
}

export const Buttons = ({ addTitle, onAddClick, addIcon, level, hasGroup, groupTitle, onGroupClick, groupIcon, }) => (
  <div class="control add_buttons">
    <button class="button is-rounded" title={addTitle} onclick={() => onAddClick({ level })}>
      <span class="icon is-small">
        <i class={`fas ${addIcon}`}></i>
      </span>
    </button>
    {hasGroup &&
      (<button class="button group-button is-small is-rounded" title={groupTitle} onclick={() => onGroupClick({ level })}>
        <span class="icon is-small">
          <i class={`fas ${groupIcon}`}></i>
        </span>
      </button>)}
  </div>
)

export const CheckBox = ({ id, active, toggle, disabled, className }) => {
  const oncreate = (e) => {
    if (disabled) {
      e.setAttribute('disabled', 'disabled');
    }
  }
  const onchange = ({ target }) => {
    toggle(target.checked ? target.value : null);
  }
  className = className || '';
  return (
    <div class="field">
      <p class={`control ${className}`}>
        {active ? (<input type="checkbox" id={`ch_${id}`} class="mw_checkbox" onchange={onchange} oncreate={oncreate} value={id} checked="checked" />) :
          (<input type="checkbox" id={`ch_${id}`} class="mw_checkbox" onchange={onchange} oncreate={oncreate} value={id} />)}
        <label for={`ch_${id}`}><span class="icon is-small">{active ?
          <i class="far fa-check-square"></i> :
          <i class="far fa-square"></i>}</span></label>
      </p>
    </div>
  )
}

export const Connector = ({ item, setConnector }) => {
  const radioName = `r_${item.id}`;
  if (item.connector == '&&') {
    return (<div class="control and_or"><input type="radio" id={`AND_${radioName}`} name={radioName} value="&&" onchange={({ target: { value } }) => setConnector(value)} checked="checked" />
      <label class="radio and" for={`AND_${radioName}`}></label>
      <input type="radio" id={`OR_${radioName}`} name={radioName} value="||" onchange={({ target: { value } }) => setConnector(value)} />
      <label class="radio or" for={`OR_${radioName}`}></label></div>)
  }
  else if (item.connector == '||') {
    return (<div class="control and_or"><input type="radio" id={`AND_${radioName}`} name={radioName} value="&&" onchange={({ target: { value } }) => setConnector(value)} />
      <label class="radio and" for={`AND_${radioName}`}></label>
      <input type="radio" id={`OR_${radioName}`} name={radioName} value="||" onchange={({ target: { value } }) => setConnector(value)} checked="checked" />
      <label class="radio or" for={`OR_${radioName}`}></label></div>)
  }
  return (<div class="control and_or"><input type="radio" id={`AND_${radioName}`} name={radioName} value="&&" onchange={({ target: { value } }) => setConnector(value)} />
    <label class="radio and" for={`AND_${radioName}`}></label>
    <input type="radio" id={`OR_${radioName}`} name={radioName} value="||" onchange={({ target: { value } }) => setConnector(value)} />
    <label class="radio or" for={`OR_${radioName}`}></label></div>)
}


export const ConfirmDelete = ({ data, label, open, toggle, onConfirm, onCancel, ...rest }) => {
  return (<Modal data={data} title={`Are you sure you want to delete ${label}?`} open={open} toggle={toggle} onConfirm={onConfirm} onCancel={onCancel} {...rest} />)
}

export const TrButton = ({ name, item, onclick, title, icon }) => {
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onclick(item)
  }
  return (<button class="button is-white is-rounded" title={`${title} ${name}`} onclick={onClick}>
    <span class="icon is-small"><i class={icon}></i></span>
  </button>)
}

export const Tr = (_, Children) => {
  return (Children)
}
export const TrTagsCell = ({ tags, filter, onchange }) => {
  const toggle = (tag, remove) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onchange) {
      var filters = filter && filter.split(/\s*,\s*/) || [];
      if (remove)
        filters = filters.filter(t => (t !== tag));
      else
        filters.push(tag);

      filters = filters.join(',');
      onchange(filters);
    }
  }

  return tags && (<div class="tags">
    {tags.map(t => {
      var isFilter = (filter && filter.split(/\s*,\s*/) || []).findIndex(item => t.toLowerCase() === item.toLowerCase()) !== -1;
      return (<a class={'tag is-small ' + (isFilter ? 'is-info' : 'is-white')} onclick={toggle(t, isFilter)}>{t}</a>)
    })}
  </div>) || null;
}

export const CreateUpdateControl = ({ created, createdBy, updated, updatedBy }) => {
  return (
    <div class="control is-expanded">
      <div class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Created</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input type="text" placeholder="Created" value={dateToLocaleString(created)} class="input is-expanded" disabled="disabled" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Created By</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input placeholder="Created By" value={createdBy} class="input is-expanded" disabled="disabled" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Updated</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input type="text" placeholder="Updated" value={dateToLocaleString(updated)} class="input is-expanded" disabled="disabled" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Updated By</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input placeholder="Updated By" value={updatedBy} class="input is-expanded" disabled="disabled" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}
