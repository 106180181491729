import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'

import '../css/notfound.css'

export const NotFound = () => (
  <section class="hero is-fullheight-with-navbar error-page" key="404_view">
    <div class="notfound">
      <div class="notfound-404">
        <h3>Oops! Page not found.</h3>
        <h1><span>4</span><span>0</span><span>4</span></h1>
      </div>
      <h2>we are sorry, but the page you requested was not found</h2>
      <Link class="button is-primary" to="/">Home</Link>
    </div>
  </section>
)
