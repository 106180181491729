import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'

export default ({ skip, take, count, goToPage }) => {

  const lastPage = Math.ceil(count / take) || 1;
  const mid = Math.min(Math.max(skip + 1, 3), lastPage - 2);
  let pages = [];
  if (lastPage > 5) {
    pages = Array(3).fill().map((_, i) => (mid - 1 + i));
  } else if (lastPage > 2) {
    pages = Array(lastPage - 2).fill().map((_, i) => (i + 2));
  }

  const setActive = (e) => {
    const current = parseInt(skip) + 1;
    const elem = e.querySelector(`.pagination-link.is-current`);
    if (elem && current != elem.dataset['key']) {
      elem.classList.remove('is-current')
      elem.classList.remove('active-page');
    }
    if (!elem || current != elem.dataset['key']) {
      const selected = e.querySelector(`.pagination-link[data-key="${current}"]`);
      if (selected) {
        selected.classList.add('is-current');
        selected.classList.add('active-page');
      }
    }
  }
  const getLinkPath = (p) => {
    const pathname = window.location.pathname;
    const search = window.location.search || "";
    const params = new URLSearchParams(search);
    params.set('skip', (p - 1));
    params.set('take', take);
    return `${pathname}?${params.toString()}`;
  }
  return (
    <nav class="pagination is-right" role="navigation" oncreate={setActive} onupdate={setActive}>
      <ul class="pagination-list">
        {skip > 0 ? (<li key="prev"><Link to={getLinkPath(skip)} onclick={() => goToPage(skip)} class="pagination-link" title="Previous Page"><span class="icon"><i class="fas fa-angle-left"></i></span></Link></li>) : null}
        <li key="1"><Link to={getLinkPath(1)} onclick={() => goToPage(1)} class="pagination-link" title="Page 1" data-key="1">1</Link></li>
        {(lastPage > 5 && skip > 2) ? <li key="bef"><span class="pagination-ellipsis">&hellip;</span></li> : null}
        {pages.map(p => {
          return (<li key={p}><Link to={getLinkPath(p)} onclick={() => goToPage(p)} class="pagination-link" title={`Page ${p}`} data-key={p}>{p}</Link></li>)
        })}
        {(lastPage > 5 && (skip + 1 < lastPage - 2)) ? <li key="aft"><span class="pagination-ellipsis">&hellip;</span></li> : null}
        {lastPage > 1 ? <li key={lastPage}><Link to={getLinkPath(lastPage)} onclick={() => goToPage(lastPage)} class="pagination-link" title={`Page ${lastPage}`} data-key={lastPage}>{lastPage}</Link></li> : null}
        {skip < lastPage - 1 ? (<li key="next"><Link to={getLinkPath(skip + 2)} onclick={() => goToPage(skip + 2)} class="pagination-link" title="Next Page"><span class="icon"><i class="fas fa-angle-right"></i></span></Link></li>) : null}
      </ul>
    </nav>
  )
}
