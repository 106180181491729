
import { h } from 'hyperapp';
import { emailRegex } from '../../modules/constants'

const pad = (num) => (num < 10 ? '0' + num : num + '')

const fieldValidators = {
  required: {
    pattern: /.+/,
    message: 'This field is required'
  },
  nowhitespace: {
    pattern: /[^\s]*/,
    message: 'Whitespaces are not allowed'
  },
  number: {
    pattern: /^(\d+)?$/,
    message: 'Only whole numbers are allowed'
  },
  float: {
    pattern: /^(\d+(\.\d+)?)?$/,
    message: 'Only numeric values are allowed'
  },
  fraction: val => {
    if (val && (val < 0 || val > 1)) {
      return 'Value has to be greater or equal to 0 and lower or equal to 1'
    }
  },
  date: (val, time) => {
    if (val) {
      const date = new Date(val);
      const valid = date instanceof Date && !isNaN(date);
      if (!valid) return `Please enter a valid ${time ? 'date and time' : 'date'}`;
    }
    return null;
  },
  datetime: val => fieldValidators.date(val, true),
  email: {
    pattern: emailRegex,
    message: 'Please enter a valid email'
  },
  match: (field, fieldName, name) => val => {
    if (field != val) {
      return `${name || 'Value'} doesn't match ${fieldName}`
    }
  },
  json: val => {
    try {
      JSON.parse(val);
      return;
    } catch (e) { }
    return 'JSON is invalid'
  },
}

export const FieldValidators = fieldValidators

export const RequiredField = (args) => {
  return FieldWithValidation(Object.assign({}, args, { required: true }));
}

export const StringField = (args) => {
  return FieldWithValidation(args);
}

export const NumericField = (args) => {
  const { validators } = args;
  const numericValidators = (validators || []);
  numericValidators.unshift(fieldValidators.number)
  return StringField(Object.assign({}, args, { validators: numericValidators }));
}

export const FloatField = (args) => {
  const { validators } = args;
  const floatValidators = (validators || []);
  floatValidators.unshift(fieldValidators.float)
  return StringField(Object.assign({}, args, { validators: floatValidators }));
}

export const FractionField = (args) => {
  const { validators } = args;
  const fractionValidators = (validators || []);
  fractionValidators.unshift(fieldValidators.fraction)
  return StringField(Object.assign({}, args, { validators: fractionValidators }));
}

export const DateTimeField = (args) => DateField(Object.assign({}, args, { time: true }))

export const DateField = (args) => {
  const { validators, time, value } = args;
  const dateValidators = (validators || []);
  if (time)
    dateValidators.unshift(fieldValidators.datetime)
  else
    dateValidators.unshift(fieldValidators.date)

  let stringValue = "";
  if (value) {
    const date = new Date(value);
    const dateString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    const timeString = `T${pad(date.getHours())}:${pad(date.getMinutes())}`
    stringValue = dateString + (time ? timeString : '')
  }
  return StringField(Object.assign({}, args, { value: stringValue, validators: dateValidators, type: time ? 'datetime-local' : 'date' }));
}

export const EmailField = (args) => {
  const { validators } = args;
  const emailValidators = (validators || []);
  emailValidators.unshift(fieldValidators.email)
  return StringField(Object.assign({}, args, { validators: emailValidators }));
}

export const MatchField = (args) => {
  const { validators, field, fieldName, placeholder } = args;
  const matchValidators = (validators || []);
  matchValidators.unshift(fieldValidators.match(field, fieldName, placeholder))
  return RequiredField(Object.assign({}, args, { validators: matchValidators }));
}

export const JsonField = (args) => {
  const { validators } = args;
  const jsonValidators = (validators || []);
  jsonValidators.unshift(fieldValidators.json)
  return RequiredField(Object.assign({}, args, { type: 'textarea', validators: jsonValidators, rows: 10 }));
}

export const FieldWithValidation = (args) => {
  const { value, type = 'text', placeholder, onchange, validators = [], className, required, disabled, nowhitespace, onkeydown, focused, isDynamic, onclick, toUpperCase, toLowerCase, rows } = args;
  const attrs = {};
  if (required) {
    validators.unshift(fieldValidators.required)
    attrs.required = 'required';
  }
  if (disabled) {
    attrs.disabled = 'disabled'
  }
  if (nowhitespace) {
    validators.unshift(fieldValidators.nowhitespace)
  }

  const oncreate = (el) => {
    el.addEventListener('validate', validate, false);
    const field = el.closest('.param_field');
    if (field) {
      field.classList.add('has-field');
    }
    if (focused) {
      el.focus()
    }
  }

  const ondestroy = (el) => {
    el.removeEventListener('validate', validate);
  }

  const validate = (e) => {
    return validateField(e.target);
  }

  const validateField = (target, changed) => {
    if (validators && validators.length) {
      const icon = target.previousSibling && target.previousSibling.firstChild;
      let p = target.nextSibling;
      while (!p.classList.contains("help")) {
        p = p.nextElementSibling;
      }
      const group = target.closest('.field.is-grouped');
      let error = "";
      if (changed || !isDynamic) {
        let i = 0;
        while (!error && i < validators.length) {
          const validator = validators[i];
          if (typeof validator == 'function') {
            error = validator(target.value);
          } else if (validator.pattern && !validator.pattern.test(target.value)) {
            error = validator.message;
          }
          i++;
        }
      }
      if (!!error) {
        if (icon)
          icon.classList.remove('is-invisible');
        if (target)
          target.classList.add('is-danger');
        if (p) {
          p.classList.remove('is-invisible');
          p.innerText = error;
        }
        if (group)
          group.classList.add('has-error');

      } else {
        if (icon)
          icon.classList.add('is-invisible');
        if (target)
          target.classList.remove('is-danger');
        if (p) {
          p.classList.add('is-invisible');
          p.innerText = '';
        }
        if (group)
          group.classList.remove('has-error');
      }
      return !error;
    }
    return true;
  }
  const onChange = (event) => {
    const { target } = event;
    if (toUpperCase || toLowerCase) {
      const { value } = target;
      if (toUpperCase) {
        event.target.value = value.toUpperCase();
      } else {
        event.target.value = value.toLowerCase();
      }
    }
    const isvalid = validateField(target, true);
    if (onchange) onchange(event, isvalid);
  }
  const classNames = 'control has-icons-right' + (className ? ` ${className}` : '');
  return (<div class="field">
    <p class={classNames}>
      <span class="icon is-small is-right">
        <i class="fas fa-exclamation-triangle is-invisible"></i>
      </span>
      {type == 'textarea' ?
        (<textarea class="textarea" rows={rows} placeholder={placeholder} value={value} oncreate={oncreate} ondestroy={ondestroy} onchange={(e) => onChange(e)} onkeydown={onkeydown} onclick={onclick} {...attrs} />) :
        (<input class="input" type={type} placeholder={placeholder} value={value} oncreate={oncreate} ondestroy={ondestroy} onchange={(e) => onChange(e)} onkeydown={onkeydown} onclick={onclick} {...attrs} />)}
      <p class="help is-danger is-invisible"></p>
    </p>
  </div>)
}
