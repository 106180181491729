import { client } from '../modules/client'
import { mapToObject, getParamValues } from '../modules/utils'
import { Rule, Action, Criteria, validate, getDatasources, getActionMethods, ruleFromState, connectors, RULES_EXEC_DONE, Condition } from "../modules/rule";

export const rule = {
  setState: (state) => (state),
  getEvents: () => async (state, actions) => {
    const res = await client.getEvents();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const crmres = await client.getCrmEvents();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    if (state.internals && state.transactions) {
      return actions.setState({
        events: (state.internals || []).concat(res.events || []).concat(crmres.events || []),
      });
    }
    const internal = await client.getInternalEvents();
    if (internal.error) {
      return actions.setState({
        error: internal.error
      })
    }
    const transactions = await client.getTransactions();
    if (transactions.error) {
      return actions.setState({
        error: transactions.error
      })
    }
    return actions.setState({
      events: (internal.events || []).concat(res.events || []).concat(crmres.events || []),
      internals: (internal.events || []),
      transactions: (transactions.events || [])
    });
  },
  getRule: (id) => async (state, actions) => {
    await actions.getEvents();
    await actions.getQueries();
    await actions.getActions();
    if (id) {
      const res = await client.getRule(id);
      if (res.error) {
        return actions.setState({
          error: res.error,
          loaded: true
        })
      }
      if (res.rule) {
        if (res.rule.event == RULES_EXEC_DONE) {
          await actions.getChainRuleIds(id);
        }
        return actions.setState(new Rule(res.rule));
      }
      return actions.setState({
        error: "Rule not found",
        loaded: true
      })
    }
    if (state.clone?.event == RULES_EXEC_DONE) {
      await actions.getChainRuleIds(state.clone.id);
    }
    return actions.setState(new Rule(state.clone));
  },
  saveRule: (onDone) => async (state, actions) => {
    var err = validate(state, state);
    if (state.active && !!err) {
      return actions.setState({
        error: err
      })
    }
    const rule = ruleFromState(state);
    const res = await client.saveRule(rule);
    if (res.error) {
      return actions.setState({
        error: `Something went wrong. ${res.error}`
      })
    }
    if (onDone) {
      if (onDone.exit) {
        history.pushState(location.pathname, '', '/rules')
      } else if (onDone.empty) {
        history.pushState(location.pathname, '', '/rules/new')
        return actions.setState(new Rule());
      } else {
        if (location.pathname == '/rules/new' && res.items.length) {
          actions.setState({ id: res.items[0].id });
          history.pushState(location.pathname, '', '/rules/' + res.items[0].id)
        }
        return actions.setState({ modified: false });
      }
    }
  },
  restoreRule: () => async (state, actions) => {
    const res = await client.restoreRule(state.id);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState({ modified: false, archive: false });
  },
  selectEvent: (event) => async (state, actions) => {
    // const event = events.find(e => e.name === eventName);
    var actionData = state.actionData || {};
    if (state.actionData && state.actionData['rules/event/id']) {
      const data = await actions.getActionData({ source: 'rules', kind: 'event', key: 'id', event: event });
      if (data.error) {
        return actions.setState({
          error: data.error
        })
      }
      actionData = data.actionData;
    }
    if (!state.ruleIds && event == RULES_EXEC_DONE) {
      await actions.getChainRuleIds(state.id);
    }
    return actions.setState({
      modified: true,
      event,
      actionData
    })
  },
  addCriteria: () => (state) => {
    const connector = state.criterias.length ? connectors.AND : connectors.OR;
    return {
      modified: true,
      criterias: state.criterias.concat(new Criteria({ connector }))
    }
  },
  addCriteriaGroup: () => (state) => {
    const connector = state.criterias.length ? connectors.AND : connectors.OR;
    return {
      modified: true,
      criterias: state.criterias.concat(new Criteria({ connector, items: [new Criteria({ connector })] }))
    }
  },
  setCriteria: (c) => (state) => {
    return {
      modified: true,
      criterias: state.criterias.map(i => c.id === i.id ? Object.assign({}, i, c) : i)
    }
  },
  removeCriteria: (c) => (state) => {
    const criterias = state.criterias.filter(i => c.id !== i.id)
    if (criterias.length) {
      criterias[0].connector == connectors.OR
    }
    return { modified: true, criterias }
  },
  moveCriteria: ({ item, direction }) => (state) => {
    const idx = state.criterias.findIndex(i => item.id == i.id)
    var criterias = state.criterias
    if (idx > -1) {
      var moveTo = idx + direction
      criterias.splice(moveTo, 0, criterias.splice(idx, 1)[0])
      if (moveTo == 0) {
        criterias[0].connector == connectors.OR
        criterias[1].connector == connectors.AND
      }
    }
    return { modified: true, criterias }
  },
  addAction: () => (state) => {
    return {
      modified: true,
      actions: state.actions.concat(new Action())
    }
  },
  setAction: (r) => (state) => {
    return {
      modified: true,
      actions: state.actions.map(i => r.id === i.id ? Object.assign({}, i, r) : i)
    }
  },
  removeAction: (r) => (state) => {
    return {
      modified: true,
      actions: state.actions.filter(i => r.id !== i.id)
    }
  },
  addUnmetAction: () => (state) => {
    return {
      modified: true,
      unmetActions: state.unmetActions.concat(new Action())
    }
  },
  setUnmetAction: (r) => (state) => {
    return {
      modified: true,
      unmetActions: state.unmetActions.map(i => r.id === i.id ? Object.assign({}, i, r) : i)
    }
  },
  removeUnmetAction: (r) => (state) => {
    return {
      modified: true,
      unmetActions: state.unmetActions.filter(i => r.id !== i.id)
    }
  },
  getActions: () => async (state, actions) => {
    if (state.actionMethods) return actions.setState({});
    const data = await client.getActions();
    if (data.error) {
      return actions.setState({
        error: data.error
      })
    }
    return actions.setState(getActionMethods(data));
  },
  getActionData: ({ source, kind, key, query, event, value }) => async (state, actions) => {
    const path = `${source}/${kind}` + (!!key ? `/${key}` : '') + (!!query ? `/${query}` : '');
    if (source == 'rules' && kind == 'event') {
      actions.setActionData({ [path]: { loading: true } });
      const data = await client.getEvents(event || state.event);
      if (data.error) {
        return actions.setState({
          error: `Something went wrong. ${data.error}`
        })
      }
      return actions.setActionData({ [path]: { data: data.events.filter(e => (e.id != event)).map(e => e.id), loading: false } });
    }
    if (source == 'csv') {
      var splits = [];
      if (value) {
        splits = value.split(/\s*,\s*/)
      }
      return actions.setActionData({ [path]: { data: splits } });
    }
    if (state.actionData && state.actionData[path] && state.actionData[path].data) {
      return actions.setState(state);
    }
    actions.setActionData({ [path]: { loading: true } });
    const data = await client.getActionData(source, kind, key, query);
    return actions.setActionData({ [path]: { data: (data || []), loading: false } });
  },
  setActionData: data => state => {
    return { actionData: Object.assign(state.actionData || {}, data) }
  },
  getParamsData: ({ source, kind, key, display, query }) => async (state, actions) => {
    key = key || 'code';
    display = display || 'name';
    const path = `${source}/${kind}` + (!!query ? `/${query}` : '');
    if (state.paramsData && state.paramsData[path]) {
      return actions.setState(state);
    }
    actions.setParamsData({ [path]: { loading: true } });
    const data = await client.getParamsData(source, kind, `${key},${display}`);
    return actions.setParamsData({ [path]: { data: mapToObject(data, key, display), loading: false } });
  },
  setParamsData: data => state => {
    return { paramsData: Object.assign(state.paramsData || {}, data) }
  },
  getQueries: () => async (state, actions) => {
    if (state.datasources)
      return actions.setState(state);
    const data = await client.getQueries();
    return actions.setState(getDatasources(data, true));
  },
  play: () => async (state, actions) => {
    if (!state.playData) {
      return actions.setState({
        playError: "Please provide some data for the event"
      })
    }
    if (state.playData.userId && !state.playData.userId.value) {
      return actions.setState({
        playError: `User ID needed to trigger ${state.event}`
      })
    }
    const eventData = {};
    if (!!state.playData) {
      for (var k in state.playData) {
        const { type, value } = state.playData[k]
        switch (type) {
          case "number":
          case "float":
            eventData[k] = Number(value)
            break
          case "date":
          case "datetime":
            eventData[k] = new Date(value)
            break
          default:
            eventData[k] = value
            break
        }
      }
    }
    const res = await client.play({
      ruleId: state.id,
      eventTopic: state.event,
      eventData,
      dryRun: state.dryRun
    });
    if (res.error) {
      return actions.setState({
        playError: res.error
      })
    }

    return actions.setState({
      playResult: res.result,
      playError: ''
    });
  },
  togglePreview: () => state => {
    return { preview: !state.preview }
  },
  showEventFields: ({ action, index, value }) => (state) => {
    const triggerEvent = state.events.find(e => (e.id == state.event));
    const event = state.events && state.events.find(e => (e.id == value))
    var eventFields
    if (!!event) {
      eventFields = (event.fields || []).filter(f => !f.readonly)
    }
    return {
      eventFields: {
        open: true,
        event,
        eventFields,
        action,
        index,
        fields: getParamValues(triggerEvent, state),
        values: action.parameters[index].value || {}
      }
    }
  },
  setEventFields: ({ action, index, data }) => (state, actions) => {
    action.parameters[index].value = data;
    const res = actions.setAction(action);
    res.eventFields = state.eventFields;
    res.eventFields.values = data;
    return res;
  },
  getChainRuleIds: (id) => async (state, actions) => {
    const resp = await client.getRules({ skip: 0, take: 0, order: 'title', filter: "chainable:true" });
    return actions.setState({ ruleIds: resp.items.filter(r => (r.id != id)) });
  },
  selectChainRule: (ruleId) => (state, actions) => {
    var crit = new Criteria({
      connector: connectors.OR,
      datasource: {
        id: "event",
        key: "event",
        alias: "event",
        changed: false
      },
      returns: "eventType",
      conditions: [new Condition({ connector: connectors.OR, field: 'ruleId', type: 'string', value: ruleId })]
    })
    if (state.criterias.length) {
      state.criterias.shift()
    }
    state.criterias.unshift(crit)
    return actions.setState({
      modified: true,
      criterias: state.criterias,
      ruleId
    });
  }
}
