import { client } from '../modules/client';
import { getActionMethods } from '../modules/rule';
import { Trigger } from '../modules/trigger';

const mapToTriggerPost = ({ rows, action, idColumn, schedule, dateTime, columnMap, params, paramValues, nopublish }) => {
  const parameters = action.params.map(p => {
    var value;
    var isDynamic = false;
    if (params[p.id] == '_set') {
      value = paramValues[p.id];
      isDynamic = true
    } else if (columnMap[p.id]) {
      value = columnMap[p.id];
    } else {
      value = columnMap[params[p.id]];
    }
    return {
      value,
      isDynamic,
      type: action.data && action.data[p.type] && action.data[p.type].type || p.type
    }
  });
  var scheduled = ''; // now
  var isSetDate = false;
  if (schedule == '_set') {
    scheduled = new Date(dateTime).toISOString();
    isSetDate = true;
  } else if (schedule) {
    scheduled = columnMap[schedule];
  }
  const isProvider = (idColumn.indexOf("provider") > -1);
  const isSegment = !isProvider && (idColumn == "segment");
  const res = {
    userId: !isProvider && !isSegment,
    providerUserId: isProvider,
    segment: isSegment,
    action: {
      method: action.name,
      parameters
    },
    schedule: scheduled,
    isSetDate: isSetDate,
    nopublish: nopublish,
    rows: rows,
  }
  return res
}

export const trigger = {
  setTrigger: (values) => (state, actions) => {
    return values;
  },
  clear: () => (state, actions) => {
    return actions.setTrigger(new Trigger());
  },
  post: () => async (state, actions) => {
    if (!state.rows.length > 1) {
      return actions.setTrigger({
        error: `Invalid data. Missing rows.`
      });
    }
    actions.setTrigger({ loading: true });
    const data = mapToTriggerPost(state);
    const res = await client.triggerAction(data);
    if (!res.error) {
      return actions.clear();
    }
    return actions.setTrigger({
      error: `Something went wrong. ${res.error}`
    });
  },
  getActions: () => async (state, actions) => {
    if (state.actionMethods) return actions.setTrigger({});
    const data = await client.getActions();
    if (data.error) {
      return actions.setTrigger({
        error: data.error
      })
    }
    var { actionMethods } = getActionMethods(data);
    actionMethods = actionMethods.filter(a => (!a.requirements));
    return actions.setTrigger({ actionMethods });
  },
}
