import { client } from '../modules/client'

export const roles = {
  setState: (state) => (state),
  getRoles: () => async (state, actions) => {
    const res = await client.getRoles();
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    return actions.setState(res);
  },
  saveRole: (role) => async (state, actions) => {
    const res = await client.saveRole({ role });
    if (res.error) {
      return actions.setState({
        error: res.error,
        loaded: true
      })
    }
    const roles = state.items.concat([role]);
    return actions.setState({
      items: roles,
      loaded: true,
      error: ''
    });
  },
  deleteRole: (role) => async (state, actions) => {
    const res = await client.deleteRole(role);
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    const roles = state.items.filter(r => r != role);
    return actions.setState({
      error: '',
      items: roles
    });
  }
}
