import { h } from 'hyperapp'
import { CurrencyEditor } from './components/currency-editor'

export const Currency = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  return (
    <section class="section" key="currency_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} Currency
      </h1>
      <CurrencyEditor state={state.currency} actions={actions.currency} id={id} />
    </section>
  )
}
