import { h } from 'hyperapp'
import { Events as EventsComponent } from './components/events'

export const Events = ({ state, actions, match }) => {
  return (
    <section class="section" key="events_view">
      <h1 class="title">
        Events
      </h1>
      <hr />
      <EventsComponent state={state.events} actions={actions.events} id={match && match.params.id} />
    </section>
  )
}
