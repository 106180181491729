

import { Entry } from './classes'

export const validate = (p) => {
  return !p.path && 'Path is mandatory' ||
    (!p.methods || !p.methods.length) && 'Please add at least one method'
}

export class Policy extends Entry {
  constructor(props) {
    super(props)
    this.path = '';
    this.methods = '';
    this.roles = '';
    this.modified = false;
  }
}
