import { h } from 'hyperapp'

import '../../css/emulate.css'

export const GameExport = ({ state, actions }) => {
  const setSlugs = ({ target: { value } }) => {
    actions.setSlugs(value)
  }
  const exportGames = (e) => {
    actions.export({ games: state.slugs })
  }
  const setBrandSlugs = ({ target: { value } }) => {
    actions.setBrandSlugs(value)
  }
  const exportBrandGames = (e) => {
    actions.exportBrand({ games: state.brandSlugs })
  }
  return (
    <section class="section" key="games_export_view" id="games_export_view">
      <h2 class="title">
        Export Meta Data
      </h2>
      <hr />
      <div class="columns">
        <div class="column  is-two-thirds">
          <h4 class="subtitle">
            <span class="icon">
              <i class="fas fa-download"></i>
            </span>
            <span>Selected meta slugs</span>
          </h4>
          <div class="field">
            <p class="control">
              <textarea class="input" placeholder="All meta data" value={state.slugs} onchange={setSlugs} />
            </p>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          {(typeof state.progress != 'undefined') &&
            <div>
              <h2>
                <i class="fas fa-spinner progress-spinner"></i>&nbsp;
                <span>{Math.round(state.progress * 100)}%</span>
              </h2>
              <progress class="progress is-info" value={state.progress} />
            </div>
          }
          {state.downloadFailed &&
            <h2>Download failed: {state.downloadFailed}</h2>
          }
          {!state.uploadFailed && state.done &&
            <h2>Meta data download completed in {state.elapsed}s</h2>
          }
        </div>
        <div class="column">
          <a class="button" onclick={exportGames} disabled={state.loading}>
            <span class="icon">
              <i class="fas fa-cloud-download-alt"></i>
            </span>
            <span>Export</span>
          </a>
        </div>
      </div>
      {/* Brand specific data */}
      <h2 class="title">
        Export Brand Data
      </h2>
      <hr />
      <div class="columns">
        <div class="column  is-two-thirds">
          <h4 class="subtitle">
            <span class="icon">
              <i class="fas fa-download"></i>
            </span>
            <span>Selected brand slugs</span>
          </h4>
          <div class="field">
            <p class="control">
              <textarea class="input" placeholder="All brand data" value={state.brandSlugs} onchange={setBrandSlugs} />
            </p>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          {(typeof state.brandProgress != 'undefined') &&
            <div>
              <h2>
                <i class="fas fa-spinner progress-spinner"></i>&nbsp;
                <span>{Math.round(state.brandProgress * 100)}%</span>
              </h2>
              <progress class="progress is-info" value={state.brandProgress} />
            </div>
          }
          {state.brandDownloadFailed &&
            <h2>Download failed: {state.brandDownloadFailed}</h2>
          }
           {!state.brandDownloadFailed && state.brandDone &&
            <h2>Brand data download completed in {state.brandElapsed}s</h2>
          }
        </div>
        <div class="column">
          <a class="button" onclick={exportBrandGames} disabled={state.brandLoading}>
            <span class="icon">
              <i class="fas fa-cloud-download-alt"></i>
            </span>
            <span>Export</span>
          </a>
        </div>
      </div>
    </section >
  )
}
