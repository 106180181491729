import { h } from 'hyperapp';
import { untagify } from '../../modules/utils';

import { TagsInput, Fields, Dropdown } from './common';

export const RulePlayEditor = ({ state, actions }) => {

  if (!state.event) return null;

  const { criterias } = state;
  const event = state.events.find(e => e.id == state.event);
  const datasources = criterias.filter(c => (c.datasource && c.datasource.id == "event"));
  const eventConditions = {};
  if (datasources.length) {
    datasources.forEach(crit => {
      crit.conditions.forEach(cond => {
        if (!eventConditions[cond.field])
          eventConditions[cond.field] = cond
      })
    })
  }
  const setPlayData = (prop, type, value) => {
    const playData = Object.assign({}, (state.playData || {}), { [prop]: { type, value } });
    actions.setState({ playData });
  }
  const oncreate = () => {
    const playData = (state.playData || {});
    Object.keys(eventConditions).forEach(k => {
      const { field, type, value } = eventConditions[k];
      playData[field] = { type, value };
    })
    if (event && event.fields) {
      var userId = event.fields.find(f => f.id == 'userId');
      if (userId) {
        playData.userId = { type: userId.type };
      }
    }
    actions.setState({ playData });
  }
  return (<div oncreate={oncreate}>
    {state.playError && (<div class="notification is-danger">{state.playError}</div>)}
    {(event.fields || []).map(field => {
      return (<div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{field.label}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <PlayParameter field={field} value={state.playData[field.id] && state.playData[field.id].value || ''} setValue={(value) => setPlayData(field.id, field.type, value)} />
            </p>
          </div>
        </div>
      </div>)
    })}
  </div>)
}

const PlayParameter = ({ field, value, setValue }) => {
  const isUserId = (field.id == 'userId');
  const fieldType = (field.itemType || field.type);
  const required = (value != "");
  if (field.values) {
    let fieldLabel = field.label.toLowerCase();
    if (['a', 'e', 'i', 'o', 'u'].indexOf(fieldLabel.charAt(0)) > -1) {
      fieldLabel = `an ${fieldLabel}`
    } else {
      fieldLabel = `a ${fieldLabel}`
    }
    return (<Dropdown text={` --- select ${fieldLabel} --- `} items={field.values} valueField="key" displayField="value" onchange={setValue} value={value} />)
  } else if (field && field.type == 'array') {
    return (<TagsInput type={field.itemType} value={value} onchange={setValue} placeholder={`Enter ${field.itemType}`} validators={validators} required={required} />)
  } else if (Fields[fieldType]) {
    return Fields[fieldType]({ value: value, onchange: ({ target: { value } }) => setValue(value), placeholder: `Enter ${fieldType}`, required: (required || isUserId), focused: isUserId })
  }
}
