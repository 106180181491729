export class CrmEvent {
  constructor(props) {
    const { id, fields, changed } = props || {};
    this.id = id || '';
    this.fields = (fields || []).map(f => (new CrmEventField(f))).filter(a => !a.readonly).sort((a, b) => (a.externalId.toLowerCase() < b.externalId.toLowerCase() ? -1 : 1));
    if (changed)
      this.changed = changed;
  }
}

export class CrmEventField {
  constructor(props) {
    const { externalId, id, label, type, readonly } = props || {};
    this.externalId = externalId || '';
    this.id = id || '';
    this.label = label || '';
    this.type = type || '';
    this.readonly = !!readonly;
  }
}
