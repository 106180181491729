import { h } from 'hyperapp'
import { Buttons } from './components/common';
import { Loading } from './components/loading'
import { RequiredField, FloatField } from './components/input-fields'
import { EditorButtons } from './components/editor-buttons';

export const Freespins = ({ state, actions }) => {
  const { loaded } = state.freespins;
  const getData = () => {
    actions.freespins.getFreespins();
  }
  return (
    <section class="section" key="freespins_view" oncreate={getData} id="freespins_view">
      <h1 class="title">Spin Value Groups</h1>
      <hr />
      {loaded ? (<FreespinsEditor {...actions.freespins} state={state.freespins} />) : (<Loading />)}
    </section>
  )
}

const FreespinsEditor = ({ state, saveFreespins, addFreespins, deleteFreespins, editFreespins, getFreespins, setState }) => {
  const { freespins, error } = state;
  const beforeSaveFreespins = () => {
    for (var i = 0, j = freespins.length; i < j; i++) {
      const fs = freespins[i];
      if (!fs.changed) return
      if (fs.name == "") return setState({ error: 'Freespins name is missing.' })
      if (fs.minValue == "" && fs.maxValue == "") return setState({ error: 'Freespins must have a min or max value.' })
      // if (!fs.minValue && !!i) return setState({ error: 'Freespins min value is missing.' })
      // if (!fs.maxValue && i < freespins.length) return setState({ error: 'Freespins max value is missing.' })

      const exists = (freespins || []).find(c => c.name == fs.name && c.id != fs.id);
      if (exists) return setState({ error: `Freespins with the same name '${exists.name}' already exists.` });
    }
  }
  const changeFreespins = (fs, value, prop) => {
    fs[prop] = value;
    fs.changed = true;
    return editFreespins(fs);
  }

  const save = (e, exit) => {
    if (state.modified) {
      if (!beforeSaveFreespins()) {
        saveFreespins()
        if (exit) {
          history.pushState(location.pathname, '', '/');
        }
      }
    }
  }

  const saveAndExit = (e) => {
    save(e, true);
  }


  return (<div>
    <div class="columns">
      <div class="column">
        <div class="control">
          <label class="label">Name</label>
        </div>
      </div>
      <div class="column">
        <div class="control">
          <label class="label">Min Value</label>
        </div>
      </div>
      <div class="column is-one-fifth">&nbsp;</div>
      <div class="column">
        <div class="control">
          <label class="label">Max Value</label>
        </div>
      </div>
      <div class="column is-one-fifth">
      </div>
    </div>
    <ul class="action_list">
      {freespins.map(fs => (
        <li class="field" key={fs.name} >
          <div class="columns">
            <div class="column">
              <div class="control">
                <RequiredField placeholder="Name" value={fs.name} onchange={({ target: { value } }) => changeFreespins(fs, value, 'name')} />
              </div>
            </div>
            <div class="column">
              <div class="control">
                <FloatField placeholder="Min Value" value={fs.minValue} onchange={({ target: { value } }) => changeFreespins(fs, value, 'minValue')} />
              </div>
            </div>
            <div class="column is-one-fifth">
              <div class="is-flex" style={{ 'justify-content': 'space-evenly', 'margin-top': '0.75em' }}>
                <span class="icon is-small"><i class="fas fa-less-than-equal"></i></span>
                <span class="icon is-small"><i class="fas fa-coins"></i></span>
                <span class="icon is-small"><i class="fas fa-less-than"></i></span>
              </div>
            </div>
            <div class="column">
              <div class="control">
                <FloatField placeholder="Max Value" value={fs.maxValue} onchange={({ target: { value } }) => changeFreespins(fs, value, 'maxValue')} />
              </div>
            </div>
            <div class="column is-one-fifth">
              <div class="control">
                {/* <button class="rm_btn delete" title="Remove freespins" onclick={() => deleteFreespins(fs)}></button> */}
                <button class="button is-white is-rounded" title="Remove freespins" onclick={() => deleteFreespins(fs)}>
                  <span class="icon is-small"><i class="far fa-times-circle"></i></span>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
    {error ? (<div class="notification is-danger">{error}</div>) : null}
    <div class="control add_buttons">
      <button class="button is-rounded" title="Add new freespins" onclick={addFreespins}>
        <span class="icon is-small">
          <i class="fas fa-plus"></i>
        </span>
      </button>
    </div>
    <EditorButtons clear={getFreespins} cancelTo="" save={save} saveAndExit={saveAndExit} validateSave={beforeSaveFreespins} saveDisabled={!state.modified || !!state.error} />
  </div >)
}