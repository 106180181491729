import { generateID, tagify, toKeyValue, parseDate, dateToString, tryParse } from './utils'
export const RULES_EXEC_DONE = 'RULES_EXEC_DONE';
export const comparers = {
  string: [{ value: '==', label: 'equal to' }, { value: '!=', label: 'not equal to' }, { value: 'in', label: 'in' }, { value: 'nin', label: 'not in' }, { value: 'match', label: 'match' }, { value: 'dontmatch', label: 'not match' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
  number: [{ value: '==', label: 'equal to' }, { value: '!=', label: 'not equal to' }, { value: '<', label: 'less than' }, { value: '<=', label: 'less or equal' }, { value: '>', label: 'greater than' }, { value: '>=', label: 'greater or equal' }, { value: '<>', label: 'between' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
  float: [{ value: '==', label: 'equal to' }, { value: '!=', label: 'not equal to' }, { value: '<', label: 'less than' }, { value: '<=', label: 'less or equal' }, { value: '>', label: 'greater than' }, { value: '>=', label: 'greater or equal' }, { value: '<>', label: 'between' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
  fraction: [{ value: '==', label: 'equal to' }, { value: '!=', label: 'not equal to' }, { value: '<', label: 'less than' }, { value: '<=', label: 'less or equal' }, { value: '>', label: 'greater than' }, { value: '>=', label: 'greater or equal' }, { value: '<>', label: 'between' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
  date: [{ value: '==', label: 'on' }, { value: '<', label: 'before' }, { value: '<=', label: 'not later than' }, { value: '>', label: 'after' }, { value: '>=', label: 'not earlier than' }, { value: '<>', label: 'between' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
  datetime: [{ value: '==', label: 'at' }, { value: '<', label: 'before' }, { value: '<=', label: 'not later than' }, { value: '>', label: 'after' }, { value: '>=', label: 'not earlier than' }, { value: '<>', label: 'between' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
  bool: [{ value: '==', label: 'is' }, { value: '!=', label: 'is not' }],
  duration: [{ value: '==', label: 'exactly' }, { value: '<', label: 'earlier than' }, { value: '<=', label: 'not later than' }, { value: '>', label: 'later than' }, { value: '>=', label: 'not earlier than' }, { value: '<>', label: 'between' }],
  array: [{ value: 'any', label: 'any of' }, { value: 'all', label: 'all of' }, { value: 'none', label: 'none of' }],
  any: [{ value: '==', label: 'is' }, { value: '!=', label: 'is not' }],
  value: [{ value: '==', label: 'equal to' }, { value: '!=', label: 'not equal to' }, { value: '<', label: 'less than' }, { value: '<=', label: 'less or equal' }, { value: '>', label: 'greater than' }, { value: '>=', label: 'greater or equal' }, { value: '<>', label: 'between' }, { value: 'in', label: 'in' }, { value: 'nin', label: 'not in' }, { value: 'match', label: 'match' }, { value: 'dontmatch', label: 'not match' }, { value: '!!', label: 'set' }, { value: 'null', label: 'not set' }],
}
export const connectors = {
  AND: '&&',
  OR: '||'
}
// TODO: get these from rules svc
export const durations = {
  's': 'Second',
  'm': 'Minute',
  'h': 'Hour',
  'D': 'Day',
  'W': 'Week',
  'M': 'Month',
  'Y': 'Year'
}

export const logMetOptions = {
  0: 'No',
  1: 'Yes'
}

export const onFailOptions = {
  0: 'Deactivate',
  1: 'Keep active'
}

export class Rule {
  constructor(props) {
    const { id, title, active, desc, tags, event, scheduled, runat, cron, criterias, actions, unmetActions, onUnmet, onMet, onFail, noCriterias, chainable, archive, validFrom, validTo, clone, ...rest } = props || {};
    this.id = id || '';
    this.title = title || '';
    this.active = !!active;
    this.desc = desc || '';
    this.validFrom = dateToString(validFrom);
    this.validTo = dateToString(validTo);
    this.tags = tags || '';
    this.event = event || '';
    this.scheduled = !!scheduled;
    this.noCriterias = !!noCriterias;
    this.chainable = !!chainable;
    this.archive = !!archive;
    this.delay = runat || '';
    this.repeat = cron || '';
    this.criterias = criterias && criterias.map(c => new Criteria(c)) || [];
    this.actions = actions && actions.map(a => new Action(a)) || [new Action()];
    this.unmetActions = unmetActions && unmetActions.map(a => new Action(a)) || [new Action()];
    this.loaded = true;
    this.onUnmet = onUnmet || 0;
    this.onMet = onMet || 0;
    this.onFail = onFail || 0;
    this.showDetails = !id;
    this.showUnmetActions = false;
    this.modified = false;
    this.preview = false;
    this.clone = null;
    for (var k in rest) {
      this[k] = rest[k]
    }
    this.dryRun = true;

    if (this.event == RULES_EXEC_DONE) {
      if (this.criterias.length && this.criterias[0].datasource?.id == 'event' && this.criterias[0].conditions?.length) {
        this.ruleId = this.criterias[0].conditions[0].value;
      }
    }
  }
}

export class Criteria {
  constructor(props) {
    const { id, datasource, returns, parameters, conditions, connector, filters, items, aggregations } = props || {};
    this.id = id || generateID();
    this.datasource = datasource && new Datasource(datasource) || '';
    this.returns = returns || '';
    this.parameters = parameters && parameters.map(p => new Parameter(p)) || [];
    this.conditions = conditions && conditions.map(c => new Condition(c)) || [];
    this.connector = connector || connectors.OR;
    this.filters = filters && filters.map(c => new Condition(c)) || [];
    this.items = items && items.map(c => new Criteria(c)) || [];
    this.aggregations = aggregations && aggregations.map(c => new Condition(c)) || [];
  }
}

export class Datasource {
  constructor(props) {
    const { id, key, alias } = props || {};
    this.id = id || '';
    this.key = key || '';
    this.alias = alias || key || '';
    this.changed = !!alias;
  }
}

export class Parameter {
  constructor(props) {
    const { id, value, type, isDynamic } = props || {};
    this.id = id || generateID();
    this.value = value || '';
    this.type = type || '';
    this.isDynamic = !!isDynamic;
    if (this.type == "eventFields") {
      this.value = tryParse(this.value);
    }
  }
}

export class Condition {
  constructor(props) {
    const { id, field, comparer, connector, value, type, items, method, alias, isDynamic } = props || {};
    this.id = id || generateID();
    this.field = field || '';
    this.type = type || '';
    this.method = method || '';
    this.comparer = comparer || comparers.string[0].value;
    this.connector = connector || connectors.OR;
    this.value = value || '';
    this.items = items && items.map(c => new Condition(c)) || [];
    this.alias = alias || '';
    this.isDynamic = !!isDynamic;
  }
}

export class Action {
  constructor(props) {
    const { id, method, parameters, alias } = props || {};
    this.id = id || generateID();
    this.method = method || undefined;
    this.parameters = parameters && parameters.map(p => new Parameter(p)) || [];
    this.alias = alias || '';
    this.changed = !!alias;
  }
}

const validateAlias = (alias) => {
  return /^[a-zA-Z_][0-9a-zA-Z_-]*$/.test(alias);
}

const validateCondition = (condition, datasource) => {
  if (condition.items && condition.items.length) {
    for (var ci = 0, cj = condition.items.length; ci < cj; ci++) {
      const item = condition.items[ci];
      const err = validateCondition(item, datasource);
      if (!!err) return err;
    }
    return false;
  }
  const novalue = condition.comparer && (condition.comparer == '!!' || condition.comparer == 'null')
  if ((!datasource.returns || datasource.returns == 'paramType') && condition.field == '' || (condition.value == '' && !novalue)) {
    return "Condition is incomplete";
  }
  if (condition.method) {
    if (!condition.alias) {
      return "Condition alias is mandatory";
    }
    if (!validateAlias(condition.alias)) {
      return "Condition alias can only contain letters, numbers, underscore or dash, and has to start with a letter or underscore";
    }
  }
  return false;
}

export const validateCriteria = (criteria, datasources) => {
  var err;
  if (criteria.items && criteria.items.length) {
    for (var ci = 0, cj = criteria.items.length; ci < cj; ci++) {
      const item = criteria.items[ci];
      err = validateCriteria(item, datasources);
      if (!!err) return err;
    }
    return false;
  }
  if (!criteria.datasource) {
    return "Datasource is missing";
  }
  const dsrc = (datasources || []).find(d => d.id == criteria.datasource.id);
  if (!dsrc || dsrc.params && dsrc.params.length !== criteria.parameters.length) {
    return "Datasource parameters are not complete";
  }
  if (!criteria.datasource.alias) {
    return "Datasource alias is mandatory";
  }
  if (!validateAlias(criteria.datasource.alias)) {
    return "Datasource alias can only contain letters, numbers, underscore or dash, and has to start with a letter or underscore";
  }
  for (var pi = 0, pj = criteria.parameters.length; pi < pj; pi++) {
    if (criteria.parameters[pi].value == '') {
      return "Criteria parameter is missing";
    }
  }
  for (var coi = 0, coj = criteria.conditions.length; coi < coj; coi++) {
    err = validateCondition(criteria.conditions[coi], dsrc);
    if (!!err) return err;
  }
  for (var coi = 0, coj = criteria.filters.length; coi < coj; coi++) {
    err = validateCondition(criteria.filters[coi], dsrc);
    if (!!err) return err;
  }
  for (var coi = 0, coj = criteria.aggregations.length; coi < coj; coi++) {
    err = validateCondition(criteria.aggregations[coi], dsrc);
    if (!!err) return err;
  }
  return false;
}

const validateAction = (action, actionMethods) => {
  if (action.method == '') {
    return "Action method is not selected";
  }
  const act = (actionMethods || []).find(a => a.name == action.method);
  if (!act) {
    return "Unknown action method";
  }
  if (act.params) {
    const count = action.parameters.length - 1;
    for (var pai = 0, paj = act.params.length; pai < paj; pai++) {
      if (!act.params[pai].optional && (count < pai || action.parameters[pai].value == '')) {
        return "Action parameter is missing";
      }
    }
  }
  if (!validateAlias(act.alias)) {
    return "Action alias can only contain letters, numbers, underscore or dash, and has to start with a letter or underscore";
  }
  return false;
}

export const validate = (rule, { datasources, actionMethods }) => {
  var err;
  if (!rule.title) return "Rule title is mandatory";
  if (!rule.event) return "Even is not selected";
  if (rule.criterias) {
    for (var ci = 0, cj = rule.criterias.length; ci < cj; ci++) {
      const criteria = rule.criterias[ci];
      err = validateCriteria(criteria, datasources);
      if (!!err) return err;
    }
  } else if (rule.event != "RULES_EXEC_ERROR" && !rule.noCriterias) return "Please add at least one criteria";
  if (!rule.actions || !rule.actions.length) return "Please add at least one action";
  for (var ai = 0, aj = rule.actions.length; ai < aj; ai++) {
    const action = rule.actions[ai];
    err = validateAction(action, actionMethods);
    if (!!err) return err;
  }
  if (rule.unmetActions?.length != 1 || typeof (rule.unmetActions[0].method) != 'undefined') {
    for (var ai = 0, aj = rule.unmetActions?.length; ai < aj; ai++) {
      const action = rule.unmetActions[ai];
      err = validateAction(action, actionMethods);
      if (!!err) return err;
    }
  }
  return false;
}

export const getDatasources = (queries, withEvent) => {
  var datasources = (queries || []).map(d => {
    if (d.props) {
      d.props.forEach(p => {
        if (p.values) {
          p.values.forEach((v, i) => {
            v.index = i;
          })
        }
        // if (p.data && datasourceFieldData[p.data.source][p.data.kind]) {
        //   p.values = toKeyValue(datasourceFieldData[p.data.source][p.data.kind]);
        // }
      })
    }
    if (d.filters) {
      d.filters.forEach(f => {
        if (f.values) {
          f.values.forEach((v, i) => {
            v.index = i;
          })
        }
      })
    }
    if (d.returns == 'any' && d.values) {
      d.values.forEach((v, i) => {
        v.index = i;
      })
    }
    return d;
  });

  if (withEvent) {
    datasources.unshift({
      id: "event",
      key: "event",
      desc: "Triggering event",
      returns: "eventType",
      disabled: true
    });
  }
  return { datasources };
}

export const getActionMethods = (data) => {
  return {
    actionMethods: (data || []).map(a => {
      if (!a.data) return a;
      return Object.assign({}, a, {
        data: a.data.reduce((res, d) => {
          res[d.id] = d;
          return res;
        }, {})
      })
    })
  }
}

export const ruleFromState = (state) => {
  return {
    id: state.id,
    created: state.created,
    createdBy: state.createdBy,
    title: state.title,
    desc: state.desc,
    group: state.group,
    tags: tagify(state.tags),
    active: state.active,
    archive: state.archive,
    noCriterias: state.noCriterias,
    chainable: state.chainable,
    validFrom: parseDate(state.validFrom) || null,
    validTo: parseDate(state.validTo) || null,
    onUnmet: state.onUnmet,
    onMet: state.onMet,
    onFail: state.onFail,
    event: state.event,
    scheduled: state.scheduled,
    runat: state.delay,
    cron: state.repeat,
    criterias: state.criterias.map(c => {
      const cc = Object.assign({}, c)
      if (cc.conditions) {
        cc.conditions = c.conditions.map(co => mapCondition(co))
      }
      return cc
    }),
    actions: state.actions.map(a => {
      const c = Object.assign({}, a)
      if (a.parameters) {
        c.parameters = c.parameters.map(p => {
          const r = Object.assign({}, p)
          if (r.type == "eventFields" && typeof r.value !== 'string') {
            r.value = r.value ? JSON.stringify(r.value) : "";
          }
          return r;
        })
      }
      return c;
    }),
    unmetActions: state.unmetActions
      .filter((a, i) => (state.unmetActions.length != 1 || i != 0 || typeof (state.unmetActions[0].method) != 'undefined'))
      .map(a => {
        const c = Object.assign({}, a)
        if (a.parameters) {
          c.parameters = c.parameters.map(p => {
            const r = Object.assign({}, p)
            if (r.type == "eventFields" && typeof r.value !== 'string') {
              r.value = r.value ? JSON.stringify(r.value) : "";
            }
            return r;
          })
        }
        return c;
      })
  }
}

const mapCondition = (c) => {
  const cc = Object.assign({}, c)
  if (cc.items.length) {
    cc.items = cc.items.map(co => mapCondition(co))
  } else if (cc.type == 'date' || cc.type == 'datetime') {
    cc.value = parseDate(cc.value)
  }
  return cc
}