import { h } from 'hyperapp'
import { CountryEditor } from './components/country-editor'

export const Country = ({ state, actions, match }) => {
  const id = match.params.id == 'new' ? null : match.params.id;
  return (
    <section class="section" key="country_view">
      <h1 class="title">
        {!id ? 'New' : 'Edit'} Country
      </h1>
      <CountryEditor state={state.country} actions={actions.country} id={id} />
    </section>
  )
}
