import { client } from '../modules/client';
import { getActionMethods } from '../modules/rule';

const mapQueue = q => {
  var split = q.replace(/worker:(.*)/, (_, n) => n).split(':')
  return {
    label: split.map(s => (s.charAt(0).toUpperCase() + s.substr(1).toLowerCase())).join(' '),
    id: q.replace(/:/g, '_')
  }
}

export const tasks = {
  setState: (values) => (state, actions) => {
    return values;
  },
  getQueues: () => async (state, actions) => {
    const res = await client.getScheduledQueues()
    if (res.error) {
      return actions.setState({
        error: queues.error
      })
    }
    const queues = res.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1)).map(mapQueue)
    return actions.setState({ loaded: true, queues });
  },
  getActions: () => async (state, actions) => {
    if (state.actionMethods) return actions.setState({});
    const data = await client.getActions();
    if (data.error) {
      return actions.setState({
        error: data.error
      })
    }
    const res = getActionMethods(data);
    res.methods = (res.actionMethods || []).map(a => a.name);
    return actions.setState(res);
  },
  getTasks: ({ queue, params }) => async (state, actions) => {
    actions.setState({ queue, loading: true });
    const { filter, order, skip, take } = state;
    const filterStr = Object.keys(filter).filter(f => (filter[f] !== "")).map(f => `${f}=${filter[f]}`).join('')
    params = Object.assign({ filter: filterStr, order, skip, take }, params || {})
    const batches = await client.getQueueTasks(queue.replace(/_/g, ':'), params)
    if (batches.error) {
      return actions.setState({
        error: batches.error
      })
    }
    batches.loading = false;
    return actions.setState(batches);
  },
  cancelTask: (index) => async (state, actions) => {
    const queueName = state.queue.replace(/_/g, ':').replace(/worker:(.*):schedule/, (_, n) => n);
    const res = await client.cancelTask(queueName, index)
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    if (res.count <= 0) {
      return actions.setState({
        error: "Couldn't find task"
      })
    }
    return actions.getTasks({ queue: state.queue });
  },
  cancelBatch: (batchId) => async (state, actions) => {
    const queueName = state.queue.replace(/_/g, ':').replace(/worker:(.*):schedule/, (_, n) => n);
    const res = await client.cancelBatch(queueName, batchId)
    if (res.error) {
      return actions.setState({
        error: res.error
      })
    }
    if (res.count <= 0) {
      return actions.setState({
        error: `Couldn't find tasks with batchId ${batchId}`
      })
    }
    return actions.getTasks({ queue: state.queue });
  },
  toggleModal: (modal) => (state) => {
    if (!modal || state.modal) return {
      modal: null
    };
    return { modal }
  },
  toggleBatch: (batch) => (state) => {
    const items = state.items.map(i => (batch.batchId === i.batchId ? Object.assign({}, i, { isExpanded: !batch.isExpanded }) : i))
    return { items }
  }
}
