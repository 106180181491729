import { h } from 'hyperapp'
import prism from 'prismjs'
import 'prismjs/themes/prism.css'
import { ruleFromState } from '../../modules/rule'
import { dangerouslySetInnerHTML } from '../../modules/utils'
import '../../css/rule-preview.css'

const copyToClipboard = (ruleJson) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(ruleJson)
  }
}

export const RulePreview = ({ state, actions }) => {
  const ruleJson = JSON.stringify(ruleFromState(state), null, 2);
  const html = prism.highlight(ruleJson, prism.languages.javascript, 'javascript')
  return (
    <div class="preview_rule">
      <div class={"preview_panel " + (state.preview ? 'open' : '')}>
        <p class="subtitle is-5"><strong>ON:</strong> {state.event}</p>
        <pre>
          <button class="button preview_copy" title="Copy to clipboard" onclick={() => copyToClipboard(ruleJson)}>
            <span class="icon is-small">
              <i class="fas fa-copy"></i>
            </span>
          </button>
          <code class="language-javascript" oncreate={dangerouslySetInnerHTML(html)} onupdate={dangerouslySetInnerHTML(html)}></code>
        </pre>
      </div>
    </div>
  )
}
